import { useEffect } from 'react';
import { create } from 'zustand';

export declare type TContractSubscribeTickData = [number, number];
export declare type TContractSubscribeListener = (code: string, data: TContractSubscribeTickData) => void;
export declare type TContractSubscribeTradeTypeData = {
  codes: { code: string; listeners: TContractSubscribeListener[] }[];
};
export declare type TContractTradeDataStore = {
  tick: TContractSubscribeTradeTypeData;
  subscribe: (type: 'tick', codes: string[], listener: TContractSubscribeListener) => void;
  unsubscribe: (type: 'tick', codes: string[], listener: TContractSubscribeListener) => void;
};

const worker = new Worker('/worker/tick-data-generator.js');
export const useContractTradeDataStore = create<TContractTradeDataStore>((set, get) => {
  worker.addEventListener('message', event => {
    const datas = event.data as number[][];
    const codes = get()
      .tick.codes.sort(() => Math.random() - 0.5)
      .slice(0, datas.length);
    codes.forEach((code, index) => {
      const data = datas[index];
      code.listeners.forEach(listener => {
        // console.log(code.code, data);
        listener(code.code, data as TContractSubscribeTickData);
      });
    });
  });

  setTimeout(() => worker.postMessage('start'), 300);

  const subscribe = (type: 'tick', codes: string[], listener: TContractSubscribeListener) => {
    const tickcodes = codes.map(code => {
      const tickcode = get().tick.codes.find(tickcode => tickcode.code === code) || { code, listeners: [] };
      return { ...tickcode, listeners: [...tickcode.listeners, listener] };
    });
    set(state => ({
      tick: { ...state.tick, codes: [...get().tick.codes.filter(c => codes.indexOf(c.code) == -1), ...tickcodes] },
    }));
  };
  const unsubscribe = (type: 'tick', codes: string[], listener: TContractSubscribeListener) => {
    const tickcodes = codes.map(code => {
      const tickcode = get().tick.codes.find(tickcode => tickcode.code === code) || { code, listeners: [] };
      return { ...tickcode, listeners: tickcode.listeners.filter(l => l !== listener) };
    });
    set(state => ({
      tick: { ...state.tick, codes: [...get().tick.codes.filter(c => codes.indexOf(c.code) == -1), ...tickcodes] },
    }));
  };
  return {
    tick: { codes: [] },
    subscribe,
    unsubscribe,
  };
});

export const useTickData = (code: string, listener: TContractSubscribeListener) => {
  const { tick, subscribe, unsubscribe } = useContractTradeDataStore();
  // console.log(tick.codes.length);
  // console.log(code);
  useEffect(() => {
    subscribe('tick', [code], listener);
    return () => unsubscribe('tick', [code], listener);
  }, [code, listener, subscribe, unsubscribe]);
};
