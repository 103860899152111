import { useContractPeriodSelectorStore } from '@/components/ContractPeriodSelector';
import { useContractPrewiewLayoutStore } from '@/components/ContractPreviewLayoutSwitcher';
import { useOptionalContractStore } from '@/components/OptionalContractPanel';
import EmptyState from '@atlaskit/empty-state';
import { Box, Grid, Stack } from '@atlaskit/primitives';
import { useMemo } from 'react';
import { useScreenResultStore } from './ConditionEditor';
import ContractMultiplePreviewToolbar from './ContractMultiplePreviewToolbar';
import { SOURCE_SCREENER_RESULT, useContractPreviewSourceStore } from './ContractPreviewSourceSwitcher';
import ContractPreviewView from './ContractPreviewView';

export default function ContractMultiplePreviewView() {
  const { type } = useContractPrewiewLayoutStore();
  const templateColumns = useMemo(() => ({ '2': '1fr 1fr', '3': '1fr 1fr 1fr' }[type]), [type]);
  const { source } = useContractPreviewSourceStore();
  const { result } = useScreenResultStore();
  const { getCurrentCollection } = useOptionalContractStore();
  const currentCollection = getCurrentCollection();
  const datas = useMemo(() => {
    if (source === SOURCE_SCREENER_RESULT) {
      return result?.datas;
    } else {
      return currentCollection?.datas;
    }
  }, [source, result, currentCollection]);
  const { periods } = useContractPeriodSelectorStore();
  return (
    <Stack space="space.050">
      <ContractMultiplePreviewToolbar />
      <Box>
        {(datas?.length || 0) > 0 ? (
          <Grid rowGap="space.025" columnGap="space.025" templateColumns={templateColumns}>
            {datas?.map(([code, name]) =>
              periods.map(period => (
                <ContractPreviewView key={`${code}-${period}`} name={name} code={code} period={period} />
              ))
            )}
          </Grid>
        ) : (
          <EmptyState header="无数据可使用" />
        )}
      </Box>
    </Stack>
  );
}
