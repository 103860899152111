import { TScreenConditionItem, TScreenConditions, TScreenPL, doScreenWith } from '@/services/screener';
import { ButtonGroup, LoadingButton } from '@atlaskit/button';
import Form, { Field, FieldProps, FormFooter, FormSection } from '@atlaskit/form';
import { Grid, Stack, xcss } from '@atlaskit/primitives';
import { CheckboxSelect, RadioSelect } from '@atlaskit/select';
import { useCallback, useRef } from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { AdvanceButton, useAdvanceSettingStore } from './AdvanceButton';
import { SOURCE_SCREENER_RESULT, useContractPreviewSourceStore } from './ContractPreviewSourceSwitcher';

declare type TPeriodValue = '1f' | '5f' | '30f' | '3f' | '15f' | '60f' | 'day' | '2day';

const PERIOD_OPTIONS = [
  { value: '1f', label: '1f' },
  { value: '5f', label: '5f' },
  { value: '30f', label: '30f' },
  { value: '3f', label: '3f' },
  { value: '15f', label: '15f' },
  { value: '60f', label: '60f' },
  { value: 'day', label: 'd' },
  { value: '2day', label: '2d' },
];

const SIGNAL_OPTIONS = [
  { value: '盘背拉回', label: '盘背拉回' },
  { value: '二买预期', label: '二买预期' },
  { value: '三买预期', label: '三买预期' },
  { value: '盘背预期', label: '盘背预期' },
  { value: '中枢突破', label: '中枢突破' },
  { value: '完备预期', label: '完备预期' },
];

declare type TConditionItemEditorProps = FieldProps<TScreenConditionItem>;
const ConditionItemEditor = ({ value, onChange }: TConditionItemEditorProps) => {
  const { period, signals = [] } = value || {};
  const handleSignalChange = (signals: any = []) => {
    onChange({ ...value, signals: (signals as any[]).map(s => s.value).slice(0, 2) });
  };
  const handlePeriodChange = (period: any) => {
    onChange({ ...value, period: period.value });
  };
  const isValid = period && signals.length > 0;
  return (
    <Grid
      columnGap="space.050"
      templateColumns="1fr 3fr"
      xcss={xcss({
        backgroundColor: isValid ? 'color.background.neutral.hovered' : 'color.background.input',
        borderRadius: 'border.radius.100',
      })}
    >
      <RadioSelect
        appearance="none"
        value={PERIOD_OPTIONS.find(p => p.value === period)}
        options={PERIOD_OPTIONS}
        placeholder="级别"
        onChange={handlePeriodChange}
      />
      <CheckboxSelect
        appearance="none"
        value={signals.map(s => ({ label: s, value: s }))}
        options={SIGNAL_OPTIONS}
        placeholder="买卖点"
        onChange={handleSignalChange}
      />
    </Grid>
  );
};

declare type TConditionFormModel = {
  condition1: TScreenConditionItem;
  condition2: TScreenConditionItem;
  condition3: TScreenConditionItem;
};

export const useConditionEditorStore = create(
  persist<{ set: (formValues: TConditionFormModel) => void } & TConditionFormModel>(
    (set, get) => ({
      condition1: { period: '30f', signals: ['盘背拉回'] },
      condition2: { period: '5f', signals: [] },
      condition3: { period: '1f', signals: [] },
      set: (formValues: TConditionFormModel) => set(formValues),
    }),
    { name: 'screener-conditions-storage' }
  )
);

export const useScreenResultStore = create(
  persist<{
    conditions?: TScreenConditions;
    result?: TScreenPL;
    setResult: (conditions?: TScreenConditions, result?: TScreenPL) => void;
    clean: () => void;
  }>(
    (set, get) => ({
      setResult: (conditions, result) => set({ conditions, result }),
      clean: () => set({ conditions: undefined, result: undefined }),
    }),
    {
      name: 'screen-result-store',
    }
  )
);

export default function ConditionEditor() {
  const { setResult } = useScreenResultStore();
  const { condition1, condition2, condition3, set } = useConditionEditorStore();
  const { hq: hqSetting, js: jsSetting } = useAdvanceSettingStore();
  const formRef = useRef<HTMLFormElement>(null);
  const { set: setPreviewSource } = useContractPreviewSourceStore();
  const onSubmit = useCallback(
    async (formValues: any) => {
      set(formValues);
      const conditions = [formValues.condition1, formValues.condition2, formValues.condition3].filter(
        ({ period, signals = [] }) => period && signals.length > 0
      );
      if (conditions.length <= 0) {
        alert('没有有效的选股规则，请核对！');
        return;
      }

      const { payload } = await doScreenWith(conditions, { hqSetting, jsSetting });
      setResult(conditions, payload);
      setPreviewSource(SOURCE_SCREENER_RESULT);
    },
    [formRef, set, hqSetting, jsSetting, setResult, setPreviewSource]
  );
  return (
    <Stack space="space.100" xcss={xcss({ paddingInline: 'space.150' })}>
      <Form<TConditionFormModel> onSubmit={onSubmit}>
        {({ formProps, submitting }) => (
          <form {...formProps} ref={formRef}>
            <FormSection>
              <Field name="condition1" defaultValue={condition1}>
                {({ fieldProps }) => <ConditionItemEditor {...fieldProps} />}
              </Field>
              <Field name="condition2" defaultValue={condition2}>
                {({ fieldProps }) => <ConditionItemEditor {...fieldProps} />}
              </Field>
              <Field name="condition3" defaultValue={condition3}>
                {({ fieldProps }) => <ConditionItemEditor {...fieldProps} />}
              </Field>
            </FormSection>
            <FormFooter>
              <ButtonGroup>
                {/* <DropdownMenu<HTMLButtonElement>
                  placement="bottom-end"
                  spacing="compact"
                  trigger={({ triggerRef, isSelected, testId, ...providedProps }) => (
                    <Button iconAfter={<MoreIcon label="" />} {...providedProps} ref={triggerRef} appearance="subtle">
                      30F级别盘背预期
                    </Button>
                  )}
                >
                  <DropdownItemGroup>
                    <DropdownItem elemBefore={<DocumentIcon label="" />}>另存为...</DropdownItem>
                  </DropdownItemGroup>
                  <DropdownItemGroup hasSeparator title="我的预设">
                    <DropdownItem title="23-12-03 17:47">30F级别盘背预期</DropdownItem>
                    <DropdownItem title="23-12-03 17:47">1F级别双盘/双级别拉回</DropdownItem>
                  </DropdownItemGroup>
                  <DropdownItemGroup hasSeparator>
                    <DropdownItem elemBefore={<TrashIcon label="" />}>删除"30F级别盘背预期"</DropdownItem>
                  </DropdownItemGroup>
                </DropdownMenu> */}
                {/* <Button onClick={() => formRef.current?.reset()}>恢复默认</Button> */}
                <AdvanceButton />
                <LoadingButton type="submit" appearance="primary" isLoading={submitting}>
                  执行选股
                </LoadingButton>
              </ButtonGroup>
            </FormFooter>
          </form>
        )}
      </Form>
    </Stack>
  );
}
