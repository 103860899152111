import { Content, PageLayout, TopNavigation } from '@atlaskit/page-layout';
import { ReactNode } from 'react';
import AppNavigation from './AppNavigation';

const AppLayout = ({ children, navigationActived }: { children: ReactNode; navigationActived: 'screener' }) => {
  return (
    <PageLayout>
      <TopNavigation height={60} isFixed>
        <AppNavigation actived={navigationActived} />
      </TopNavigation>
      <Content testId="content">{children}</Content>
      {/* <RightPanel isFixed width={200}>
          帮助文档
        </RightPanel> */}
    </PageLayout>
  );
};
export default AppLayout;
