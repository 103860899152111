import * as echarts from 'echarts';
import { useEffect, useRef } from 'react';

export default function ContractChartView({ width, height }: { width?: string; height?: string }) {
  const chartRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!chartRef.current) {
      return;
    }
    const chart = echarts.init(chartRef.current, 'white', { renderer: 'canvas' });
    const option = {
      animation: false,
      animationThreshold: 2000,
      animationDuration: 1000,
      animationEasing: 'cubicOut',
      animationDelay: 0,
      animationDurationUpdate: 300,
      animationEasingUpdate: 'cubicOut',
      animationDelayUpdate: 0,
      color: [
        '#c23531',
        '#2f4554',
        '#61a0a8',
        '#d48265',
        '#749f83',
        '#ca8622',
        '#bda29a',
        '#6e7074',
        '#546570',
        '#c4ccd3',
        '#f05b72',
        '#ef5b9c',
        '#f47920',
        '#905a3d',
        '#fab27b',
        '#2a5caa',
        '#444693',
        '#726930',
        '#b2d235',
        '#6d8346',
        '#ac6767',
        '#1d953f',
        '#6950a1',
        '#918597',
      ],
      series: [
        {
          type: 'candlestick',
          name: 'K\u7ebf',
          xAxisIndex: 0,
          yAxisIndex: 0,
          data: [
            [3023.78, 3024.74, 3005.69, 3026.24],
            [3027.41, 3021.2, 3005.38, 3027.41],
            [3029.93, 3008.81, 3004.22, 3030.56],
            [3016.63, 3031.24, 3005.19, 3033.47],
            [3041.92, 3030.75, 3020.05, 3042.93],
            [3023.71, 2978.12, 2970.57, 3023.71],
            [2984.08, 2985.66, 2982.4, 2996.4],
            [2992.92, 2999.28, 2975.4, 2999.28],
            [3004.81, 3006.45, 2996.19, 3011.34],
            [2998.4, 2977.08, 2960.83, 2998.4],
            [2979.48, 2985.34, 2973.76, 3002.9],
            [2977.67, 2955.43, 2955.43, 2977.67],
            [2964.48, 2929.09, 2928.26, 2970.04],
            [2929.49, 2932.17, 2920.93, 2939.08],
            [2927.92, 2905.19, 2905.19, 2936.48],
            [2905.76, 2913.57, 2905.76, 2933.02],
            [2902.08, 2924.86, 2891.54, 2924.86],
            [2923.71, 2947.71, 2918.23, 2949.24],
            [2954.82, 2973.66, 2943.01, 2980.79],
            [2993.96, 3007.88, 2989.81, 3026.38],
            [3005.66, 2991.05, 2986.3, 3005.66],
            [2992.61, 2978.71, 2975.92, 3010.42],
            [2979.99, 2977.33, 2969.57, 2986.72],
            [2982.34, 2938.14, 2933.24, 2987.2],
            [2933.9, 2939.62, 2917.69, 2940.32],
            [2945.6, 2954.38, 2934.39, 2954.64],
            [2952.97, 2941.62, 2934.63, 2957.3],
            [2944.01, 2940.92, 2927.06, 2953.04],
            [2940.47, 2954.93, 2920.52, 2958.24],
            [2958.69, 2980.05, 2956.96, 2980.13],
            [2979.04, 2954.18, 2954.18, 2979.04],
            [2949.46, 2939.32, 2934.83, 2953.3],
            [2944.84, 2929.06, 2923.52, 2946.75],
            [2924.34, 2958.2, 2917.15, 2959.83],
            [2964.58, 2975.49, 2964.58, 2983.06],
            [2977.7, 2991.56, 2968.03, 3008.31],
            [2992.04, 2978.6, 2969.72, 2995.56],
            [2978.15, 2978.71, 2969.12, 2988.96],
            [2993.98, 2964.18, 2962.84, 2998.76],
            [2949.96, 2909.97, 2906.77, 2949.96],
            [2913.0, 2914.82, 2891.51, 2918.3],
            [2913.02, 2905.24, 2893.97, 2913.86],
            [2907.74, 2909.87, 2899.62, 2917.37],
            [2911.35, 2891.34, 2891.2, 2917.83],
            [2889.55, 2909.2, 2884.09, 2911.38],
            [2904.28, 2933.99, 2902.86, 2933.99],
            [2928.11, 2911.05, 2907.42, 2930.49],
            [2902.55, 2903.64, 2891.53, 2910.36],
            [2906.24, 2885.29, 2873.99, 2925.02],
            [2885.61, 2906.17, 2880.65, 2906.61],
            [2912.52, 2907.06, 2897.93, 2915.04],
            [2905.4, 2903.19, 2893.42, 2914.49],
            [2902.36, 2889.69, 2883.43, 2909.84],
            [2885.97, 2871.98, 2858.58, 2892.39],
            [2874.45, 2875.81, 2870.24, 2888.89],
            [2869.88, 2884.7, 2857.32, 2884.86],
            [2876.91, 2878.12, 2869.42, 2882.5],
            [2886.52, 2899.47, 2885.08, 2902.0],
            [2902.28, 2912.01, 2894.75, 2912.01],
            [2914.46, 2914.48, 2905.25, 2919.59],
            [2908.94, 2917.32, 2902.79, 2919.2],
            [2922.6, 2924.42, 2915.0, 2928.26],
            [2926.34, 2915.7, 2913.48, 2926.34],
            [2937.78, 2967.68, 2935.85, 2969.98],
            [2970.97, 2984.39, 2958.71, 2984.64],
            [2985.26, 3022.42, 2982.5, 3039.38],
            [3021.47, 3017.04, 3011.72, 3033.23],
            [3017.15, 3017.07, 3007.99, 3021.42],
            [3019.64, 3004.94, 3002.26, 3027.48],
            [2999.04, 2962.75, 2960.44, 3009.34],
            [2965.83, 2982.68, 2960.68, 2983.82],
            [2980.43, 2981.88, 2970.66, 2988.29],
            [2981.25, 3007.35, 2980.4, 3007.35],
            [3006.85, 3005.04, 3003.63, 3036.11],
            [2998.17, 3040.02, 2983.34, 3041.4],
            [3036.39, 3050.12, 3030.51, 3051.68],
            [3066.34, 3085.2, 3066.34, 3098.1],
            [3089.02, 3083.79, 3074.52, 3093.82],
            [3070.91, 3083.41, 3065.31, 3107.2],
            [3085.49, 3104.8, 3084.33, 3105.45],
            [3094.24, 3066.89, 3059.13, 3094.24],
            [3082.64, 3094.88, 3080.13, 3097.33],
            [3102.29, 3092.29, 3081.4, 3105.22],
            [3091.49, 3115.57, 3075.38, 3115.57],
            [3120.67, 3106.82, 3105.6, 3127.17],
            [3103.17, 3090.04, 3082.04, 3107.94],
            [3095.73, 3074.08, 3070.88, 3096.37],
            [3081.46, 3075.5, 3067.25, 3091.95],
            [3082.11, 3095.79, 3070.48, 3096.31],
            [3085.79, 3052.14, 3051.23, 3085.79],
            [3038.49, 3060.75, 3006.27, 3069.25],
            [3037.95, 2976.53, 2955.35, 3045.04],
            [2716.7, 2746.61, 2716.7, 2766.58],
            [2685.27, 2783.29, 2685.27, 2786.16],
            [2792.37, 2818.09, 2778.86, 2842.74],
            [2826.89, 2866.51, 2807.61, 2876.59],
            [2858.93, 2875.96, 2838.77, 2875.96],
            [2860.5, 2890.49, 2851.05, 2891.85],
            [2894.54, 2901.67, 2882.24, 2913.82],
            [2895.56, 2926.9, 2892.42, 2926.9],
            [2927.14, 2906.07, 2901.24, 2935.41],
            [2899.87, 2917.01, 2899.57, 2926.94],
            [2924.99, 2983.62, 2924.99, 2983.64],
            [2981.41, 2984.97, 2960.78, 2990.6],
            [2979.52, 2975.4, 2971.82, 2998.27],
            [2981.88, 3030.15, 2968.45, 3031.37],
            [3022.25, 3039.67, 3020.14, 3058.9],
            [3027.89, 3031.23, 3007.36, 3042.18],
            [2982.07, 3013.05, 2943.72, 3016.95],
            [2978.42, 2987.93, 2974.94, 3028.78],
            [2992.49, 2991.33, 2980.48, 3009.46],
            [2924.64, 2880.3, 2878.54, 2948.13],
            [2899.31, 2970.93, 2899.31, 2982.51],
            [3006.89, 2992.9, 2976.62, 3026.84],
            [2981.81, 3011.67, 2974.36, 3012.0],
            [3036.15, 3071.68, 3022.93, 3074.26],
            [3039.94, 3034.51, 3029.46, 3052.44],
            [2987.18, 2943.29, 2940.71, 2989.21],
            [2918.93, 2996.76, 2904.8, 3000.3],
            [3001.76, 2968.52, 2968.52, 3010.03],
            [2936.02, 2923.49, 2906.28, 2944.47],
            [2804.23, 2887.43, 2799.98, 2910.88],
            [2897.3, 2789.25, 2784.66, 2898.03],
            [2796.28, 2779.64, 2715.22, 2826.91],
            [2792.32, 2728.76, 2728.76, 2815.87],
            [2719.41, 2702.13, 2646.8, 2736.82],
            [2727.02, 2745.62, 2702.49, 2751.9],
            [2677.59, 2660.17, 2656.5, 2703.33],
            [2703.02, 2722.44, 2667.13, 2723.41],
            [2775.3, 2781.59, 2757.8, 2788.64],
            [2761.9, 2764.91, 2753.43, 2788.5],
            [2792.98, 2772.2, 2771.76, 2805.55],
            [2739.72, 2747.21, 2723.05, 2759.1],
            [2767.31, 2750.3, 2743.12, 2771.17],
            [2743.54, 2734.52, 2731.08, 2773.36],
            [2720.23, 2780.64, 2719.9, 2780.64],
            [2773.58, 2763.99, 2754.07, 2780.59],
            [2806.97, 2820.76, 2801.84, 2823.28],
            [2805.92, 2815.37, 2800.3, 2823.21],
            [2825.84, 2825.9, 2820.43, 2832.4],
            [2827.19, 2796.63, 2789.98, 2833.01],
            [2784.6, 2783.05, 2774.08, 2792.89],
            [2794.8, 2827.28, 2789.43, 2827.3],
            [2826.66, 2811.17, 2808.7, 2829.75],
            [2798.43, 2819.94, 2796.84, 2823.34],
            [2835.56, 2838.49, 2830.02, 2854.96],
            [2840.41, 2852.55, 2833.26, 2852.99],
            [2842.24, 2827.01, 2808.02, 2842.24],
            [2814.07, 2843.98, 2808.48, 2843.98],
            [2850.51, 2838.5, 2835.9, 2853.64],
            [2834.94, 2808.53, 2802.5, 2834.94],
            [2812.24, 2815.49, 2802.96, 2832.67],
            [2819.99, 2810.02, 2758.25, 2821.74],
            [2801.38, 2822.44, 2800.74, 2831.76],
            [2832.38, 2860.08, 2832.38, 2865.59],
            [2831.63, 2878.14, 2830.65, 2879.26],
            [2876.47, 2871.52, 2864.58, 2882.02],
            [2882.71, 2895.34, 2879.2, 2903.8],
            [2901.57, 2894.8, 2884.38, 2914.28],
            [2894.62, 2891.56, 2871.23, 2897.9],
            [2882.96, 2898.05, 2875.54, 2900.26],
            [2887.06, 2870.34, 2869.18, 2887.06],
            [2880.71, 2868.46, 2863.37, 2884.22],
            [2872.52, 2875.42, 2862.27, 2889.98],
            [2897.69, 2898.58, 2887.58, 2900.22],
            [2896.47, 2883.74, 2876.18, 2896.47],
            [2890.72, 2867.92, 2864.21, 2891.52],
            [2863.05, 2813.77, 2808.02, 2863.05],
            [2816.24, 2817.97, 2802.47, 2821.5],
            [2827.9, 2846.55, 2825.9, 2848.34],
            [2847.32, 2836.8, 2831.93, 2849.0],
            [2838.21, 2846.22, 2820.15, 2861.92],
            [2835.58, 2852.35, 2829.63, 2855.38],
            [2871.96, 2915.43, 2871.96, 2917.15],
            [2916.32, 2921.4, 2909.13, 2926.36],
            [2930.39, 2923.37, 2922.66, 2942.76],
            [2931.84, 2919.25, 2910.75, 2932.97],
            [2923.19, 2930.8, 2909.12, 2930.8],
            [2941.98, 2937.77, 2934.21, 2950.07],
            [2939.54, 2956.11, 2932.94, 2957.12],
            [2951.28, 2943.75, 2934.84, 2951.33],
            [2939.79, 2920.9, 2912.19, 2952.65],
            [2876.8, 2919.74, 2872.62, 2930.26],
            [2908.28, 2890.03, 2890.03, 2922.54],
            [2912.83, 2931.75, 2909.13, 2931.78],
            [2932.67, 2935.87, 2919.04, 2936.62],
            [2929.88, 2939.32, 2920.11, 2942.9],
            [2938.79, 2967.63, 2935.82, 2973.32],
            [2966.9, 2965.27, 2959.04, 2983.44],
            [2960.89, 2970.62, 2949.85, 2972.4],
            [2972.98, 2979.55, 2971.22, 2982.94],
            [2973.08, 2961.52, 2951.77, 2977.91],
            [2965.11, 2984.67, 2965.11, 2990.83],
            [2991.18, 3025.98, 2984.98, 3026.19],
            [3023.72, 3090.57, 3021.67, 3092.44],
            [3104.0, 3152.81, 3104.0, 3152.81],
            [3187.84, 3332.88, 3187.84, 3337.27],
            [3380.95, 3345.34, 3336.24, 3407.08],
            [3337.55, 3403.44, 3327.71, 3421.53],
            [3403.48, 3450.59, 3393.64, 3456.97],
            [3418.93, 3383.32, 3372.51, 3433.11],
            [3379.39, 3443.29, 3369.04, 3458.79],
            [3435.02, 3414.62, 3366.08, 3451.22],
            [3422.08, 3361.3, 3345.75, 3432.45],
            [3356.36, 3210.1, 3209.73, 3373.53],
            [3214.4, 3214.13, 3181.27, 3252.78],
            [3243.91, 3314.15, 3220.68, 3314.15],
            [3330.55, 3320.89, 3300.57, 3336.68],
            [3315.18, 3333.16, 3311.79, 3381.98],
            [3306.15, 3325.11, 3257.83, 3336.3],
            [3310.64, 3196.77, 3184.96, 3319.13],
            [3210.39, 3205.23, 3174.66, 3221.98],
            [3226.13, 3227.96, 3208.49, 3245.3],
            [3221.99, 3294.55, 3209.99, 3294.55],
            [3299.57, 3286.82, 3282.16, 3312.45],
            [3280.8, 3310.01, 3261.61, 3333.79],
            [3332.18, 3367.97, 3327.68, 3368.1],
            [3376.44, 3371.69, 3352.5, 3391.07],
            [3363.33, 3377.56, 3333.88, 3383.64],
            [3380.76, 3386.46, 3334.33, 3392.7],
            [3370.59, 3354.04, 3307.71, 3374.13],
            [3341.53, 3379.25, 3335.04, 3399.93],
            [3379.49, 3340.29, 3336.09, 3409.06],
            [3327.49, 3319.27, 3263.27, 3335.73],
            [3328.18, 3320.73, 3309.46, 3338.15],
            [3315.67, 3360.1, 3302.74, 3362.03],
            [3373.9, 3438.8, 3369.37, 3450.9],
            [3441.93, 3451.09, 3432.64, 3456.72],
            [3444.56, 3408.13, 3406.16, 3454.46],
            [3385.96, 3363.9, 3352.78, 3394.56],
            [3380.23, 3380.68, 3358.0, 3393.92],
            [3391.11, 3385.64, 3368.03, 3396.57],
            [3392.88, 3373.58, 3364.16, 3408.87],
            [3371.81, 3329.74, 3320.14, 3382.6],
            [3333.49, 3350.11, 3312.99, 3351.83],
            [3346.29, 3403.81, 3339.65, 3405.88],
            [3416.55, 3395.68, 3395.47, 3442.74],
            [3389.74, 3410.61, 3381.71, 3410.61],
            [3420.47, 3404.8, 3377.21, 3421.4],
            [3404.03, 3384.98, 3374.26, 3425.63],
            [3336.41, 3355.37, 3328.55, 3360.11],
            [3349.92, 3292.59, 3285.63, 3368.24],
            [3301.22, 3316.42, 3276.44, 3324.39],
            [3281.0, 3254.63, 3238.56, 3289.61],
            [3282.23, 3234.82, 3227.64, 3285.47],
            [3225.78, 3260.35, 3220.54, 3262.5],
            [3275.92, 3278.81, 3261.15, 3283.53],
            [3277.13, 3295.68, 3263.75, 3297.69],
            [3293.17, 3283.92, 3271.08, 3302.46],
            [3277.32, 3270.44, 3248.5, 3290.41],
            [3270.91, 3338.09, 3268.53, 3338.32],
            [3348.9, 3316.94, 3313.07, 3350.59],
            [3290.67, 3274.3, 3265.7, 3320.23],
            [3278.84, 3279.71, 3264.89, 3289.76],
            [3262.88, 3223.18, 3221.24, 3265.35],
            [3234.37, 3219.42, 3208.03, 3239.47],
            [3224.98, 3217.53, 3210.89, 3238.18],
            [3231.86, 3224.36, 3219.79, 3242.79],
            [3232.71, 3218.05, 3202.34, 3244.91],
            [3262.61, 3272.08, 3260.19, 3280.51],
            [3287.33, 3358.47, 3286.11, 3359.15],
            [3353.12, 3359.75, 3334.5, 3361.83],
            [3353.62, 3340.78, 3332.96, 3353.62],
            [3342.92, 3332.18, 3330.0, 3354.58],
            [3334.46, 3336.36, 3319.11, 3348.95],
            [3351.1, 3312.67, 3307.84, 3371.09],
            [3307.15, 3328.1, 3293.77, 3328.1],
            [3332.15, 3325.02, 3304.17, 3332.15],
            [3315.82, 3312.5, 3281.37, 3320.88],
            [3308.16, 3278.0, 3276.62, 3326.05],
            [3258.7, 3251.12, 3226.6, 3264.45],
            [3240.74, 3254.32, 3235.54, 3258.34],
            [3256.22, 3269.24, 3238.38, 3275.87],
            [3235.76, 3272.73, 3231.12, 3289.77],
            [3278.63, 3224.53, 3219.42, 3279.86],
            [3228.72, 3225.12, 3209.91, 3242.8],
            [3239.81, 3271.07, 3237.85, 3278.38],
            [3273.43, 3277.44, 3254.11, 3286.62],
            [3305.58, 3320.13, 3291.6, 3320.41],
            [3326.46, 3312.16, 3292.15, 3326.46],
            [3329.43, 3373.73, 3329.43, 3380.82],
            [3387.62, 3360.15, 3346.17, 3387.62],
            [3354.02, 3342.2, 3339.04, 3365.84],
            [3344.46, 3338.68, 3329.48, 3350.28],
            [3327.23, 3310.1, 3291.64, 3327.23],
            [3325.62, 3346.97, 3313.65, 3346.97],
            [3347.15, 3339.9, 3323.95, 3347.7],
            [3337.33, 3347.3, 3333.99, 3358.89],
            [3339.09, 3363.09, 3330.45, 3367.33],
            [3359.6, 3377.73, 3356.31, 3380.15],
            [3384.1, 3414.49, 3377.99, 3431.65],
            [3407.41, 3402.82, 3396.24, 3413.93],
            [3417.52, 3362.33, 3362.33, 3423.49],
            [3360.06, 3369.73, 3344.28, 3371.45],
            [3373.84, 3408.31, 3364.49, 3408.31],
            [3418.16, 3391.76, 3391.76, 3456.74],
            [3388.99, 3451.94, 3386.91, 3457.64],
            [3453.52, 3449.38, 3435.87, 3465.73],
            [3448.54, 3442.14, 3428.8, 3452.16],
            [3436.73, 3444.58, 3417.05, 3448.4],
            [3446.65, 3416.6, 3414.31, 3449.58],
            [3417.69, 3410.18, 3403.03, 3428.66],
            [3416.08, 3371.96, 3371.92, 3422.54],
            [3365.73, 3373.28, 3357.75, 3384.89],
            [3381.01, 3347.19, 3325.17, 3383.18],
            [3349.53, 3369.12, 3338.63, 3371.13],
            [3366.58, 3367.23, 3348.42, 3373.56],
            [3371.26, 3366.98, 3359.17, 3378.66],
            [3367.28, 3404.87, 3354.01, 3406.15],
            [3400.49, 3394.9, 3382.75, 3413.81],
            [3394.39, 3420.57, 3381.12, 3423.61],
            [3410.97, 3356.78, 3353.86, 3415.75],
            [3362.47, 3382.32, 3360.2, 3394.21],
            [3382.19, 3363.11, 3354.02, 3394.07],
            [3351.79, 3396.56, 3348.35, 3397.01],
            [3396.36, 3397.29, 3383.65, 3412.52],
            [3399.29, 3379.04, 3376.09, 3407.09],
            [3375.01, 3414.45, 3374.42, 3414.45],
            [3419.73, 3473.07, 3419.73, 3474.92],
            [3474.68, 3502.96, 3457.21, 3511.66],
            [3492.19, 3528.68, 3484.72, 3528.68],
            [3530.91, 3550.88, 3513.13, 3556.8],
            [3552.91, 3576.2, 3526.62, 3576.2],
            [3577.69, 3570.11, 3544.89, 3588.06],
            [3571.32, 3531.5, 3516.99, 3597.7],
            [3518.01, 3608.34, 3517.47, 3608.34],
            [3613.28, 3598.65, 3575.59, 3622.35],
            [3584.93, 3565.9, 3559.6, 3599.06],
            [3566.28, 3566.38, 3533.79, 3589.27],
            [3554.8, 3596.22, 3544.26, 3608.77],
            [3596.36, 3566.38, 3553.02, 3603.15],
            [3564.12, 3583.09, 3556.44, 3589.96],
            [3590.92, 3621.26, 3585.8, 3636.24],
            [3616.54, 3606.75, 3585.03, 3616.54],
            [3605.36, 3624.24, 3591.02, 3637.1],
            [3610.97, 3569.43, 3564.74, 3610.97],
            [3567.55, 3573.34, 3546.49, 3578.8],
            [3534.67, 3505.18, 3496.88, 3549.54],
            [3521.72, 3483.07, 3446.55, 3531.6],
            [3477.17, 3505.28, 3469.88, 3506.39],
            [3510.81, 3533.68, 3495.57, 3535.5],
            [3531.15, 3517.31, 3508.51, 3544.01],
            [3503.78, 3501.86, 3465.77, 3524.72],
            [3509.49, 3496.33, 3492.96, 3536.54],
            [3504.56, 3532.45, 3492.13, 3542.21],
            [3539.77, 3603.49, 3528.68, 3604.01],
            [3612.61, 3655.09, 3612.5, 3662.77],
            [3721.09, 3675.36, 3663.66, 3731.69],
            [3661.78, 3696.17, 3634.01, 3699.65],
            [3707.19, 3642.44, 3642.44, 3717.27],
            [3617.7, 3636.36, 3617.7, 3672.15],
            [3638.94, 3564.08, 3531.59, 3645.65],
            [3595.54, 3585.05, 3568.47, 3608.56],
            [3514.56, 3509.08, 3500.7, 3550.41],
            [3531.48, 3551.4, 3511.99, 3552.57],
            [3566.85, 3508.59, 3485.36, 3566.85],
            [3500.16, 3576.9, 3498.72, 3577.62],
            [3546.64, 3503.49, 3487.38, 3552.2],
            [3463.31, 3501.99, 3456.67, 3523.57],
            [3524.98, 3421.41, 3421.22, 3542.3],
            [3415.34, 3359.29, 3328.31, 3429.15],
            [3389.84, 3357.74, 3354.82, 3397.03],
            [3369.9, 3436.83, 3369.9, 3436.83],
            [3447.2, 3453.08, 3417.24, 3454.51],
            [3441.88, 3419.95, 3392.51, 3457.49],
            [3424.65, 3446.73, 3406.17, 3448.88],
            [3435.73, 3445.55, 3410.28, 3454.06],
            [3449.64, 3463.07, 3449.38, 3478.14],
            [3423.87, 3404.66, 3389.29, 3439.52],
            [3406.12, 3443.44, 3404.23, 3443.66],
            [3445.34, 3411.51, 3390.05, 3445.42],
            [3394.13, 3367.06, 3362.18, 3415.29],
            [3355.07, 3363.59, 3344.97, 3382.23],
            [3373.32, 3418.33, 3373.32, 3423.22],
            [3429.63, 3435.3, 3409.89, 3449.83],
            [3432.53, 3456.68, 3423.32, 3457.63],
            [3452.21, 3441.91, 3420.83, 3452.21],
            [3444.81, 3466.33, 3438.83, 3470.03],
            [3472.63, 3484.39, 3462.83, 3487.59],
            [3491.63, 3482.97, 3471.76, 3493.28],
            [3483.42, 3479.63, 3453.19, 3483.42],
            [3467.49, 3482.55, 3459.32, 3495.91],
            [3475.44, 3450.68, 3442.14, 3475.45],
            [3445.97, 3412.95, 3403.9, 3456.16],
            [3411.29, 3396.47, 3387.9, 3425.39],
            [3397.02, 3416.72, 3392.98, 3420.03],
            [3409.61, 3398.99, 3373.09, 3409.61],
            [3407.19, 3426.62, 3394.13, 3432.63],
            [3427.55, 3477.55, 3414.36, 3479.01],
            [3467.15, 3472.94, 3463.46, 3494.3],
            [3456.3, 3472.93, 3450.11, 3481.25],
            [3482.83, 3465.11, 3456.32, 3485.36],
            [3462.09, 3474.17, 3456.33, 3482.36],
            [3484.11, 3441.17, 3438.57, 3497.12],
            [3440.09, 3442.61, 3417.26, 3443.85],
            [3432.16, 3457.07, 3423.32, 3457.07],
            [3458.08, 3474.9, 3447.59, 3478.23],
            [3468.3, 3446.86, 3426.9, 3469.09],
            [3446.07, 3441.28, 3426.85, 3471.24],
            [3446.41, 3418.87, 3416.78, 3457.89],
            [3423.59, 3427.99, 3401.93, 3429.74],
            [3406.6, 3441.85, 3384.7, 3448.1],
            [3429.75, 3462.75, 3428.39, 3466.37],
            [3432.14, 3429.54, 3418.38, 3448.02],
            [3436.09, 3490.38, 3422.56, 3490.64],
            [3490.41, 3517.62, 3490.14, 3530.51],
            [3520.65, 3529.01, 3510.86, 3529.01],
            [3521.11, 3510.96, 3503.82, 3521.11],
            [3500.88, 3506.94, 3486.07, 3517.74],
            [3510.84, 3486.56, 3479.67, 3518.38],
            [3486.27, 3497.28, 3469.87, 3498.3],
            [3502.54, 3581.34, 3502.44, 3584.58],
            [3586.84, 3593.36, 3585.37, 3603.49],
            [3585.73, 3608.85, 3579.26, 3626.36],
            [3610.77, 3600.78, 3582.36, 3622.18],
            [3600.07, 3615.48, 3580.65, 3615.66],
            [3608.6, 3624.71, 3581.91, 3626.07],
            [3626.33, 3597.14, 3584.6, 3629.29],
            [3595.42, 3584.21, 3584.21, 3618.51],
            [3565.47, 3591.84, 3561.89, 3618.8],
            [3597.14, 3599.54, 3581.9, 3600.38],
            [3598.75, 3580.11, 3563.25, 3621.52],
            [3576.8, 3591.4, 3572.64, 3598.71],
            [3587.53, 3610.86, 3584.13, 3624.34],
            [3614.11, 3589.75, 3587.15, 3614.4],
            [3587.47, 3556.56, 3547.19, 3592.95],
            [3556.71, 3518.33, 3513.56, 3561.09],
            [3508.32, 3525.6, 3507.35, 3534.2],
            [3520.5, 3525.1, 3503.18, 3535.62],
            [3515.5, 3529.18, 3504.01, 3540.3],
            [3539.79, 3557.41, 3536.81, 3560.19],
            [3559.4, 3566.22, 3549.85, 3577.47],
            [3568.58, 3566.65, 3548.82, 3570.49],
            [3567.38, 3607.56, 3564.52, 3614.3],
            [3612.25, 3606.37, 3594.22, 3614.36],
            [3601.68, 3573.18, 3570.53, 3601.83],
            [3572.62, 3591.2, 3569.5, 3594.12],
            [3600.55, 3588.78, 3573.94, 3607.7],
            [3569.89, 3518.76, 3514.34, 3569.89],
            [3516.92, 3534.32, 3510.58, 3534.32],
            [3533.13, 3530.26, 3496.93, 3538.35],
            [3508.59, 3553.72, 3504.47, 3557.88],
            [3557.22, 3525.5, 3521.06, 3558.68],
            [3512.23, 3524.09, 3485.05, 3529.31],
            [3545.2, 3547.84, 3527.39, 3565.03],
            [3547.59, 3566.52, 3542.82, 3567.48],
            [3560.83, 3528.5, 3525.49, 3560.83],
            [3519.06, 3564.59, 3514.26, 3565.93],
            [3559.53, 3539.3, 3537.73, 3566.0],
            [3530.49, 3539.12, 3506.34, 3544.88],
            [3515.07, 3536.79, 3509.19, 3538.64],
            [3543.05, 3562.66, 3543.05, 3569.09],
            [3563.66, 3574.73, 3558.29, 3576.47],
            [3571.72, 3550.4, 3541.51, 3571.85],
            [3538.01, 3467.44, 3424.74, 3538.01],
            [3467.45, 3381.18, 3380.28, 3483.87],
            [3355.82, 3361.59, 3312.72, 3385.54],
            [3403.84, 3411.72, 3382.57, 3416.61],
            [3398.2, 3397.36, 3370.45, 3404.88],
            [3385.69, 3464.29, 3367.64, 3464.29],
            [3446.78, 3447.99, 3435.46, 3470.66],
            [3442.94, 3477.22, 3440.78, 3477.6],
            [3461.72, 3466.55, 3449.39, 3486.16],
            [3465.48, 3458.23, 3436.93, 3466.39],
            [3441.75, 3494.63, 3438.7, 3502.84],
            [3488.66, 3529.93, 3477.06, 3529.93],
            [3527.11, 3532.62, 3524.04, 3544.09],
            [3522.72, 3524.74, 3513.45, 3538.4],
            [3514.47, 3516.3, 3500.8, 3540.08],
            [3514.34, 3517.34, 3510.9, 3537.85],
            [3512.11, 3446.98, 3438.12, 3531.72],
            [3442.35, 3485.29, 3437.69, 3487.44],
            [3475.3, 3465.55, 3446.01, 3480.46],
            [3442.51, 3427.33, 3394.97, 3453.15],
            [3436.8, 3477.13, 3436.67, 3481.25],
            [3482.27, 3514.47, 3481.14, 3522.7],
            [3517.92, 3540.38, 3510.07, 3540.39],
            [3537.31, 3501.66, 3499.45, 3537.32],
            [3494.42, 3522.16, 3493.38, 3530.19],
            [3535.07, 3528.15, 3512.99, 3539.49],
            [3519.02, 3543.94, 3496.39, 3543.94],
            [3543.87, 3567.1, 3514.67, 3582.32],
            [3559.9, 3597.04, 3557.68, 3597.37],
            [3602.74, 3581.73, 3569.4, 3613.95],
            [3580.14, 3621.86, 3580.14, 3626.29],
            [3621.71, 3676.59, 3615.14, 3681.93],
            [3673.4, 3675.19, 3661.0, 3695.32],
            [3666.82, 3693.13, 3662.16, 3693.45],
            [3691.19, 3703.11, 3681.64, 3722.87],
            [3699.25, 3715.37, 3692.82, 3716.83],
            [3709.63, 3662.6, 3655.63, 3723.85],
            [3651.16, 3656.22, 3638.32, 3677.53],
            [3664.84, 3607.09, 3606.73, 3677.92],
            [3595.27, 3613.97, 3569.27, 3620.96],
            [3563.21, 3628.49, 3560.5, 3629.45],
            [3651.27, 3642.22, 3632.28, 3670.95],
            [3637.87, 3613.07, 3607.79, 3651.43],
            [3625.96, 3582.83, 3559.92, 3640.81],
            [3577.89, 3602.22, 3568.82, 3610.92],
            [3573.52, 3536.29, 3518.05, 3573.52],
            [3541.93, 3568.17, 3541.93, 3572.43],
            [3609.09, 3592.17, 3571.73, 3612.55],
            [3600.36, 3591.71, 3586.75, 3614.7],
            [3581.3, 3546.94, 3515.14, 3583.64],
            [3543.49, 3561.76, 3515.65, 3569.13],
            [3555.11, 3558.28, 3547.18, 3569.69],
            [3551.99, 3572.37, 3542.69, 3578.77],
            [3571.05, 3568.14, 3539.48, 3571.05],
            [3562.3, 3593.15, 3560.62, 3596.79],
            [3583.24, 3587.0, 3574.3, 3596.05],
            [3590.05, 3594.78, 3576.35, 3610.96],
            [3594.75, 3582.6, 3578.76, 3607.58],
            [3574.26, 3609.86, 3564.21, 3611.09],
            [3612.83, 3597.64, 3589.71, 3625.02],
            [3589.86, 3562.31, 3553.13, 3589.86],
            [3548.7, 3518.42, 3509.49, 3552.04],
            [3519.33, 3547.34, 3502.8, 3547.34],
            [3530.4, 3544.48, 3519.29, 3556.59],
            [3543.38, 3505.63, 3477.66, 3559.05],
            [3501.11, 3498.54, 3480.49, 3512.61],
            [3505.89, 3526.87, 3503.01, 3527.95],
            [3520.21, 3491.57, 3491.46, 3525.87],
            [3491.97, 3498.63, 3484.24, 3507.27],
            [3507.11, 3507.0, 3489.04, 3514.95],
            [3498.94, 3492.46, 3448.44, 3498.94],
            [3486.45, 3532.79, 3482.83, 3534.2],
            [3534.15, 3539.1, 3527.39, 3543.65],
            [3542.9, 3533.3, 3521.29, 3550.44],
            [3530.46, 3521.79, 3517.81, 3549.77],
            [3518.56, 3537.37, 3513.52, 3537.51],
            [3531.49, 3520.71, 3513.11, 3537.98],
            [3519.28, 3560.37, 3517.65, 3561.91],
            [3562.76, 3582.08, 3562.75, 3585.19],
            [3580.51, 3589.09, 3577.36, 3598.37],
            [3590.02, 3592.7, 3575.29, 3602.74],
            [3593.39, 3584.18, 3579.53, 3597.15],
            [3576.11, 3564.09, 3554.88, 3576.11],
            [3528.67, 3562.7, 3526.36, 3563.68],
            [3570.75, 3563.89, 3546.36, 3582.12],
            [3561.89, 3576.89, 3558.69, 3576.89],
            [3573.25, 3573.84, 3567.14, 3586.87],
            [3576.45, 3607.43, 3573.21, 3608.47],
            [3615.24, 3589.31, 3586.81, 3626.13],
            [3611.22, 3595.09, 3572.57, 3614.22],
            [3602.82, 3637.57, 3591.99, 3637.72],
            [3641.16, 3673.04, 3638.7, 3688.4],
            [3654.37, 3666.35, 3651.35, 3667.85],
            [3686.94, 3681.08, 3678.06, 3708.94],
            [3669.81, 3661.53, 3654.66, 3671.68],
            [3655.05, 3647.63, 3645.24, 3668.4],
            [3648.93, 3675.02, 3644.66, 3675.02],
            [3670.26, 3632.36, 3631.66, 3673.65],
            [3620.04, 3593.6, 3589.36, 3643.95],
            [3591.45, 3625.13, 3591.45, 3627.09],
            [3632.68, 3622.62, 3616.55, 3635.9],
            [3625.47, 3643.34, 3618.05, 3643.55],
            [3645.39, 3618.05, 3612.07, 3648.96],
            [3613.05, 3615.97, 3601.94, 3632.19],
            [3619.64, 3630.11, 3607.36, 3631.08],
            [3630.92, 3597.0, 3596.32, 3630.92],
            [3596.49, 3619.19, 3595.5, 3628.92],
            [3626.24, 3639.78, 3624.94, 3642.84],
            [3649.15, 3632.33, 3610.09, 3651.89],
            [3628.26, 3595.18, 3583.47, 3628.26],
            [3581.22, 3586.08, 3559.88, 3594.49],
            [3588.99, 3579.54, 3577.1, 3607.23],
            [3572.74, 3593.52, 3555.13, 3593.52],
            [3589.9, 3567.44, 3562.75, 3602.15],
            [3578.16, 3597.43, 3572.1, 3599.5],
            [3601.03, 3555.26, 3555.16, 3601.07],
            [3544.07, 3521.26, 3519.32, 3548.42],
            [3522.09, 3541.67, 3519.43, 3546.09],
            [3541.89, 3569.91, 3531.33, 3579.31],
            [3567.63, 3558.18, 3541.66, 3578.73],
            [3556.23, 3555.06, 3540.55, 3576.26],
            [3546.75, 3522.57, 3514.89, 3547.0],
            [3508.24, 3524.1, 3500.14, 3531.61],
            [3509.28, 3433.06, 3433.06, 3519.83],
            [3442.69, 3455.67, 3417.76, 3462.12],
            [3456.1, 3394.25, 3392.02, 3456.36],
            [3407.59, 3361.44, 3356.56, 3417.05],
            [3407.76, 3429.58, 3407.76, 3434.03],
            [3428.54, 3452.63, 3390.46, 3453.19],
            [3450.82, 3479.95, 3444.08, 3484.74],
            [3481.91, 3485.91, 3464.22, 3488.86],
            [3472.28, 3462.95, 3459.33, 3500.15],
            [3451.85, 3428.88, 3415.45, 3457.26],
            [3428.04, 3446.09, 3421.64, 3447.49],
            [3457.07, 3465.83, 3453.8, 3475.06],
            [3464.21, 3468.04, 3454.28, 3480.97],
            [3451.63, 3490.76, 3447.03, 3490.76],
            [3488.41, 3490.61, 3471.69, 3492.2],
            [3473.29, 3457.15, 3437.67, 3473.39],
            [3458.51, 3489.15, 3458.18, 3490.76],
            [3474.37, 3429.96, 3400.21, 3486.98],
            [3445.34, 3451.41, 3440.93, 3480.18],
            [3450.32, 3462.31, 3425.52, 3462.31],
            [3471.36, 3488.83, 3465.72, 3491.13],
            [3478.29, 3484.19, 3467.0, 3486.62],
            [3495.93, 3481.11, 3473.34, 3500.29],
            [3459.98, 3447.65, 3437.7, 3474.88],
            [3438.56, 3372.86, 3360.74, 3438.56],
            [3372.55, 3293.53, 3287.34, 3383.63],
            [3303.71, 3256.39, 3147.68, 3321.48],
            [3312.18, 3296.09, 3291.24, 3326.58],
            [3259.32, 3309.75, 3217.42, 3315.66],
            [3271.89, 3223.53, 3223.53, 3297.8],
            [3192.36, 3063.97, 3063.97, 3196.92],
            [3107.67, 3170.71, 3023.3, 3177.79],
            [3215.01, 3215.04, 3202.93, 3260.17],
            [3207.15, 3251.07, 3197.36, 3260.78],
            [3255.62, 3253.69, 3223.39, 3267.5],
            [3249.54, 3259.86, 3239.53, 3279.11],
            [3264.79, 3271.03, 3251.98, 3279.89],
            [3256.06, 3250.26, 3236.66, 3266.89],
            [3247.16, 3212.24, 3211.64, 3257.11],
            [3185.17, 3214.5, 3159.83, 3230.22],
            [3216.01, 3203.94, 3196.46, 3229.18],
            [3217.59, 3266.6, 3216.3, 3266.6],
            [3256.14, 3252.2, 3246.06, 3272.04],
            [3234.67, 3282.72, 3226.3, 3287.23],
            [3269.43, 3283.43, 3255.69, 3288.11],
            [3267.81, 3236.7, 3236.48, 3290.26],
            [3239.88, 3251.85, 3208.35, 3257.09],
            [3239.86, 3167.13, 3156.51, 3239.86],
            [3165.12, 3213.33, 3140.9, 3214.54],
            [3199.8, 3186.82, 3183.53, 3225.47],
            [3203.63, 3225.64, 3200.23, 3240.0],
            [3210.7, 3211.24, 3200.13, 3229.87],
            [3185.94, 3195.52, 3166.98, 3204.03],
            [3192.09, 3194.03, 3174.71, 3207.83],
            [3189.89, 3151.05, 3142.05, 3191.83],
            [3138.43, 3079.81, 3069.68, 3160.9],
            [3058.4, 3086.92, 3049.36, 3105.66],
            [3034.27, 2928.51, 2928.51, 3043.82],
            [2930.45, 2886.43, 2878.26, 2957.68],
            [2866.82, 2958.28, 2863.65, 2959.18],
            [2945.81, 2975.48, 2936.79, 2991.51],
            [2986.06, 3047.06, 2968.33, 3048.49],
            [3044.85, 3067.76, 3042.12, 3082.23],
            [3011.32, 3001.56, 2992.72, 3030.69],
            [2990.2, 3004.14, 2983.61, 3015.94],
            [2965.78, 3035.84, 2957.4, 3043.78],
            [3035.39, 3058.7, 3034.67, 3100.9],
            [3044.8, 3054.99, 3032.95, 3072.16],
            [3068.03, 3084.28, 3059.25, 3086.1],
            [3100.55, 3073.75, 3063.4, 3102.58],
            [3076.5, 3093.7, 3057.64, 3093.7],
            [3095.89, 3085.98, 3071.3, 3105.84],
            [3046.71, 3096.96, 3042.67, 3096.96],
            [3107.09, 3146.57, 3107.09, 3146.57],
            [3150.49, 3146.86, 3127.9, 3150.51],
            [3149.06, 3070.93, 3070.93, 3153.13],
            [3070.17, 3107.46, 3069.95, 3107.63],
            [3111.48, 3123.11, 3079.48, 3133.28],
            [3135.03, 3130.24, 3112.54, 3151.05],
            [3141.96, 3149.06, 3123.15, 3150.89],
            [3149.94, 3186.43, 3142.0, 3188.6],
            [3179.69, 3182.16, 3160.04, 3190.61],
            [3170.31, 3195.46, 3163.76, 3197.28],
            [3196.96, 3236.37, 3181.65, 3237.07],
            [3235.42, 3241.76, 3222.64, 3253.09],
            [3245.02, 3263.79, 3216.01, 3266.63],
            [3259.49, 3238.95, 3223.48, 3270.56],
            [3214.18, 3284.83, 3210.81, 3286.62],
            [3256.28, 3255.55, 3229.31, 3272.99],
            [3224.21, 3288.91, 3195.82, 3289.13],
            [3289.1, 3305.41, 3288.85, 3358.55],
            [3306.84, 3285.38, 3277.53, 3319.69],
            [3265.51, 3316.79, 3262.89, 3323.28],
            [3315.78, 3315.43, 3292.93, 3333.9],
            [3313.79, 3306.72, 3279.83, 3329.39],
            [3309.12, 3267.2, 3266.54, 3311.02],
            [3269.05, 3320.15, 3262.29, 3320.15],
            [3324.74, 3349.75, 3322.95, 3356.71],
            [3364.0, 3379.19, 3364.0, 3393.31],
            [3377.68, 3409.21, 3358.87, 3412.1],
            [3399.68, 3361.52, 3358.47, 3414.65],
            [3358.93, 3398.62, 3358.93, 3417.01],
            [3400.26, 3387.64, 3378.36, 3404.05],
            [3381.82, 3405.43, 3364.09, 3405.62],
            [3411.13, 3404.03, 3372.06, 3424.84],
            [3391.03, 3355.35, 3333.1, 3391.03],
            [3353.13, 3364.4, 3332.31, 3375.86],
            [3380.37, 3356.08, 3354.12, 3386.31],
            [3341.1, 3313.58, 3297.0, 3341.1],
            [3307.22, 3281.47, 3277.08, 3319.25],
            [3279.6, 3284.29, 3266.48, 3297.02],
            [3277.45, 3281.74, 3261.49, 3299.25],
            [3261.38, 3228.06, 3228.06, 3288.92],
            [3235.09, 3278.1, 3226.23, 3278.47],
            [3278.72, 3279.43, 3256.02, 3283.93],
            [3291.55, 3304.72, 3287.74, 3308.35],
            [3297.66, 3272.0, 3272.0, 3300.53],
            [3276.2, 3269.97, 3246.73, 3293.64],
            [3269.71, 3250.39, 3243.03, 3273.18],
            [3254.19, 3277.44, 3246.04, 3282.41],
            [3271.78, 3275.76, 3265.73, 3282.57],
            [3287.5, 3282.58, 3277.11, 3305.71],
            [3282.81, 3253.24, 3246.37, 3294.8],
            [3246.62, 3259.96, 3225.55, 3264.3],
            [3231.26, 3186.27, 3155.19, 3231.26],
            [3188.89, 3163.67, 3159.46, 3217.55],
            [3179.43, 3189.04, 3155.7, 3191.0],
            [3195.23, 3227.03, 3184.45, 3228.89],
            [3218.85, 3236.93, 3214.08, 3237.97],
            [3235.74, 3247.43, 3227.54, 3248.77],
            [3242.36, 3230.02, 3217.81, 3253.17],
            [3243.47, 3281.67, 3237.9, 3281.67],
            [3275.77, 3276.89, 3272.84, 3288.22],
            [3268.37, 3276.09, 3261.82, 3286.89],
            [3278.68, 3277.88, 3271.57, 3295.02],
            [3282.1, 3292.53, 3263.35, 3296.0],
            [3286.37, 3277.54, 3270.56, 3289.14],
            [3275.62, 3258.08, 3258.06, 3286.49],
            [3249.7, 3277.79, 3247.19, 3278.17],
            [3274.43, 3276.22, 3262.63, 3284.6],
            [3279.17, 3215.2, 3212.39, 3281.88],
            [3223.46, 3246.25, 3199.12, 3248.35],
            [3250.63, 3236.22, 3232.28, 3266.27],
            [3203.1, 3240.73, 3199.0, 3240.73],
            [3240.18, 3227.22, 3212.63, 3243.75],
            [3216.53, 3202.14, 3184.39, 3232.02],
            [3196.54, 3184.98, 3181.63, 3214.56],
            [3189.64, 3186.48, 3173.79, 3198.28],
            [3183.95, 3199.91, 3172.04, 3199.91],
            [3207.93, 3243.45, 3203.82, 3244.64],
            [3232.14, 3246.29, 3227.82, 3253.77],
            [3245.56, 3235.59, 3233.8, 3253.7],
            [3241.18, 3262.05, 3236.51, 3266.2],
            [3272.05, 3263.8, 3259.66, 3278.17],
            [3224.68, 3237.54, 3221.96, 3250.8],
            [3248.97, 3199.92, 3174.39, 3254.18],
            [3189.83, 3126.4, 3126.4, 3191.83],
            [3122.75, 3115.6, 3101.22, 3135.56],
            [3127.84, 3122.41, 3114.04, 3140.03],
            [3116.01, 3117.18, 3091.3, 3129.82],
            [3098.77, 3108.91, 3092.82, 3125.68],
            [3106.81, 3088.37, 3072.24, 3124.66],
            [3067.57, 3051.23, 3048.51, 3102.65],
            [3056.39, 3093.86, 3048.36, 3094.04],
            [3089.1, 3045.07, 3044.86, 3089.1],
            [3067.47, 3041.2, 3026.08, 3076.76],
            [3042.17, 3024.39, 3021.93, 3054.61],
            [3026.94, 2974.15, 2968.28, 3029.45],
            [2978.06, 2979.79, 2953.5, 2986.91],
            [2976.72, 3025.51, 2934.09, 3025.51],
            [3008.3, 3016.36, 3004.5, 3036.25],
            [3035.03, 3071.99, 3035.03, 3084.27],
            [3060.52, 3084.94, 3052.71, 3087.19],
            [3094.93, 3080.96, 3074.22, 3099.92],
            [3073.26, 3044.38, 3044.38, 3081.39],
            [3029.3, 3035.05, 3013.69, 3070.26],
            [3038.04, 3038.93, 3026.96, 3055.42],
            [3034.75, 2977.56, 2965.17, 3064.42],
            [2969.16, 2976.28, 2944.26, 3001.72],
            [2977.56, 2999.5, 2977.56, 3028.35],
            [3005.04, 2982.9, 2981.69, 3017.26],
            [2967.02, 2915.93, 2908.98, 2974.24],
            [2893.2, 2893.48, 2885.09, 2926.02],
            [2899.5, 2969.2, 2896.76, 2969.2],
            [2960.65, 3003.37, 2954.95, 3019.05],
            [2981.2, 2997.81, 2977.72, 3003.72],
            [2997.0, 3070.8, 2997.0, 3081.59],
            [3062.86, 3077.82, 3054.46, 3088.19],
            [3077.31, 3064.49, 3047.46, 3078.28],
            [3064.46, 3048.17, 3046.19, 3073.92],
            [3031.69, 3036.13, 3022.85, 3047.98],
            [3099.65, 3087.29, 3070.3, 3117.74],
            [3100.87, 3083.4, 3075.22, 3121.41],
            [3081.13, 3134.08, 3074.5, 3135.59],
            [3133.65, 3119.98, 3115.35, 3145.75],
            [3110.96, 3115.43, 3087.17, 3115.44],
            [3116.73, 3097.24, 3096.89, 3126.71],
            [3078.06, 3085.04, 3056.17, 3085.24],
            [3084.23, 3088.94, 3076.32, 3118.12],
            [3084.74, 3096.91, 3075.32, 3108.24],
            [3104.1, 3089.31, 3084.86, 3113.24],
            [3085.46, 3101.69, 3077.97, 3111.42],
            [3055.29, 3078.55, 3034.7, 3080.18],
            [3096.11, 3149.75, 3096.11, 3152.0],
            [3141.4, 3151.34, 3137.37, 3158.57],
            [3187.99, 3165.47, 3164.53, 3198.41],
            [3160.58, 3156.14, 3149.84, 3170.9],
            [3181.92, 3211.81, 3177.06, 3213.44],
            [3200.28, 3212.53, 3195.08, 3224.82],
            [3204.94, 3199.62, 3188.67, 3226.08],
            [3196.02, 3197.35, 3187.26, 3206.72],
            [3197.12, 3206.95, 3182.91, 3212.11],
            [3195.87, 3179.04, 3176.58, 3196.72],
            [3179.44, 3176.33, 3171.48, 3187.2],
            [3178.55, 3176.53, 3168.59, 3189.84],
            [3177.2, 3168.65, 3158.45, 3179.1],
            [3156.13, 3167.86, 3151.61, 3175.35],
            [3165.31, 3107.12, 3096.1, 3170.26],
            [3098.95, 3073.77, 3061.51, 3100.75],
            [3078.33, 3068.41, 3060.55, 3085.8],
            [3085.8, 3054.43, 3044.6, 3096.24],
            [3038.84, 3045.87, 3031.54, 3061.87],
            [3048.2, 3065.56, 3047.35, 3071.84],
            [3077.75, 3095.57, 3074.31, 3098.08],
            [3088.62, 3087.4, 3079.43, 3098.65],
            [3076.73, 3073.7, 3064.46, 3086.0],
            [3084.52, 3089.26, 3082.2, 3096.31],
            [3087.51, 3116.51, 3073.05, 3119.86],
            [3117.57, 3123.52, 3109.45, 3129.09],
            [3132.76, 3155.22, 3130.23, 3159.43],
            [3155.07, 3157.64, 3151.84, 3170.74],
            [3169.37, 3176.08, 3165.43, 3183.58],
            [3178.02, 3169.51, 3165.14, 3178.16],
            [3172.38, 3161.84, 3160.89, 3184.76],
            [3167.27, 3163.45, 3153.4, 3171.59],
            [3170.35, 3195.31, 3167.51, 3195.31],
            [3198.52, 3227.59, 3197.32, 3251.37],
            [3229.44, 3224.24, 3211.76, 3231.26],
            [3227.59, 3224.41, 3220.37, 3234.82],
            [3221.52, 3240.28, 3210.38, 3240.28],
            [3247.2, 3264.81, 3247.2, 3267.06],
            [3308.87, 3269.32, 3266.76, 3310.49],
            [3266.14, 3255.67, 3252.01, 3277.25],
            [3262.2, 3284.92, 3245.41, 3284.92],
            [3291.78, 3285.67, 3272.41, 3295.76],
            [3275.66, 3263.41, 3235.35, 3275.66],
            [3244.7, 3238.7, 3226.21, 3250.35],
            [3245.23, 3248.09, 3233.83, 3250.03],
            [3251.5, 3232.11, 3230.44, 3257.11],
            [3227.73, 3270.38, 3225.77, 3270.38],
            [3266.44, 3260.67, 3247.23, 3269.99],
            [3256.99, 3284.16, 3252.63, 3285.09],
            [3288.97, 3293.28, 3279.19, 3293.73],
            [3294.02, 3280.49, 3274.55, 3296.2],
            [3281.74, 3249.03, 3231.34, 3308.83],
            [3244.73, 3224.02, 3223.26, 3262.47],
            [3230.47, 3290.34, 3225.84, 3291.04],
            [3291.63, 3306.52, 3282.44, 3308.79],
            [3292.05, 3291.15, 3286.72, 3307.03],
            [3293.52, 3287.48, 3275.36, 3307.44],
            [3287.26, 3267.16, 3253.97, 3288.39],
            [3257.0, 3258.03, 3251.72, 3276.58],
            [3265.74, 3279.61, 3246.14, 3280.42],
            [3279.14, 3312.35, 3272.04, 3315.16],
            [3308.01, 3310.65, 3306.46, 3320.74],
            [3314.77, 3328.39, 3302.62, 3330.6],
            [3332.02, 3322.03, 3308.73, 3333.06],
            [3320.21, 3285.1, 3284.41, 3342.86],
            [3271.88, 3283.25, 3263.69, 3283.25],
            [3285.94, 3276.09, 3260.0, 3289.06],
            [3255.51, 3230.08, 3229.5, 3262.15],
            [3228.12, 3268.7, 3228.12, 3268.7],
            [3261.92, 3245.31, 3216.99, 3262.0],
            [3261.03, 3263.31, 3256.86, 3273.12],
            [3244.47, 3226.89, 3224.72, 3257.43],
            [3245.26, 3250.55, 3240.16, 3279.92],
            [3253.93, 3234.91, 3231.65, 3267.99],
            [3240.84, 3255.65, 3237.89, 3256.0],
            [3263.41, 3265.75, 3255.74, 3279.39],
            [3258.78, 3286.65, 3252.99, 3286.65],
            [3282.74, 3265.65, 3260.84, 3282.74],
            [3261.82, 3251.4, 3229.7, 3261.82],
            [3256.6, 3245.38, 3241.74, 3264.1],
            [3253.62, 3240.06, 3229.58, 3254.56],
            [3243.07, 3261.25, 3220.98, 3263.74],
            [3263.41, 3272.86, 3260.04, 3278.42],
            [3277.34, 3296.4, 3276.42, 3299.73],
            [3297.18, 3312.56, 3290.99, 3312.56],
            [3302.75, 3312.63, 3296.06, 3314.53],
            [3312.48, 3327.65, 3308.06, 3328.85],
            [3331.51, 3315.36, 3309.92, 3332.72],
            [3317.08, 3313.57, 3298.25, 3317.45],
            [3316.86, 3327.18, 3315.4, 3330.71],
            [3321.27, 3318.36, 3309.25, 3334.47],
            [3326.37, 3338.15, 3316.81, 3340.08],
            [3337.06, 3385.61, 3336.39, 3385.61],
            [3379.23, 3393.33, 3375.78, 3396.17],
            [3391.35, 3370.13, 3364.64, 3394.96],
            [3367.05, 3367.03, 3344.02, 3371.37],
            [3364.0, 3301.26, 3301.26, 3367.61],
            [3300.81, 3275.41, 3264.93, 3303.09],
            [3276.4, 3264.87, 3229.45, 3288.57],
            [3255.22, 3264.1, 3239.99, 3277.98],
            [3256.23, 3285.88, 3253.11, 3299.3],
            [3283.12, 3323.27, 3283.12, 3324.88],
            [3306.48, 3350.46, 3302.03, 3352.75],
            [3350.65, 3334.5, 3321.19, 3364.14],
            [3340.99, 3395.0, 3340.99, 3400.18],
            [3402.39, 3357.67, 3356.12, 3418.95],
            [3347.7, 3319.15, 3305.25, 3349.72],
            [3323.66, 3309.55, 3307.29, 3333.25],
            [3304.46, 3272.36, 3271.43, 3319.13],
            [3269.88, 3310.74, 3235.87, 3310.74],
            [3310.16, 3290.99, 3282.49, 3317.9],
            [3281.97, 3284.23, 3269.89, 3298.52],
            [3288.41, 3297.32, 3284.17, 3313.86],
            [3288.89, 3283.54, 3268.22, 3302.34],
            [3278.6, 3296.47, 3276.54, 3308.53],
            [3294.79, 3246.24, 3246.24, 3298.23],
            [3237.7, 3204.75, 3204.38, 3237.7],
            [3197.36, 3201.26, 3168.57, 3207.02],
            [3198.88, 3212.5, 3179.82, 3220.77],
            [3219.76, 3221.45, 3208.96, 3230.97],
            [3214.67, 3224.21, 3186.63, 3230.63],
            [3214.66, 3204.56, 3190.27, 3216.36],
            [3196.15, 3204.63, 3189.52, 3225.09],
            [3212.45, 3230.07, 3211.74, 3233.99],
            [3231.81, 3232.44, 3221.63, 3244.18],
            [3228.41, 3195.34, 3189.27, 3247.35],
            [3197.47, 3197.76, 3188.01, 3210.33],
            [3194.83, 3213.59, 3180.54, 3225.53],
            [3213.03, 3231.41, 3206.1, 3231.41],
            [3225.3, 3228.83, 3210.71, 3239.44],
            [3223.9, 3233.67, 3215.5, 3235.09],
            [3238.12, 3228.99, 3226.77, 3248.46],
            [3230.45, 3252.98, 3229.15, 3252.98],
            [3256.3, 3273.33, 3255.5, 3276.55],
            [3271.16, 3255.81, 3251.78, 3271.16],
            [3252.54, 3240.36, 3237.79, 3252.84],
            [3230.11, 3197.9, 3197.9, 3236.36],
            [3177.23, 3150.62, 3144.25, 3181.08],
            [3153.31, 3189.44, 3148.27, 3194.41],
            [3183.49, 3189.38, 3157.12, 3192.66],
            [3185.42, 3182.38, 3179.53, 3196.5],
            [3178.92, 3202.06, 3177.99, 3212.99],
            [3209.16, 3243.98, 3209.16, 3246.86],
            [3241.23, 3245.35, 3233.99, 3246.96],
            [3240.05, 3222.95, 3218.6, 3241.84],
            [3215.86, 3205.57, 3199.72, 3229.48],
            [3197.47, 3196.61, 3189.21, 3212.05],
            [3208.73, 3203.7, 3192.66, 3216.53],
            [3211.12, 3221.37, 3200.38, 3221.68],
            [3220.38, 3196.13, 3193.44, 3224.33],
            [3202.7, 3236.48, 3202.7, 3238.47],
            [3240.97, 3237.7, 3234.0, 3248.38],
            [3219.02, 3209.63, 3195.89, 3219.02],
            [3206.79, 3197.82, 3190.0, 3210.25],
            [3195.01, 3198.84, 3181.56, 3204.36],
            [3201.91, 3169.52, 3165.67, 3209.06],
            [3163.42, 3167.75, 3157.33, 3185.64],
            [3157.47, 3164.16, 3151.13, 3178.73],
            [3201.46, 3231.52, 3201.46, 3231.75],
            [3228.09, 3223.03, 3212.88, 3229.89],
            [3225.48, 3216.67, 3210.47, 3245.6],
            [3206.74, 3275.93, 3200.99, 3280.28],
            [3287.21, 3291.04, 3281.01, 3322.13],
            [3288.76, 3290.95, 3279.57, 3305.34],
            [3281.86, 3261.69, 3252.3, 3290.82],
            [3254.57, 3280.46, 3247.27, 3280.86],
            [3296.09, 3288.08, 3281.73, 3315.05],
            [3276.78, 3268.83, 3258.54, 3276.78],
            [3260.72, 3260.62, 3246.67, 3274.52],
            [3251.15, 3244.49, 3240.29, 3257.08],
            [3242.21, 3254.56, 3234.12, 3257.03],
            [3254.48, 3189.25, 3189.25, 3254.48],
            [3159.71, 3178.43, 3141.66, 3180.51],
            [3180.56, 3176.18, 3147.35, 3186.23],
            [3164.7, 3150.13, 3149.72, 3176.63],
            [3137.35, 3163.74, 3123.25, 3167.45],
            [3165.1, 3131.95, 3131.95, 3179.12],
            [3125.99, 3092.98, 3092.98, 3134.96],
            [3103.78, 3120.33, 3074.53, 3126.78],
            [3116.27, 3078.4, 3077.61, 3116.38],
            [3085.91, 3082.24, 3072.95, 3106.18],
            [3068.62, 3064.07, 3053.04, 3085.05],
            [3219.04, 3098.64, 3089.12, 3219.04],
            [3091.94, 3135.89, 3090.44, 3145.76],
            [3144.41, 3137.14, 3126.9, 3153.69],
            [3134.55, 3119.88, 3113.95, 3139.98],
            [3126.02, 3133.25, 3123.19, 3143.85],
            [3145.94, 3177.06, 3143.38, 3177.06],
            [3169.74, 3154.37, 3150.08, 3169.74],
          ],
          itemStyle: {
            color: '#ec0000',
            color0: '#00da3c',
            borderColor: '#ec0000',
            borderColor0: '#00da3c',
          },
          markArea: {
            silent: false,
            label: {
              show: true,
              position: 'top',
              margin: 8,
            },
            data: [],
          },
          rippleEffect: {
            show: true,
            brushType: 'stroke',
            scale: 2.5,
            period: 4,
          },
        },
        {
          type: 'line',
          name: '\u5206\u7b14',
          connectNulls: false,
          xAxisIndex: 0,
          yAxisIndex: 0,
          symbolSize: 4,
          showSymbol: true,
          smooth: false,
          step: false,
          data: [
            ['2019-09-16T00:00:00', 3042.93],
            ['2019-10-09T00:00:00', 2891.54],
            ['2019-10-14T00:00:00', 3026.38],
            ['2019-12-03T00:00:00', 2857.32],
            ['2020-01-14T00:00:00', 3127.17],
            ['2020-02-04T00:00:00', 2685.27],
            ['2020-02-21T00:00:00', 3058.9],
            ['2020-02-28T00:00:00', 2878.54],
            ['2020-03-05T00:00:00', 3074.26],
            ['2020-03-19T00:00:00', 2646.8],
            ['2020-04-17T00:00:00', 2854.96],
            ['2020-04-28T00:00:00', 2758.25],
            ['2020-05-11T00:00:00', 2914.28],
            ['2020-05-25T00:00:00', 2802.47],
            ['2020-07-09T00:00:00', 3456.97],
            ['2020-07-27T00:00:00', 3174.66],
            ['2020-08-18T00:00:00', 3456.72],
            ['2020-09-11T00:00:00', 3220.54],
            ['2020-09-21T00:00:00', 3350.59],
            ['2020-09-25T00:00:00', 3208.03],
            ['2020-10-13T00:00:00', 3361.83],
            ['2020-11-02T00:00:00', 3209.91],
            ['2020-12-02T00:00:00', 3465.73],
            ['2020-12-11T00:00:00', 3325.17],
            ['2021-01-25T00:00:00', 3637.1],
            ['2021-01-29T00:00:00', 3446.55],
            ['2021-02-18T00:00:00', 3731.69],
            ['2021-03-09T00:00:00', 3328.31],
            ['2021-03-18T00:00:00', 3478.14],
            ['2021-03-25T00:00:00', 3344.97],
            ['2021-04-08T00:00:00', 3495.91],
            ['2021-04-15T00:00:00', 3373.09],
            ['2021-04-26T00:00:00', 3497.12],
            ['2021-05-11T00:00:00', 3384.7],
            ['2021-06-02T00:00:00', 3629.29],
            ['2021-06-18T00:00:00', 3503.18],
            ['2021-06-28T00:00:00', 3614.36],
            ['2021-07-09T00:00:00', 3485.05],
            ['2021-07-22T00:00:00', 3576.47],
            ['2021-07-28T00:00:00', 3312.72],
            ['2021-08-11T00:00:00', 3544.09],
            ['2021-08-20T00:00:00', 3394.97],
            ['2021-09-14T00:00:00', 3723.85],
            ['2021-10-12T00:00:00', 3515.14],
            ['2021-10-26T00:00:00', 3625.02],
            ['2021-11-10T00:00:00', 3448.44],
            ['2021-12-13T00:00:00', 3708.94],
            ['2022-01-28T00:00:00', 3356.56],
            ['2022-03-03T00:00:00', 3500.29],
            ['2022-03-16T00:00:00', 3023.3],
            ['2022-04-07T00:00:00', 3290.26],
            ['2022-04-27T00:00:00', 2863.65],
            ['2022-06-15T00:00:00', 3358.55],
            ['2022-06-23T00:00:00', 3262.29],
            ['2022-07-05T00:00:00', 3424.84],
            ['2022-08-02T00:00:00', 3155.19],
            ['2022-08-17T00:00:00', 3296.0],
            ['2022-09-05T00:00:00', 3172.04],
            ['2022-09-13T00:00:00', 3278.17],
            ['2022-10-12T00:00:00', 2934.09],
            ['2022-10-18T00:00:00', 3099.92],
            ['2022-10-31T00:00:00', 2885.09],
            ['2022-11-16T00:00:00', 3145.75],
            ['2022-11-28T00:00:00', 3034.7],
            ['2022-12-07T00:00:00', 3226.08],
            ['2022-12-23T00:00:00', 3031.54],
            ['2023-01-30T00:00:00', 3310.49],
            ['2023-02-17T00:00:00', 3223.26],
            ['2023-03-07T00:00:00', 3342.86],
            ['2023-03-14T00:00:00', 3216.99],
            ['2023-04-18T00:00:00', 3396.17],
            ['2023-04-25T00:00:00', 3229.45],
            ['2023-05-09T00:00:00', 3418.95],
            ['2023-05-25T00:00:00', 3168.57],
            ['2023-06-16T00:00:00', 3276.55],
            ['2023-06-26T00:00:00', 3144.25],
            ['2023-07-14T00:00:00', 3248.38],
            ['2023-07-24T00:00:00', 3151.13],
            ['2023-07-31T00:00:00', 3322.13],
          ],
          hoverAnimation: true,
          label: {
            show: true,
            position: 'top',
            margin: 8,
          },
          lineStyle: {
            show: true,
            width: 1,
            opacity: 1,
            curveness: 0,
            type: 'solid',
            color: '#d48265',
          },
          areaStyle: {
            opacity: 0,
          },
          zlevel: 0,
          z: 0,
        },
        {
          type: 'line',
          name: '\u5206\u7b14',
          connectNulls: false,
          xAxisIndex: 0,
          yAxisIndex: 0,
          symbolSize: 4,
          showSymbol: true,
          smooth: false,
          step: false,
          data: [
            ['2023-07-31T00:00:00', 3322.13],
            ['2023-08-25T00:00:00', 3053.04],
          ],
          hoverAnimation: true,
          label: {
            show: true,
            position: 'top',
            margin: 8,
          },
          lineStyle: {
            show: true,
            width: 1,
            opacity: 1,
            curveness: 0,
            type: 'solid',
            color: '#d48265',
          },
          areaStyle: {
            opacity: 0,
          },
          markPoint: {
            symbol: 'pin',
            symbolSize: '30',
            label: {
              show: true,
              position: 'inside',
              color: '#fff',
              margin: 8,
            },
            data: [],
          },
          zlevel: 0,
          z: 0,
          rippleEffect: {
            show: true,
            brushType: 'stroke',
            scale: 2.5,
            period: 4,
          },
        },
        {
          type: 'line',
          name: '\u7ebf\u6bb5',
          connectNulls: false,
          xAxisIndex: 0,
          yAxisIndex: 0,
          symbolSize: 4,
          showSymbol: true,
          smooth: false,
          step: false,
          data: [
            ['2019-09-16T00:00:00', 3042.93],
            ['2019-12-03T00:00:00', 2857.32],
            ['2020-01-14T00:00:00', 3127.17],
            ['2020-02-04T00:00:00', 2685.27],
            ['2020-03-05T00:00:00', 3074.26],
            ['2020-03-19T00:00:00', 2646.8],
            ['2020-07-09T00:00:00', 3456.97],
            ['2020-09-25T00:00:00', 3208.03],
            ['2021-02-18T00:00:00', 3731.69],
            ['2021-03-09T00:00:00', 3328.31],
            ['2021-06-02T00:00:00', 3629.29],
            ['2021-07-28T00:00:00', 3312.72],
            ['2021-09-14T00:00:00', 3723.85],
            ['2021-11-10T00:00:00', 3448.44],
            ['2021-12-13T00:00:00', 3708.94],
            ['2022-04-27T00:00:00', 2863.65],
            ['2022-07-05T00:00:00', 3424.84],
            ['2022-10-31T00:00:00', 2885.09],
            ['2022-12-07T00:00:00', 3226.08],
            ['2022-12-23T00:00:00', 3031.54],
            ['2023-03-07T00:00:00', 3342.86],
            ['2023-03-14T00:00:00', 3216.99],
            ['2023-05-09T00:00:00', 3418.95],
            ['2023-06-26T00:00:00', 3144.25],
          ],
          hoverAnimation: true,
          label: {
            show: true,
            position: 'top',
            margin: 8,
          },
          lineStyle: {
            show: true,
            width: 1,
            opacity: 1,
            curveness: 0,
            type: 'solid',
            color: '#2f4554',
          },
          areaStyle: {
            opacity: 0,
          },
          markPoint: {
            symbol: 'arrow',
            symbolSize: '10',
            label: {
              show: true,
              position: 'inside',
              color: '#fff',
              margin: 8,
            },
            data: [
              {
                coord: ['2020-01-14T00:00:00', 3127.17],
              },
              {
                coord: ['2020-03-19T00:00:00', 2646.8],
              },
              {
                coord: ['2021-02-18T00:00:00', 3731.69],
              },
              {
                coord: ['2022-04-27T00:00:00', 2863.65],
              },
              {
                coord: ['2023-05-09T00:00:00', 3418.95],
              },
              {
                coord: ['2022-10-31T00:00:00', 2885.09],
              },
            ],
          },
          itemStyle: {
            color: '#2f4554',
          },
          zlevel: 0,
          z: 0,
          markArea: {
            silent: false,
            label: {
              show: true,
              position: 'top',
              margin: 8,
            },
            data: [
              [
                {
                  coord: ['2019-09-16T00:00:00', 3042.93],
                  itemStyle: {
                    color: 'rgba(196,204,211,0.5)',
                  },
                },
                {
                  coord: ['2020-03-19T00:00:00', 2857.32],
                },
              ],
              [
                {
                  coord: ['2020-07-09T00:00:00', 3456.97],
                  itemStyle: {
                    color: 'rgba(196,204,211,0.5)',
                  },
                },
                {
                  coord: ['2022-07-05T00:00:00', 3328.31],
                },
              ],
              [
                {
                  coord: ['2022-10-31T00:00:00', 3226.08],
                  itemStyle: {
                    color: 'rgba(196,204,211,0.5)',
                  },
                },
                {
                  coord: ['2023-08-25T00:00:00', 3031.54],
                },
              ],
            ],
          },
          rippleEffect: {
            show: true,
            brushType: 'stroke',
            scale: 2.5,
            period: 4,
          },
        },
        {
          type: 'line',
          name: '\u7ebf\u6bb5',
          connectNulls: false,
          xAxisIndex: 0,
          yAxisIndex: 0,
          symbolSize: 4,
          showSymbol: true,
          smooth: false,
          step: false,
          data: [
            ['2023-06-26T00:00:00', 3144.25],
            ['2023-07-31T00:00:00', 3322.13],
            ['2023-08-25T00:00:00', 3053.04],
          ],
          hoverAnimation: true,
          label: {
            show: true,
            position: 'top',
            margin: 8,
          },
          lineStyle: {
            show: true,
            width: 1,
            opacity: 1,
            curveness: 0,
            type: 'solid',
            color: '#2f4554',
          },
          areaStyle: {
            opacity: 0,
          },
          zlevel: 0,
          z: 0,
        },
        {
          type: 'line',
          name: 'dea',
          connectNulls: false,
          xAxisIndex: 1,
          yAxisIndex: 1,
          symbolSize: 4,
          showSymbol: false,
          smooth: true,
          step: false,
          data: [
            ['2019-09-09T00:00:00', null],
            ['2019-09-10T00:00:00', null],
            ['2019-09-11T00:00:00', null],
            ['2019-09-12T00:00:00', null],
            ['2019-09-16T00:00:00', null],
            ['2019-09-17T00:00:00', null],
            ['2019-09-18T00:00:00', null],
            ['2019-09-19T00:00:00', null],
            ['2019-09-20T00:00:00', null],
            ['2019-09-23T00:00:00', null],
            ['2019-09-24T00:00:00', null],
            ['2019-09-25T00:00:00', null],
            ['2019-09-26T00:00:00', null],
            ['2019-09-27T00:00:00', null],
            ['2019-09-30T00:00:00', null],
            ['2019-10-08T00:00:00', null],
            ['2019-10-09T00:00:00', null],
            ['2019-10-10T00:00:00', null],
            ['2019-10-11T00:00:00', null],
            ['2019-10-14T00:00:00', null],
            ['2019-10-15T00:00:00', null],
            ['2019-10-16T00:00:00', null],
            ['2019-10-17T00:00:00', null],
            ['2019-10-18T00:00:00', null],
            ['2019-10-21T00:00:00', null],
            ['2019-10-22T00:00:00', null],
            ['2019-10-23T00:00:00', null],
            ['2019-10-24T00:00:00', null],
            ['2019-10-25T00:00:00', null],
            ['2019-10-28T00:00:00', null],
            ['2019-10-29T00:00:00', null],
            ['2019-10-30T00:00:00', null],
            ['2019-10-31T00:00:00', null],
            ['2019-11-01T00:00:00', -15.31],
            ['2019-11-04T00:00:00', -10.75],
            ['2019-11-05T00:00:00', -6.35],
            ['2019-11-06T00:00:00', -2.9],
            ['2019-11-07T00:00:00', -0.1],
            ['2019-11-08T00:00:00', 1.75],
            ['2019-11-11T00:00:00', 0.15],
            ['2019-11-12T00:00:00', -0.69],
            ['2019-11-13T00:00:00', -1.32],
            ['2019-11-14T00:00:00', -2.16],
            ['2019-11-15T00:00:00', -2.55],
            ['2019-11-18T00:00:00', -2.7],
            ['2019-11-19T00:00:00', -4.21],
            ['2019-11-20T00:00:00', -5.75],
            ['2019-11-21T00:00:00', -6.53],
            ['2019-11-22T00:00:00', -9.04],
            ['2019-11-25T00:00:00', -12.35],
            ['2019-11-26T00:00:00', -14.15],
            ['2019-11-27T00:00:00', -14.38],
            ['2019-11-28T00:00:00', -13.75],
            ['2019-11-29T00:00:00', -14.08],
            ['2019-12-02T00:00:00', -15.56],
            ['2019-12-03T00:00:00', -18.08],
            ['2019-12-04T00:00:00', -19.89],
            ['2019-12-05T00:00:00', -22.96],
            ['2019-12-06T00:00:00', -25.11],
            ['2019-12-09T00:00:00', -24.05],
            ['2019-12-10T00:00:00', -23.23],
            ['2019-12-11T00:00:00', -21.54],
            ['2019-12-12T00:00:00', -19.66],
            ['2019-12-13T00:00:00', -15.8],
            ['2019-12-16T00:00:00', -12.92],
            ['2019-12-17T00:00:00', -11.17],
            ['2019-12-18T00:00:00', -8.32],
            ['2019-12-19T00:00:00', -3.96],
            ['2019-12-20T00:00:00', 1.87],
            ['2019-12-23T00:00:00', 6.8],
            ['2019-12-24T00:00:00', 11.36],
            ['2019-12-25T00:00:00', 15.33],
            ['2019-12-26T00:00:00', 19.44],
            ['2019-12-27T00:00:00', 22.42],
            ['2019-12-30T00:00:00', 25.82],
            ['2019-12-31T00:00:00', 28.91],
            ['2020-01-02T00:00:00', 33.23],
            ['2020-01-03T00:00:00', 37.02],
            ['2020-01-06T00:00:00', 40.8],
            ['2020-01-07T00:00:00', 45.61],
            ['2020-01-08T00:00:00', 49.26],
            ['2020-01-09T00:00:00', 52.57],
            ['2020-01-10T00:00:00', 55.4],
            ['2020-01-13T00:00:00', 56.54],
            ['2020-01-14T00:00:00', 56.58],
            ['2020-01-15T00:00:00', 54.67],
            ['2020-01-16T00:00:00', 52.44],
            ['2020-01-17T00:00:00', 49.27],
            ['2020-01-20T00:00:00', 47.34],
            ['2020-01-21T00:00:00', 46.94],
            ['2020-01-22T00:00:00', 45.27],
            ['2020-01-23T00:00:00', 43.07],
            ['2020-02-03T00:00:00', 37.44],
            ['2020-02-04T00:00:00', 30.48],
            ['2020-02-05T00:00:00', 21.17],
            ['2020-02-06T00:00:00', 11.3],
            ['2020-02-07T00:00:00', -0.77],
            ['2020-02-10T00:00:00', -10.54],
            ['2020-02-11T00:00:00', -19.62],
            ['2020-02-12T00:00:00', -27.0],
            ['2020-02-13T00:00:00', -29.53],
            ['2020-02-14T00:00:00', -33.58],
            ['2020-02-17T00:00:00', -37.56],
            ['2020-02-18T00:00:00', -43.92],
            ['2020-02-19T00:00:00', -49.1],
            ['2020-02-20T00:00:00', -52.99],
            ['2020-02-21T00:00:00', -54.94],
            ['2020-02-24T00:00:00', -56.99],
            ['2020-02-25T00:00:00', -58.62],
            ['2020-02-26T00:00:00', -58.23],
            ['2020-02-27T00:00:00', -58.26],
            ['2020-02-28T00:00:00', -55.5],
            ['2020-03-02T00:00:00', -41.94],
            ['2020-03-03T00:00:00', -30.03],
            ['2020-03-04T00:00:00', -20.67],
            ['2020-03-05T00:00:00', -11.87],
            ['2020-03-06T00:00:00', -3.05],
            ['2020-03-09T00:00:00', 5.5],
            ['2020-03-10T00:00:00', 13.26],
            ['2020-03-11T00:00:00', 20.55],
            ['2020-03-12T00:00:00', 26.56],
            ['2020-03-13T00:00:00', 31.0],
            ['2020-03-16T00:00:00', 31.19],
            ['2020-03-17T00:00:00', 28.07],
            ['2020-03-18T00:00:00', 24.8],
            ['2020-03-19T00:00:00', 16.51],
            ['2020-03-20T00:00:00', -0.02],
            ['2020-03-23T00:00:00', -15.8],
            ['2020-03-24T00:00:00', -29.34],
            ['2020-03-25T00:00:00', -39.39],
            ['2020-03-26T00:00:00', -47.8],
            ['2020-03-27T00:00:00', -50.19],
            ['2020-03-30T00:00:00', -55.83],
            ['2020-03-31T00:00:00', -61.39],
            ['2020-04-01T00:00:00', -69.12],
            ['2020-04-02T00:00:00', -77.94],
            ['2020-04-03T00:00:00', -83.85],
            ['2020-04-07T00:00:00', -85.67],
            ['2020-04-08T00:00:00', -89.7],
            ['2020-04-09T00:00:00', -90.54],
            ['2020-04-10T00:00:00', -88.65],
            ['2020-04-13T00:00:00', -85.48],
            ['2020-04-14T00:00:00', -77.75],
            ['2020-04-15T00:00:00', -70.85],
            ['2020-04-16T00:00:00', -61.14],
            ['2020-04-17T00:00:00', -53.89],
            ['2020-04-20T00:00:00', -45.68],
            ['2020-04-21T00:00:00', -32.97],
            ['2020-04-22T00:00:00', -22.11],
            ['2020-04-23T00:00:00', -12.05],
            ['2020-04-24T00:00:00', -3.08],
            ['2020-04-27T00:00:00', 2.99],
            ['2020-04-28T00:00:00', 11.51],
            ['2020-04-29T00:00:00', 18.82],
            ['2020-04-30T00:00:00', 25.72],
            ['2020-05-06T00:00:00', 30.18],
            ['2020-05-07T00:00:00', 32.64],
            ['2020-05-08T00:00:00', 32.65],
            ['2020-05-11T00:00:00', 32.15],
            ['2020-05-12T00:00:00', 30.5],
            ['2020-05-13T00:00:00', 31.72],
            ['2020-05-14T00:00:00', 30.83],
            ['2020-05-15T00:00:00', 29.04],
            ['2020-05-18T00:00:00', 29.42],
            ['2020-05-19T00:00:00', 29.15],
            ['2020-05-20T00:00:00', 27.91],
            ['2020-05-21T00:00:00', 25.15],
            ['2020-05-22T00:00:00', 23.12],
            ['2020-05-25T00:00:00', 19.36],
            ['2020-05-26T00:00:00', 17.48],
            ['2020-05-27T00:00:00', 16.43],
            ['2020-05-28T00:00:00', 16.61],
            ['2020-05-29T00:00:00', 16.66],
            ['2020-06-01T00:00:00', 17.25],
            ['2020-06-02T00:00:00', 15.99],
            ['2020-06-03T00:00:00', 14.64],
            ['2020-06-04T00:00:00', 15.11],
            ['2020-06-05T00:00:00', 13.93],
            ['2020-06-08T00:00:00', 13.41],
            ['2020-06-09T00:00:00', 13.81],
            ['2020-06-10T00:00:00', 14.36],
            ['2020-06-11T00:00:00', 14.52],
            ['2020-06-12T00:00:00', 15.06],
            ['2020-06-15T00:00:00', 14.72],
            ['2020-06-16T00:00:00', 12.57],
            ['2020-06-17T00:00:00', 11.28],
            ['2020-06-18T00:00:00', 10.59],
            ['2020-06-19T00:00:00', 12.89],
            ['2020-06-22T00:00:00', 16.5],
            ['2020-06-23T00:00:00', 19.83],
            ['2020-06-24T00:00:00', 23.7],
            ['2020-06-29T00:00:00', 28.23],
            ['2020-06-30T00:00:00', 32.11],
            ['2020-07-01T00:00:00', 33.41],
            ['2020-07-02T00:00:00', 35.52],
            ['2020-07-03T00:00:00', 37.64],
            ['2020-07-06T00:00:00', 42.49],
            ['2020-07-07T00:00:00', 48.98],
            ['2020-07-08T00:00:00', 57.74],
            ['2020-07-09T00:00:00', 67.35],
            ['2020-07-10T00:00:00', 77.56],
            ['2020-07-13T00:00:00', 87.74],
            ['2020-07-14T00:00:00', 97.66],
            ['2020-07-15T00:00:00', 108.72],
            ['2020-07-16T00:00:00', 113.58],
            ['2020-07-17T00:00:00', 117.09],
            ['2020-07-20T00:00:00', 119.78],
            ['2020-07-21T00:00:00', 122.37],
            ['2020-07-22T00:00:00', 126.3],
            ['2020-07-23T00:00:00', 129.35],
            ['2020-07-24T00:00:00', 131.22],
            ['2020-07-27T00:00:00', 134.29],
            ['2020-07-28T00:00:00', 138.13],
            ['2020-07-29T00:00:00', 140.88],
            ['2020-07-30T00:00:00', 139.15],
            ['2020-07-31T00:00:00', 134.13],
            ['2020-08-03T00:00:00', 121.32],
            ['2020-08-04T00:00:00', 107.85],
            ['2020-08-05T00:00:00', 95.12],
            ['2020-08-06T00:00:00', 80.68],
            ['2020-08-07T00:00:00', 69.24],
            ['2020-08-10T00:00:00', 55.16],
            ['2020-08-11T00:00:00', 42.24],
            ['2020-08-12T00:00:00', 31.3],
            ['2020-08-13T00:00:00', 26.65],
            ['2020-08-14T00:00:00', 21.36],
            ['2020-08-17T00:00:00', 12.79],
            ['2020-08-18T00:00:00', 5.71],
            ['2020-08-19T00:00:00', 0.55],
            ['2020-08-20T00:00:00', -3.03],
            ['2020-08-21T00:00:00', 5.04],
            ['2020-08-24T00:00:00', 13.33],
            ['2020-08-25T00:00:00', 22.06],
            ['2020-08-26T00:00:00', 28.01],
            ['2020-08-27T00:00:00', 31.13],
            ['2020-08-28T00:00:00', 35.05],
            ['2020-08-31T00:00:00', 36.19],
            ['2020-09-01T00:00:00', 36.49],
            ['2020-09-02T00:00:00', 32.13],
            ['2020-09-03T00:00:00', 27.35],
            ['2020-09-04T00:00:00', 26.84],
            ['2020-09-07T00:00:00', 25.12],
            ['2020-09-08T00:00:00', 24.89],
            ['2020-09-09T00:00:00', 23.85],
            ['2020-09-10T00:00:00', 18.32],
            ['2020-09-11T00:00:00', 10.61],
            ['2020-09-14T00:00:00', -0.15],
            ['2020-09-15T00:00:00', -9.18],
            ['2020-09-16T00:00:00', -16.01],
            ['2020-09-17T00:00:00', -19.36],
            ['2020-09-18T00:00:00', -21.58],
            ['2020-09-21T00:00:00', -22.87],
            ['2020-09-22T00:00:00', -23.36],
            ['2020-09-23T00:00:00', -21.99],
            ['2020-09-24T00:00:00', -23.37],
            ['2020-09-25T00:00:00', -27.25],
            ['2020-09-28T00:00:00', -32.13],
            ['2020-09-29T00:00:00', -38.39],
            ['2020-09-30T00:00:00', -45.43],
            ['2020-10-09T00:00:00', -49.8],
            ['2020-10-12T00:00:00', -48.91],
            ['2020-10-13T00:00:00', -43.95],
            ['2020-10-14T00:00:00', -40.14],
            ['2020-10-15T00:00:00', -34.39],
            ['2020-10-16T00:00:00', -26.99],
            ['2020-10-19T00:00:00', -20.81],
            ['2020-10-20T00:00:00', -15.73],
            ['2020-10-21T00:00:00', -13.43],
            ['2020-10-22T00:00:00', -11.28],
            ['2020-10-23T00:00:00', -7.39],
            ['2020-10-26T00:00:00', -7.21],
            ['2020-10-27T00:00:00', -5.88],
            ['2020-10-28T00:00:00', -2.93],
            ['2020-10-29T00:00:00', -0.56],
            ['2020-10-30T00:00:00', 2.93],
            ['2020-11-02T00:00:00', 4.5],
            ['2020-11-03T00:00:00', 7.18],
            ['2020-11-04T00:00:00', 8.41],
            ['2020-11-05T00:00:00', 10.27],
            ['2020-11-06T00:00:00', 10.66],
            ['2020-11-09T00:00:00', 8.15],
            ['2020-11-10T00:00:00', 6.53],
            ['2020-11-11T00:00:00', 6.28],
            ['2020-11-12T00:00:00', 6.14],
            ['2020-11-13T00:00:00', 6.95],
            ['2020-11-16T00:00:00', 8.3],
            ['2020-11-17T00:00:00', 7.17],
            ['2020-11-18T00:00:00', 6.52],
            ['2020-11-19T00:00:00', 4.21],
            ['2020-11-20T00:00:00', 3.87],
            ['2020-11-23T00:00:00', 5.52],
            ['2020-11-24T00:00:00', 7.57],
            ['2020-11-25T00:00:00', 8.99],
            ['2020-11-26T00:00:00', 11.27],
            ['2020-11-27T00:00:00', 18.67],
            ['2020-11-30T00:00:00', 26.05],
            ['2020-12-01T00:00:00', 31.09],
            ['2020-12-02T00:00:00', 35.8],
            ['2020-12-03T00:00:00', 38.39],
            ['2020-12-04T00:00:00', 41.03],
            ['2020-12-07T00:00:00', 41.65],
            ['2020-12-08T00:00:00', 42.56],
            ['2020-12-09T00:00:00', 42.97],
            ['2020-12-10T00:00:00', 42.39],
            ['2020-12-11T00:00:00', 41.08],
            ['2020-12-14T00:00:00', 37.97],
            ['2020-12-15T00:00:00', 35.58],
            ['2020-12-16T00:00:00', 32.82],
            ['2020-12-17T00:00:00', 28.59],
            ['2020-12-18T00:00:00', 23.92],
            ['2020-12-21T00:00:00', 19.72],
            ['2020-12-22T00:00:00', 15.94],
            ['2020-12-23T00:00:00', 15.61],
            ['2020-12-24T00:00:00', 14.25],
            ['2020-12-25T00:00:00', 13.02],
            ['2020-12-28T00:00:00', 12.29],
            ['2020-12-29T00:00:00', 7.66],
            ['2020-12-30T00:00:00', 3.43],
            ['2020-12-31T00:00:00', -0.88],
            ['2021-01-04T00:00:00', -2.6],
            ['2021-01-05T00:00:00', -2.69],
            ['2021-01-06T00:00:00', -1.18],
            ['2021-01-07T00:00:00', 2.97],
            ['2021-01-08T00:00:00', 8.01],
            ['2021-01-11T00:00:00', 15.61],
            ['2021-01-12T00:00:00', 22.05],
            ['2021-01-13T00:00:00', 27.02],
            ['2021-01-14T00:00:00', 31.79],
            ['2021-01-15T00:00:00', 35.52],
            ['2021-01-18T00:00:00', 39.15],
            ['2021-01-19T00:00:00', 42.88],
            ['2021-01-20T00:00:00', 49.66],
            ['2021-01-21T00:00:00', 55.91],
            ['2021-01-22T00:00:00', 62.29],
            ['2021-01-25T00:00:00', 66.69],
            ['2021-01-26T00:00:00', 71.03],
            ['2021-01-27T00:00:00', 74.92],
            ['2021-01-28T00:00:00', 76.05],
            ['2021-01-29T00:00:00', 73.29],
            ['2021-02-01T00:00:00', 69.14],
            ['2021-02-02T00:00:00', 63.19],
            ['2021-02-03T00:00:00', 56.23],
            ['2021-02-04T00:00:00', 48.73],
            ['2021-02-05T00:00:00', 41.75],
            ['2021-02-08T00:00:00', 37.47],
            ['2021-02-09T00:00:00', 29.43],
            ['2021-02-10T00:00:00', 23.95],
            ['2021-02-18T00:00:00', 20.16],
            ['2021-02-19T00:00:00', 17.87],
            ['2021-02-22T00:00:00', 14.62],
            ['2021-02-23T00:00:00', 12.52],
            ['2021-02-24T00:00:00', 10.08],
            ['2021-02-25T00:00:00', 6.73],
            ['2021-02-26T00:00:00', 3.02],
            ['2021-03-01T00:00:00', -1.83],
            ['2021-03-02T00:00:00', -4.4],
            ['2021-03-03T00:00:00', -6.55],
            ['2021-03-04T00:00:00', -5.48],
            ['2021-03-05T00:00:00', -4.78],
            ['2021-03-08T00:00:00', -2.93],
            ['2021-03-09T00:00:00', -4.23],
            ['2021-03-10T00:00:00', -5.7],
            ['2021-03-11T00:00:00', -7.0],
            ['2021-03-12T00:00:00', -6.45],
            ['2021-03-15T00:00:00', -9.47],
            ['2021-03-16T00:00:00', -14.19],
            ['2021-03-17T00:00:00', -20.04],
            ['2021-03-18T00:00:00', -25.91],
            ['2021-03-19T00:00:00', -31.73],
            ['2021-03-22T00:00:00', -36.49],
            ['2021-03-23T00:00:00', -42.11],
            ['2021-03-24T00:00:00', -47.09],
            ['2021-03-25T00:00:00', -53.74],
            ['2021-03-26T00:00:00', -56.4],
            ['2021-03-29T00:00:00', -59.95],
            ['2021-03-30T00:00:00', -61.78],
            ['2021-03-31T00:00:00', -66.29],
            ['2021-04-01T00:00:00', -67.66],
            ['2021-04-02T00:00:00', -67.05],
            ['2021-04-06T00:00:00', -59.73],
            ['2021-04-07T00:00:00', -47.77],
            ['2021-04-08T00:00:00', -35.73],
            ['2021-04-09T00:00:00', -27.2],
            ['2021-04-12T00:00:00', -21.76],
            ['2021-04-13T00:00:00', -15.29],
            ['2021-04-14T00:00:00', -12.71],
            ['2021-04-15T00:00:00', -10.04],
            ['2021-04-16T00:00:00', -10.35],
            ['2021-04-19T00:00:00', -6.19],
            ['2021-04-20T00:00:00', -4.77],
            ['2021-04-21T00:00:00', 0.82],
            ['2021-04-22T00:00:00', 7.02],
            ['2021-04-23T00:00:00', 13.73],
            ['2021-04-26T00:00:00', 15.13],
            ['2021-04-27T00:00:00', 14.04],
            ['2021-04-28T00:00:00', 11.55],
            ['2021-04-29T00:00:00', 11.52],
            ['2021-04-30T00:00:00', 10.75],
            ['2021-05-06T00:00:00', 8.03],
            ['2021-05-07T00:00:00', 5.95],
            ['2021-05-10T00:00:00', 3.73],
            ['2021-05-11T00:00:00', 2.32],
            ['2021-05-12T00:00:00', 0.9],
            ['2021-05-13T00:00:00', 2.05],
            ['2021-05-14T00:00:00', 3.25],
            ['2021-05-17T00:00:00', 3.17],
            ['2021-05-18T00:00:00', 4.45],
            ['2021-05-19T00:00:00', 6.57],
            ['2021-05-20T00:00:00', 6.81],
            ['2021-05-21T00:00:00', 7.61],
            ['2021-05-24T00:00:00', 7.7],
            ['2021-05-25T00:00:00', 10.03],
            ['2021-05-26T00:00:00', 12.31],
            ['2021-05-27T00:00:00', 16.36],
            ['2021-05-28T00:00:00', 20.5],
            ['2021-05-31T00:00:00', 24.71],
            ['2021-06-01T00:00:00', 27.59],
            ['2021-06-02T00:00:00', 31.0],
            ['2021-06-03T00:00:00', 33.91],
            ['2021-06-04T00:00:00', 37.29],
            ['2021-06-07T00:00:00', 39.58],
            ['2021-06-08T00:00:00', 41.64],
            ['2021-06-09T00:00:00', 44.26],
            ['2021-06-10T00:00:00', 48.27],
            ['2021-06-11T00:00:00', 49.25],
            ['2021-06-15T00:00:00', 48.75],
            ['2021-06-16T00:00:00', 47.55],
            ['2021-06-17T00:00:00', 45.75],
            ['2021-06-18T00:00:00', 43.78],
            ['2021-06-21T00:00:00', 43.26],
            ['2021-06-22T00:00:00', 42.73],
            ['2021-06-23T00:00:00', 37.4],
            ['2021-06-24T00:00:00', 31.8],
            ['2021-06-25T00:00:00', 25.77],
            ['2021-06-28T00:00:00', 21.27],
            ['2021-06-29T00:00:00', 16.38],
            ['2021-06-30T00:00:00', 11.79],
            ['2021-07-01T00:00:00', 7.55],
            ['2021-07-02T00:00:00', 4.71],
            ['2021-07-05T00:00:00', 1.85],
            ['2021-07-06T00:00:00', -1.34],
            ['2021-07-07T00:00:00', -4.68],
            ['2021-07-08T00:00:00', -8.9],
            ['2021-07-09T00:00:00', -14.36],
            ['2021-07-12T00:00:00', -18.68],
            ['2021-07-13T00:00:00', -18.74],
            ['2021-07-14T00:00:00', -15.88],
            ['2021-07-15T00:00:00', -12.9],
            ['2021-07-16T00:00:00', -10.14],
            ['2021-07-19T00:00:00', -7.5],
            ['2021-07-20T00:00:00', -5.61],
            ['2021-07-21T00:00:00', -4.74],
            ['2021-07-22T00:00:00', -4.32],
            ['2021-07-23T00:00:00', -5.88],
            ['2021-07-26T00:00:00', -7.43],
            ['2021-07-27T00:00:00', -9.8],
            ['2021-07-28T00:00:00', -13.58],
            ['2021-07-29T00:00:00', -17.15],
            ['2021-07-30T00:00:00', -18.49],
            ['2021-08-02T00:00:00', -21.56],
            ['2021-08-03T00:00:00', -25.59],
            ['2021-08-04T00:00:00', -29.78],
            ['2021-08-05T00:00:00', -31.34],
            ['2021-08-06T00:00:00', -31.46],
            ['2021-08-09T00:00:00', -31.22],
            ['2021-08-10T00:00:00', -31.65],
            ['2021-08-11T00:00:00', -29.65],
            ['2021-08-12T00:00:00', -28.76],
            ['2021-08-13T00:00:00', -26.4],
            ['2021-08-16T00:00:00', -25.3],
            ['2021-08-17T00:00:00', -24.02],
            ['2021-08-18T00:00:00', -24.04],
            ['2021-08-19T00:00:00', -27.41],
            ['2021-08-20T00:00:00', -30.2],
            ['2021-08-23T00:00:00', -29.24],
            ['2021-08-24T00:00:00', -23.09],
            ['2021-08-25T00:00:00', -16.2],
            ['2021-08-26T00:00:00', -11.62],
            ['2021-08-27T00:00:00', -4.52],
            ['2021-08-30T00:00:00', -0.27],
            ['2021-08-31T00:00:00', 4.2],
            ['2021-09-01T00:00:00', 9.27],
            ['2021-09-02T00:00:00', 14.03],
            ['2021-09-03T00:00:00', 18.97],
            ['2021-09-06T00:00:00', 22.36],
            ['2021-09-07T00:00:00', 26.24],
            ['2021-09-08T00:00:00', 30.74],
            ['2021-09-09T00:00:00', 35.46],
            ['2021-09-10T00:00:00', 38.51],
            ['2021-09-13T00:00:00', 39.22],
            ['2021-09-14T00:00:00', 42.14],
            ['2021-09-15T00:00:00', 44.72],
            ['2021-09-16T00:00:00', 46.67],
            ['2021-09-17T00:00:00', 51.19],
            ['2021-09-22T00:00:00', 52.73],
            ['2021-09-23T00:00:00', 53.11],
            ['2021-09-24T00:00:00', 51.58],
            ['2021-09-27T00:00:00', 51.02],
            ['2021-09-28T00:00:00', 51.09],
            ['2021-09-29T00:00:00', 51.3],
            ['2021-09-30T00:00:00', 51.27],
            ['2021-10-08T00:00:00', 49.89],
            ['2021-10-11T00:00:00', 46.72],
            ['2021-10-12T00:00:00', 43.78],
            ['2021-10-13T00:00:00', 37.2],
            ['2021-10-14T00:00:00', 29.44],
            ['2021-10-15T00:00:00', 20.98],
            ['2021-10-18T00:00:00', 11.45],
            ['2021-10-19T00:00:00', 2.69],
            ['2021-10-20T00:00:00', -5.75],
            ['2021-10-21T00:00:00', -10.8],
            ['2021-10-22T00:00:00', -16.07],
            ['2021-10-25T00:00:00', -18.39],
            ['2021-10-26T00:00:00', -20.74],
            ['2021-10-27T00:00:00', -23.76],
            ['2021-10-28T00:00:00', -27.31],
            ['2021-10-29T00:00:00', -29.16],
            ['2021-11-01T00:00:00', -30.15],
            ['2021-11-02T00:00:00', -31.46],
            ['2021-11-03T00:00:00', -28.37],
            ['2021-11-04T00:00:00', -27.21],
            ['2021-11-05T00:00:00', -26.83],
            ['2021-11-08T00:00:00', -25.85],
            ['2021-11-09T00:00:00', -21.88],
            ['2021-11-10T00:00:00', -20.65],
            ['2021-11-11T00:00:00', -19.15],
            ['2021-11-12T00:00:00', -18.96],
            ['2021-11-15T00:00:00', -18.12],
            ['2021-11-16T00:00:00', -18.38],
            ['2021-11-17T00:00:00', -17.36],
            ['2021-11-18T00:00:00', -17.68],
            ['2021-11-19T00:00:00', -18.1],
            ['2021-11-22T00:00:00', -18.63],
            ['2021-11-23T00:00:00', -20.63],
            ['2021-11-24T00:00:00', -19.83],
            ['2021-11-25T00:00:00', -16.06],
            ['2021-11-26T00:00:00', -13.57],
            ['2021-11-29T00:00:00', -12.53],
            ['2021-11-30T00:00:00', -9.1],
            ['2021-12-01T00:00:00', -5.9],
            ['2021-12-02T00:00:00', -3.9],
            ['2021-12-03T00:00:00', -0.16],
            ['2021-12-06T00:00:00', 4.0],
            ['2021-12-07T00:00:00', 7.81],
            ['2021-12-08T00:00:00', 13.26],
            ['2021-12-09T00:00:00', 17.15],
            ['2021-12-10T00:00:00', 21.96],
            ['2021-12-13T00:00:00', 26.96],
            ['2021-12-14T00:00:00', 31.68],
            ['2021-12-15T00:00:00', 33.94],
            ['2021-12-16T00:00:00', 38.24],
            ['2021-12-17T00:00:00', 40.28],
            ['2021-12-20T00:00:00', 39.07],
            ['2021-12-21T00:00:00', 36.71],
            ['2021-12-22T00:00:00', 34.95],
            ['2021-12-23T00:00:00', 32.48],
            ['2021-12-24T00:00:00', 31.26],
            ['2021-12-27T00:00:00', 30.42],
            ['2021-12-28T00:00:00', 28.8],
            ['2021-12-29T00:00:00', 28.04],
            ['2021-12-30T00:00:00', 28.12],
            ['2021-12-31T00:00:00', 26.24],
            ['2022-01-04T00:00:00', 25.0],
            ['2022-01-05T00:00:00', 23.39],
            ['2022-01-06T00:00:00', 19.37],
            ['2022-01-07T00:00:00', 14.58],
            ['2022-01-10T00:00:00', 10.46],
            ['2022-01-11T00:00:00', 6.0],
            ['2022-01-12T00:00:00', 2.31],
            ['2022-01-13T00:00:00', -1.56],
            ['2022-01-14T00:00:00', -7.74],
            ['2022-01-17T00:00:00', -11.69],
            ['2022-01-18T00:00:00', -13.59],
            ['2022-01-19T00:00:00', -16.51],
            ['2022-01-20T00:00:00', -19.62],
            ['2022-01-21T00:00:00', -22.69],
            ['2022-01-24T00:00:00', -25.7],
            ['2022-01-25T00:00:00', -28.86],
            ['2022-01-26T00:00:00', -31.54],
            ['2022-01-27T00:00:00', -32.86],
            ['2022-01-28T00:00:00', -36.79],
            ['2022-02-07T00:00:00', -41.23],
            ['2022-02-08T00:00:00', -45.7],
            ['2022-02-09T00:00:00', -48.02],
            ['2022-02-10T00:00:00', -48.74],
            ['2022-02-11T00:00:00', -49.07],
            ['2022-02-14T00:00:00', -51.67],
            ['2022-02-15T00:00:00', -51.36],
            ['2022-02-16T00:00:00', -51.95],
            ['2022-02-17T00:00:00', -50.73],
            ['2022-02-18T00:00:00', -48.69],
            ['2022-02-21T00:00:00', -47.8],
            ['2022-02-22T00:00:00', -48.04],
            ['2022-02-23T00:00:00', -48.21],
            ['2022-02-24T00:00:00', -47.53],
            ['2022-02-25T00:00:00', -45.09],
            ['2022-02-28T00:00:00', -43.19],
            ['2022-03-01T00:00:00', -37.91],
            ['2022-03-02T00:00:00', -34.09],
            ['2022-03-03T00:00:00', -27.49],
            ['2022-03-04T00:00:00', -18.75],
            ['2022-03-07T00:00:00', -15.2],
            ['2022-03-08T00:00:00', -12.45],
            ['2022-03-09T00:00:00', -13.83],
            ['2022-03-10T00:00:00', -17.31],
            ['2022-03-11T00:00:00', -19.83],
            ['2022-03-14T00:00:00', -20.88],
            ['2022-03-15T00:00:00', -25.16],
            ['2022-03-16T00:00:00', -30.64],
            ['2022-03-17T00:00:00', -36.21],
            ['2022-03-18T00:00:00', -41.4],
            ['2022-03-21T00:00:00', -48.37],
            ['2022-03-22T00:00:00', -53.3],
            ['2022-03-23T00:00:00', -61.28],
            ['2022-03-24T00:00:00', -66.18],
            ['2022-03-25T00:00:00', -68.29],
            ['2022-03-28T00:00:00', -71.83],
            ['2022-03-29T00:00:00', -76.7],
            ['2022-03-30T00:00:00', -81.32],
            ['2022-03-31T00:00:00', -86.36],
            ['2022-04-01T00:00:00', -90.52],
            ['2022-04-06T00:00:00', -90.52],
            ['2022-04-07T00:00:00', -86.14],
            ['2022-04-08T00:00:00', -79.48],
            ['2022-04-11T00:00:00', -74.81],
            ['2022-04-12T00:00:00', -70.85],
            ['2022-04-13T00:00:00', -65.03],
            ['2022-04-14T00:00:00', -50.57],
            ['2022-04-15T00:00:00', -43.29],
            ['2022-04-18T00:00:00', -37.65],
            ['2022-04-19T00:00:00', -32.9],
            ['2022-04-20T00:00:00', -28.16],
            ['2022-04-21T00:00:00', -23.73],
            ['2022-04-22T00:00:00', -21.19],
            ['2022-04-25T00:00:00', -20.22],
            ['2022-04-26T00:00:00', -21.97],
            ['2022-04-27T00:00:00', -26.64],
            ['2022-04-28T00:00:00', -32.03],
            ['2022-04-29T00:00:00', -38.74],
            ['2022-05-05T00:00:00', -43.22],
            ['2022-05-06T00:00:00', -50.76],
            ['2022-05-09T00:00:00', -63.03],
            ['2022-05-10T00:00:00', -67.67],
            ['2022-05-11T00:00:00', -70.78],
            ['2022-05-12T00:00:00', -69.57],
            ['2022-05-13T00:00:00', -70.25],
            ['2022-05-16T00:00:00', -70.23],
            ['2022-05-17T00:00:00', -71.63],
            ['2022-05-18T00:00:00', -71.92],
            ['2022-05-19T00:00:00', -72.38],
            ['2022-05-20T00:00:00', -72.53],
            ['2022-05-23T00:00:00', -70.83],
            ['2022-05-24T00:00:00', -64.29],
            ['2022-05-25T00:00:00', -58.86],
            ['2022-05-26T00:00:00', -44.84],
            ['2022-05-27T00:00:00', -28.92],
            ['2022-05-30T00:00:00', -17.32],
            ['2022-05-31T00:00:00', -5.69],
            ['2022-06-01T00:00:00', -0.11],
            ['2022-06-02T00:00:00', 6.22],
            ['2022-06-06T00:00:00', 16.54],
            ['2022-06-07T00:00:00', 27.92],
            ['2022-06-08T00:00:00', 37.78],
            ['2022-06-09T00:00:00', 46.21],
            ['2022-06-10T00:00:00', 55.05],
            ['2022-06-13T00:00:00', 60.91],
            ['2022-06-14T00:00:00', 65.63],
            ['2022-06-15T00:00:00', 69.84],
            ['2022-06-16T00:00:00', 69.43],
            ['2022-06-17T00:00:00', 67.56],
            ['2022-06-20T00:00:00', 65.39],
            ['2022-06-21T00:00:00', 64.12],
            ['2022-06-22T00:00:00', 67.58],
            ['2022-06-23T00:00:00', 70.53],
            ['2022-06-24T00:00:00', 70.84],
            ['2022-06-27T00:00:00', 71.27],
            ['2022-06-28T00:00:00', 72.7],
            ['2022-06-29T00:00:00', 72.74],
            ['2022-06-30T00:00:00', 73.3],
            ['2022-07-01T00:00:00', 74.38],
            ['2022-07-04T00:00:00', 74.05],
            ['2022-07-05T00:00:00', 73.52],
            ['2022-07-06T00:00:00', 71.02],
            ['2022-07-07T00:00:00', 69.44],
            ['2022-07-08T00:00:00', 66.52],
            ['2022-07-11T00:00:00', 65.07],
            ['2022-07-12T00:00:00', 58.67],
            ['2022-07-13T00:00:00', 52.2],
            ['2022-07-14T00:00:00', 46.5],
            ['2022-07-15T00:00:00', 38.8],
            ['2022-07-18T00:00:00', 32.25],
            ['2022-07-19T00:00:00', 27.02],
            ['2022-07-20T00:00:00', 23.86],
            ['2022-07-21T00:00:00', 19.06],
            ['2022-07-22T00:00:00', 14.41],
            ['2022-07-25T00:00:00', 8.27],
            ['2022-07-26T00:00:00', 1.47],
            ['2022-07-27T00:00:00', -3.09],
            ['2022-07-28T00:00:00', -8.63],
            ['2022-07-29T00:00:00', -14.71],
            ['2022-08-01T00:00:00', -21.08],
            ['2022-08-02T00:00:00', -27.2],
            ['2022-08-03T00:00:00', -32.34],
            ['2022-08-04T00:00:00', -37.08],
            ['2022-08-05T00:00:00', -41.75],
            ['2022-08-08T00:00:00', -44.47],
            ['2022-08-09T00:00:00', -46.67],
            ['2022-08-10T00:00:00', -47.03],
            ['2022-08-11T00:00:00', -45.99],
            ['2022-08-12T00:00:00', -40.26],
            ['2022-08-15T00:00:00', -35.46],
            ['2022-08-16T00:00:00', -32.24],
            ['2022-08-17T00:00:00', -29.37],
            ['2022-08-18T00:00:00', -25.38],
            ['2022-08-19T00:00:00', -21.07],
            ['2022-08-22T00:00:00', -15.61],
            ['2022-08-23T00:00:00', -13.49],
            ['2022-08-24T00:00:00', -11.5],
            ['2022-08-25T00:00:00', -10.2],
            ['2022-08-26T00:00:00', -8.96],
            ['2022-08-29T00:00:00', -9.25],
            ['2022-08-30T00:00:00', -6.0],
            ['2022-08-31T00:00:00', -1.86],
            ['2022-09-01T00:00:00', -1.12],
            ['2022-09-02T00:00:00', -0.99],
            ['2022-09-05T00:00:00', -0.57],
            ['2022-09-06T00:00:00', 0.0],
            ['2022-09-07T00:00:00', 0.88],
            ['2022-09-08T00:00:00', -0.64],
            ['2022-09-09T00:00:00', -1.98],
            ['2022-09-13T00:00:00', -1.89],
            ['2022-09-14T00:00:00', -1.73],
            ['2022-09-15T00:00:00', -2.45],
            ['2022-09-16T00:00:00', -4.46],
            ['2022-09-19T00:00:00', -6.77],
            ['2022-09-20T00:00:00', -12.74],
            ['2022-09-21T00:00:00', -19.63],
            ['2022-09-22T00:00:00', -23.68],
            ['2022-09-23T00:00:00', -28.57],
            ['2022-09-26T00:00:00', -33.0],
            ['2022-09-27T00:00:00', -36.91],
            ['2022-09-28T00:00:00', -40.2],
            ['2022-09-29T00:00:00', -41.04],
            ['2022-09-30T00:00:00', -41.72],
            ['2022-10-10T00:00:00', -43.18],
            ['2022-10-11T00:00:00', -45.6],
            ['2022-10-12T00:00:00', -49.33],
            ['2022-10-13T00:00:00', -53.16],
            ['2022-10-14T00:00:00', -57.1],
            ['2022-10-17T00:00:00', -60.57],
            ['2022-10-18T00:00:00', -63.73],
            ['2022-10-19T00:00:00', -67.24],
            ['2022-10-20T00:00:00', -67.56],
            ['2022-10-21T00:00:00', -64.89],
            ['2022-10-24T00:00:00', -62.81],
            ['2022-10-25T00:00:00', -61.83],
            ['2022-10-26T00:00:00', -62.24],
            ['2022-10-27T00:00:00', -63.2],
            ['2022-10-28T00:00:00', -63.88],
            ['2022-10-31T00:00:00', -62.74],
            ['2022-11-01T00:00:00', -62.12],
            ['2022-11-02T00:00:00', -58.77],
            ['2022-11-03T00:00:00', -54.45],
            ['2022-11-04T00:00:00', -47.68],
            ['2022-11-07T00:00:00', -38.06],
            ['2022-11-08T00:00:00', -28.79],
            ['2022-11-09T00:00:00', -21.68],
            ['2022-11-10T00:00:00', -15.97],
            ['2022-11-11T00:00:00', -13.76],
            ['2022-11-14T00:00:00', -12.15],
            ['2022-11-15T00:00:00', -9.81],
            ['2022-11-16T00:00:00', -6.62],
            ['2022-11-17T00:00:00', -3.47],
            ['2022-11-18T00:00:00', -1.41],
            ['2022-11-21T00:00:00', 4.84],
            ['2022-11-22T00:00:00', 9.8],
            ['2022-11-23T00:00:00', 12.89],
            ['2022-11-24T00:00:00', 16.23],
            ['2022-11-25T00:00:00', 20.64],
            ['2022-11-28T00:00:00', 26.29],
            ['2022-11-29T00:00:00', 30.28],
            ['2022-11-30T00:00:00', 33.06],
            ['2022-12-01T00:00:00', 38.31],
            ['2022-12-02T00:00:00', 40.72],
            ['2022-12-05T00:00:00', 43.25],
            ['2022-12-06T00:00:00', 45.96],
            ['2022-12-07T00:00:00', 49.25],
            ['2022-12-08T00:00:00', 53.67],
            ['2022-12-09T00:00:00', 53.15],
            ['2022-12-12T00:00:00', 52.62],
            ['2022-12-13T00:00:00', 50.06],
            ['2022-12-14T00:00:00', 47.67],
            ['2022-12-15T00:00:00', 42.66],
            ['2022-12-16T00:00:00', 37.71],
            ['2022-12-19T00:00:00', 35.05],
            ['2022-12-20T00:00:00', 32.45],
            ['2022-12-21T00:00:00', 28.55],
            ['2022-12-22T00:00:00', 26.9],
            ['2022-12-23T00:00:00', 24.4],
            ['2022-12-26T00:00:00', 22.41],
            ['2022-12-27T00:00:00', 16.89],
            ['2022-12-28T00:00:00', 11.04],
            ['2022-12-29T00:00:00', 6.76],
            ['2022-12-30T00:00:00', 3.38],
            ['2023-01-03T00:00:00', -0.73],
            ['2023-01-04T00:00:00', -4.78],
            ['2023-01-05T00:00:00', -7.72],
            ['2023-01-06T00:00:00', -10.94],
            ['2023-01-09T00:00:00', -14.88],
            ['2023-01-10T00:00:00', -17.02],
            ['2023-01-11T00:00:00', -18.5],
            ['2023-01-12T00:00:00', -20.33],
            ['2023-01-13T00:00:00', -21.45],
            ['2023-01-16T00:00:00', -22.78],
            ['2023-01-17T00:00:00', -19.28],
            ['2023-01-18T00:00:00', -13.93],
            ['2023-01-19T00:00:00', -7.84],
            ['2023-01-20T00:00:00', -0.83],
            ['2023-01-30T00:00:00', 8.67],
            ['2023-01-31T00:00:00', 17.39],
            ['2023-02-01T00:00:00', 24.26],
            ['2023-02-02T00:00:00', 31.28],
            ['2023-02-03T00:00:00', 39.24],
            ['2023-02-06T00:00:00', 45.42],
            ['2023-02-07T00:00:00', 49.92],
            ['2023-02-08T00:00:00', 53.52],
            ['2023-02-09T00:00:00', 55.51],
            ['2023-02-10T00:00:00', 57.58],
            ['2023-02-13T00:00:00', 56.89],
            ['2023-02-14T00:00:00', 55.81],
            ['2023-02-15T00:00:00', 55.33],
            ['2023-02-16T00:00:00', 54.49],
            ['2023-02-17T00:00:00', 51.4],
            ['2023-02-20T00:00:00', 47.85],
            ['2023-02-21T00:00:00', 45.18],
            ['2023-02-22T00:00:00', 42.53],
            ['2023-02-23T00:00:00', 38.51],
            ['2023-02-24T00:00:00', 33.54],
            ['2023-02-27T00:00:00', 29.13],
            ['2023-02-28T00:00:00', 26.03],
            ['2023-03-01T00:00:00', 23.12],
            ['2023-03-02T00:00:00', 19.6],
            ['2023-03-03T00:00:00', 17.78],
            ['2023-03-06T00:00:00', 17.32],
            ['2023-03-07T00:00:00', 16.06],
            ['2023-03-08T00:00:00', 15.71],
            ['2023-03-09T00:00:00', 14.39],
            ['2023-03-10T00:00:00', 13.61],
            ['2023-03-13T00:00:00', 11.1],
            ['2023-03-14T00:00:00', 7.91],
            ['2023-03-15T00:00:00', 5.51],
            ['2023-03-16T00:00:00', 5.04],
            ['2023-03-17T00:00:00', 6.31],
            ['2023-03-20T00:00:00', 3.98],
            ['2023-03-21T00:00:00', 1.9],
            ['2023-03-22T00:00:00', 1.33],
            ['2023-03-23T00:00:00', 0.1],
            ['2023-03-24T00:00:00', -0.54],
            ['2023-03-27T00:00:00', -0.79],
            ['2023-03-28T00:00:00', -2.17],
            ['2023-03-29T00:00:00', -4.44],
            ['2023-03-30T00:00:00', -6.63],
            ['2023-03-31T00:00:00', -9.1],
            ['2023-04-03T00:00:00', -10.81],
            ['2023-04-04T00:00:00', -11.04],
            ['2023-04-06T00:00:00', -11.5],
            ['2023-04-07T00:00:00', -11.8],
            ['2023-04-10T00:00:00', -7.8],
            ['2023-04-11T00:00:00', -4.98],
            ['2023-04-12T00:00:00', -1.62],
            ['2023-04-13T00:00:00', 0.67],
            ['2023-04-14T00:00:00', 3.96],
            ['2023-04-17T00:00:00', 6.23],
            ['2023-04-18T00:00:00', 10.33],
            ['2023-04-19T00:00:00', 14.38],
            ['2023-04-20T00:00:00', 18.15],
            ['2023-04-21T00:00:00', 20.95],
            ['2023-04-24T00:00:00', 23.7],
            ['2023-04-25T00:00:00', 25.57],
            ['2023-04-26T00:00:00', 26.92],
            ['2023-04-27T00:00:00', 27.54],
            ['2023-04-28T00:00:00', 25.97],
            ['2023-05-04T00:00:00', 25.73],
            ['2023-05-05T00:00:00', 23.99],
            ['2023-05-08T00:00:00', 23.6],
            ['2023-05-09T00:00:00', 22.73],
            ['2023-05-10T00:00:00', 21.8],
            ['2023-05-11T00:00:00', 20.75],
            ['2023-05-12T00:00:00', 19.54],
            ['2023-05-15T00:00:00', 17.4],
            ['2023-05-16T00:00:00', 15.67],
            ['2023-05-17T00:00:00', 12.37],
            ['2023-05-18T00:00:00', 5.6],
            ['2023-05-19T00:00:00', -1.37],
            ['2023-05-22T00:00:00', -6.87],
            ['2023-05-23T00:00:00', -11.91],
            ['2023-05-24T00:00:00', -13.73],
            ['2023-05-25T00:00:00', -14.71],
            ['2023-05-26T00:00:00', -14.79],
            ['2023-05-29T00:00:00', -14.88],
            ['2023-05-30T00:00:00', -15.8],
            ['2023-05-31T00:00:00', -18.84],
            ['2023-06-01T00:00:00', -23.5],
            ['2023-06-02T00:00:00', -26.23],
            ['2023-06-05T00:00:00', -31.35],
            ['2023-06-06T00:00:00', -34.32],
            ['2023-06-07T00:00:00', -34.42],
            ['2023-06-08T00:00:00', -33.93],
            ['2023-06-09T00:00:00', -32.24],
            ['2023-06-12T00:00:00', -32.05],
            ['2023-06-13T00:00:00', -32.7],
            ['2023-06-14T00:00:00', -34.05],
            ['2023-06-15T00:00:00', -36.1],
            ['2023-06-16T00:00:00', -36.66],
            ['2023-06-19T00:00:00', -37.1],
            ['2023-06-20T00:00:00', -34.38],
            ['2023-06-21T00:00:00', -29.59],
            ['2023-06-26T00:00:00', -25.83],
            ['2023-06-27T00:00:00', -21.16],
            ['2023-06-28T00:00:00', -18.1],
            ['2023-06-29T00:00:00', -16.79],
            ['2023-06-30T00:00:00', -15.05],
            ['2023-07-03T00:00:00', -13.73],
            ['2023-07-04T00:00:00', -11.92],
            ['2023-07-05T00:00:00', -10.14],
            ['2023-07-06T00:00:00', -6.29],
            ['2023-07-07T00:00:00', -2.84],
            ['2023-07-10T00:00:00', -0.61],
            ['2023-07-11T00:00:00', 1.34],
            ['2023-07-12T00:00:00', 1.54],
            ['2023-07-13T00:00:00', 0.01],
            ['2023-07-14T00:00:00', -1.23],
            ['2023-07-17T00:00:00', -3.06],
            ['2023-07-18T00:00:00', -5.54],
            ['2023-07-19T00:00:00', -7.12],
            ['2023-07-20T00:00:00', -9.0],
            ['2023-07-21T00:00:00', -9.42],
            ['2023-07-24T00:00:00', -6.82],
            ['2023-07-25T00:00:00', -6.09],
            ['2023-07-26T00:00:00', -6.18],
            ['2023-07-27T00:00:00', -5.36],
            ['2023-07-28T00:00:00', -4.2],
            ['2023-07-31T00:00:00', -3.87],
            ['2023-08-01T00:00:00', -2.82],
            ['2023-08-02T00:00:00', -0.47],
            ['2023-08-03T00:00:00', 2.8],
            ['2023-08-04T00:00:00', 6.49],
            ['2023-08-07T00:00:00', 10.51],
            ['2023-08-08T00:00:00', 13.14],
            ['2023-08-09T00:00:00', 15.49],
            ['2023-08-10T00:00:00', 14.41],
            ['2023-08-11T00:00:00', 11.26],
            ['2023-08-14T00:00:00', 10.33],
            ['2023-08-15T00:00:00', 9.23],
            ['2023-08-16T00:00:00', 7.22],
            ['2023-08-17T00:00:00', 7.1],
            ['2023-08-18T00:00:00', 7.9],
            ['2023-08-21T00:00:00', 8.43],
            ['2023-08-22T00:00:00', 4.78],
            ['2023-08-23T00:00:00', 1.33],
            ['2023-08-24T00:00:00', -2.28],
            ['2023-08-25T00:00:00', -8.84],
            ['2023-08-28T00:00:00', -15.25],
            ['2023-08-29T00:00:00', -22.2],
            ['2023-08-30T00:00:00', -25.88],
            ['2023-08-31T00:00:00', -29.8],
            ['2023-09-01T00:00:00', -35.04],
            ['2023-09-04T00:00:00', -38.64],
            ['2023-09-05T00:00:00', -41.68],
          ],
          hoverAnimation: true,
          label: {
            show: false,
            position: 'top',
            margin: 8,
          },
          lineStyle: {
            show: true,
            width: 1,
            opacity: 1,
            curveness: 0,
            type: 'solid',
            color: '#0484E8',
          },
          areaStyle: {
            opacity: 0,
          },
          zlevel: 0,
          z: 0,
        },
        {
          type: 'line',
          name: 'dif',
          connectNulls: false,
          xAxisIndex: 1,
          yAxisIndex: 1,
          symbolSize: 4,
          showSymbol: false,
          smooth: true,
          step: false,
          data: [
            ['2019-09-09T00:00:00', null],
            ['2019-09-10T00:00:00', null],
            ['2019-09-11T00:00:00', null],
            ['2019-09-12T00:00:00', null],
            ['2019-09-16T00:00:00', null],
            ['2019-09-17T00:00:00', null],
            ['2019-09-18T00:00:00', null],
            ['2019-09-19T00:00:00', null],
            ['2019-09-20T00:00:00', null],
            ['2019-09-23T00:00:00', null],
            ['2019-09-24T00:00:00', null],
            ['2019-09-25T00:00:00', null],
            ['2019-09-26T00:00:00', null],
            ['2019-09-27T00:00:00', null],
            ['2019-09-30T00:00:00', null],
            ['2019-10-08T00:00:00', null],
            ['2019-10-09T00:00:00', null],
            ['2019-10-10T00:00:00', null],
            ['2019-10-11T00:00:00', null],
            ['2019-10-14T00:00:00', null],
            ['2019-10-15T00:00:00', null],
            ['2019-10-16T00:00:00', null],
            ['2019-10-17T00:00:00', null],
            ['2019-10-18T00:00:00', null],
            ['2019-10-21T00:00:00', null],
            ['2019-10-22T00:00:00', null],
            ['2019-10-23T00:00:00', null],
            ['2019-10-24T00:00:00', null],
            ['2019-10-25T00:00:00', null],
            ['2019-10-28T00:00:00', null],
            ['2019-10-29T00:00:00', null],
            ['2019-10-30T00:00:00', null],
            ['2019-10-31T00:00:00', null],
            ['2019-11-01T00:00:00', -11.99],
            ['2019-11-04T00:00:00', -7.37],
            ['2019-11-05T00:00:00', -2.23],
            ['2019-11-06T00:00:00', 0.69],
            ['2019-11-07T00:00:00', 3.29],
            ['2019-11-08T00:00:00', 3.98],
            ['2019-11-11T00:00:00', -1.46],
            ['2019-11-12T00:00:00', -5.02],
            ['2019-11-13T00:00:00', -8.08],
            ['2019-11-14T00:00:00', -9.74],
            ['2019-11-15T00:00:00', -11.97],
            ['2019-11-18T00:00:00', -11.71],
            ['2019-11-19T00:00:00', -10.04],
            ['2019-11-20T00:00:00', -10.63],
            ['2019-11-21T00:00:00', -11.52],
            ['2019-11-22T00:00:00', -14.61],
            ['2019-11-25T00:00:00', -15.93],
            ['2019-11-26T00:00:00', -16.41],
            ['2019-11-27T00:00:00', -16.43],
            ['2019-11-28T00:00:00', -16.76],
            ['2019-11-29T00:00:00', -18.12],
            ['2019-12-02T00:00:00', -19.27],
            ['2019-12-03T00:00:00', -19.94],
            ['2019-12-04T00:00:00', -20.77],
            ['2019-12-05T00:00:00', -20.53],
            ['2019-12-06T00:00:00', -19.14],
            ['2019-12-09T00:00:00', -16.44],
            ['2019-12-10T00:00:00', -14.27],
            ['2019-12-11T00:00:00', -11.76],
            ['2019-12-12T00:00:00', -10.27],
            ['2019-12-13T00:00:00', -3.95],
            ['2019-12-16T00:00:00', 1.65],
            ['2019-12-17T00:00:00', 8.21],
            ['2019-12-18T00:00:00', 13.01],
            ['2019-12-19T00:00:00', 17.38],
            ['2019-12-20T00:00:00', 20.59],
            ['2019-12-23T00:00:00', 19.75],
            ['2019-12-24T00:00:00', 20.57],
            ['2019-12-25T00:00:00', 21.35],
            ['2019-12-26T00:00:00', 24.29],
            ['2019-12-27T00:00:00', 26.03],
            ['2019-12-30T00:00:00', 30.48],
            ['2019-12-31T00:00:00', 34.62],
            ['2020-01-02T00:00:00', 41.01],
            ['2020-01-03T00:00:00', 45.08],
            ['2020-01-06T00:00:00', 48.03],
            ['2020-01-07T00:00:00', 52.24],
            ['2020-01-08T00:00:00', 51.79],
            ['2020-01-09T00:00:00', 53.13],
            ['2020-01-10T00:00:00', 53.48],
            ['2020-01-13T00:00:00', 54.65],
            ['2020-01-14T00:00:00', 54.15],
            ['2020-01-15T00:00:00', 51.13],
            ['2020-01-16T00:00:00', 46.77],
            ['2020-01-17T00:00:00', 42.53],
            ['2020-01-20T00:00:00', 40.56],
            ['2020-01-21T00:00:00', 36.04],
            ['2020-01-22T00:00:00', 32.34],
            ['2020-01-23T00:00:00', 22.82],
            ['2020-02-03T00:00:00', -3.39],
            ['2020-02-04T00:00:00', -20.84],
            ['2020-02-05T00:00:00', -32.24],
            ['2020-02-06T00:00:00', -37.26],
            ['2020-02-07T00:00:00', -41.25],
            ['2020-02-10T00:00:00', -42.15],
            ['2020-02-11T00:00:00', -41.52],
            ['2020-02-12T00:00:00', -38.61],
            ['2020-02-13T00:00:00', -37.56],
            ['2020-02-14T00:00:00', -37.24],
            ['2020-02-17T00:00:00', -32.65],
            ['2020-02-18T00:00:00', -31.12],
            ['2020-02-19T00:00:00', -31.07],
            ['2020-02-20T00:00:00', -27.19],
            ['2020-02-21T00:00:00', -23.32],
            ['2020-02-24T00:00:00', -21.68],
            ['2020-02-25T00:00:00', -22.2],
            ['2020-02-26T00:00:00', -24.09],
            ['2020-02-27T00:00:00', -25.04],
            ['2020-02-28T00:00:00', -32.81],
            ['2020-03-02T00:00:00', -26.11],
            ['2020-03-03T00:00:00', -17.83],
            ['2020-03-04T00:00:00', -9.69],
            ['2020-03-05T00:00:00', 2.34],
            ['2020-03-06T00:00:00', 9.67],
            ['2020-03-09T00:00:00', 9.28],
            ['2020-03-10T00:00:00', 13.82],
            ['2020-03-11T00:00:00', 15.06],
            ['2020-03-12T00:00:00', 13.0],
            ['2020-03-13T00:00:00', 9.09],
            ['2020-03-16T00:00:00', -1.94],
            ['2020-03-17T00:00:00', -11.37],
            ['2020-03-18T00:00:00', -21.46],
            ['2020-03-19T00:00:00', -33.4],
            ['2020-03-20T00:00:00', -43.97],
            ['2020-03-23T00:00:00', -58.72],
            ['2020-03-24T00:00:00', -64.57],
            ['2020-03-25T00:00:00', -63.17],
            ['2020-03-26T00:00:00', -63.43],
            ['2020-03-27T00:00:00', -60.74],
            ['2020-03-30T00:00:00', -62.31],
            ['2020-03-31T00:00:00', -63.71],
            ['2020-04-01T00:00:00', -67.41],
            ['2020-04-02T00:00:00', -68.32],
            ['2020-04-03T00:00:00', -69.23],
            ['2020-04-07T00:00:00', -63.77],
            ['2020-04-08T00:00:00', -61.14],
            ['2020-04-09T00:00:00', -56.72],
            ['2020-04-10T00:00:00', -54.28],
            ['2020-04-13T00:00:00', -52.53],
            ['2020-04-14T00:00:00', -45.41],
            ['2020-04-15T00:00:00', -40.63],
            ['2020-04-16T00:00:00', -34.17],
            ['2020-04-17T00:00:00', -28.28],
            ['2020-04-20T00:00:00', -20.78],
            ['2020-04-21T00:00:00', -13.62],
            ['2020-04-22T00:00:00', -6.13],
            ['2020-04-23T00:00:00', 0.44],
            ['2020-04-24T00:00:00', 3.95],
            ['2020-04-27T00:00:00', 6.42],
            ['2020-04-28T00:00:00', 10.31],
            ['2020-04-29T00:00:00', 14.85],
            ['2020-04-30T00:00:00', 21.89],
            ['2020-05-06T00:00:00', 28.01],
            ['2020-05-07T00:00:00', 31.06],
            ['2020-05-08T00:00:00', 34.0],
            ['2020-05-11T00:00:00', 35.03],
            ['2020-05-12T00:00:00', 34.19],
            ['2020-05-13T00:00:00', 34.81],
            ['2020-05-14T00:00:00', 31.14],
            ['2020-05-15T00:00:00', 27.73],
            ['2020-05-18T00:00:00', 26.67],
            ['2020-05-19T00:00:00', 27.03],
            ['2020-05-20T00:00:00', 25.62],
            ['2020-05-21T00:00:00', 22.17],
            ['2020-05-22T00:00:00', 15.36],
            ['2020-05-25T00:00:00', 9.43],
            ['2020-05-26T00:00:00', 7.8],
            ['2020-05-27T00:00:00', 5.89],
            ['2020-05-28T00:00:00', 6.08],
            ['2020-05-29T00:00:00', 6.88],
            ['2020-06-01T00:00:00', 12.7],
            ['2020-06-02T00:00:00', 16.38],
            ['2020-06-03T00:00:00', 18.38],
            ['2020-06-04T00:00:00', 19.98],
            ['2020-06-05T00:00:00', 20.83],
            ['2020-06-08T00:00:00', 21.63],
            ['2020-06-09T00:00:00', 23.56],
            ['2020-06-10T00:00:00', 23.71],
            ['2020-06-11T00:00:00', 21.63],
            ['2020-06-12T00:00:00', 20.06],
            ['2020-06-15T00:00:00', 16.07],
            ['2020-06-16T00:00:00', 15.0],
            ['2020-06-17T00:00:00', 14.56],
            ['2020-06-18T00:00:00', 14.45],
            ['2020-06-19T00:00:00', 17.86],
            ['2020-06-22T00:00:00', 21.15],
            ['2020-06-23T00:00:00', 24.1],
            ['2020-06-24T00:00:00', 27.24],
            ['2020-06-29T00:00:00', 28.57],
            ['2020-06-30T00:00:00', 31.34],
            ['2020-07-01T00:00:00', 35.44],
            ['2020-07-02T00:00:00', 43.54],
            ['2020-07-03T00:00:00', 53.9],
            ['2020-07-06T00:00:00', 75.91],
            ['2020-07-07T00:00:00', 93.26],
            ['2020-07-08T00:00:00', 110.83],
            ['2020-07-09T00:00:00', 126.54],
            ['2020-07-10T00:00:00', 131.92],
            ['2020-07-13T00:00:00', 138.79],
            ['2020-07-14T00:00:00', 140.43],
            ['2020-07-15T00:00:00', 137.32],
            ['2020-07-16T00:00:00', 120.93],
            ['2020-07-17T00:00:00', 108.15],
            ['2020-07-20T00:00:00', 106.04],
            ['2020-07-21T00:00:00', 105.69],
            ['2020-07-22T00:00:00', 106.88],
            ['2020-07-23T00:00:00', 107.27],
            ['2020-07-24T00:00:00', 97.36],
            ['2020-07-27T00:00:00', 91.13],
            ['2020-07-28T00:00:00', 87.89],
            ['2020-07-29T00:00:00', 89.87],
            ['2020-07-30T00:00:00', 88.79],
            ['2020-07-31T00:00:00', 87.51],
            ['2020-08-03T00:00:00', 86.3],
            ['2020-08-04T00:00:00', 83.0],
            ['2020-08-05T00:00:00', 78.32],
            ['2020-08-06T00:00:00', 71.79],
            ['2020-08-07T00:00:00', 62.77],
            ['2020-08-10T00:00:00', 54.58],
            ['2020-08-11T00:00:00', 43.22],
            ['2020-08-12T00:00:00', 31.64],
            ['2020-08-13T00:00:00', 24.32],
            ['2020-08-14T00:00:00', 20.36],
            ['2020-08-17T00:00:00', 20.82],
            ['2020-08-18T00:00:00', 21.43],
            ['2020-08-19T00:00:00', 18.19],
            ['2020-08-20T00:00:00', 12.38],
            ['2020-08-21T00:00:00', 14.88],
            ['2020-08-24T00:00:00', 17.79],
            ['2020-08-25T00:00:00', 20.24],
            ['2020-08-26T00:00:00', 18.83],
            ['2020-08-27T00:00:00', 18.86],
            ['2020-08-28T00:00:00', 24.63],
            ['2020-08-31T00:00:00', 27.49],
            ['2020-09-01T00:00:00', 30.02],
            ['2020-09-02T00:00:00', 28.16],
            ['2020-09-03T00:00:00', 24.21],
            ['2020-09-04T00:00:00', 20.74],
            ['2020-09-07T00:00:00', 12.37],
            ['2020-09-08T00:00:00', 8.47],
            ['2020-09-09T00:00:00', 0.81],
            ['2020-09-10T00:00:00', -9.15],
            ['2020-09-11T00:00:00', -15.8],
            ['2020-09-14T00:00:00', -21.04],
            ['2020-09-15T00:00:00', -23.25],
            ['2020-09-16T00:00:00', -25.53],
            ['2020-09-17T00:00:00', -27.3],
            ['2020-09-18T00:00:00', -22.65],
            ['2020-09-21T00:00:00', -20.77],
            ['2020-09-22T00:00:00', -22.57],
            ['2020-09-23T00:00:00', -22.59],
            ['2020-09-24T00:00:00', -28.09],
            ['2020-09-25T00:00:00', -33.32],
            ['2020-09-28T00:00:00', -38.21],
            ['2020-09-29T00:00:00', -42.42],
            ['2020-09-30T00:00:00', -46.35],
            ['2020-10-09T00:00:00', -43.92],
            ['2020-10-12T00:00:00', -32.75],
            ['2020-10-13T00:00:00', -21.91],
            ['2020-10-14T00:00:00', -15.73],
            ['2020-10-15T00:00:00', -11.05],
            ['2020-10-16T00:00:00', -6.11],
            ['2020-10-19T00:00:00', -4.29],
            ['2020-10-20T00:00:00', -2.07],
            ['2020-10-21T00:00:00', -1.76],
            ['2020-10-22T00:00:00', -1.8],
            ['2020-10-23T00:00:00', -2.75],
            ['2020-10-26T00:00:00', -6.52],
            ['2020-10-27T00:00:00', -8.03],
            ['2020-10-28T00:00:00', -6.62],
            ['2020-10-29T00:00:00', -4.98],
            ['2020-10-30T00:00:00', -6.29],
            ['2020-11-02T00:00:00', -7.65],
            ['2020-11-03T00:00:00', -3.92],
            ['2020-11-04T00:00:00', -1.29],
            ['2020-11-05T00:00:00', 4.1],
            ['2020-11-06T00:00:00', 7.04],
            ['2020-11-09T00:00:00', 12.59],
            ['2020-11-10T00:00:00', 15.66],
            ['2020-11-11T00:00:00', 16.27],
            ['2020-11-12T00:00:00', 15.74],
            ['2020-11-13T00:00:00', 13.73],
            ['2020-11-16T00:00:00', 14.96],
            ['2020-11-17T00:00:00', 14.0],
            ['2020-11-18T00:00:00', 13.7],
            ['2020-11-19T00:00:00', 13.57],
            ['2020-11-20T00:00:00', 15.01],
            ['2020-11-23T00:00:00', 19.37],
            ['2020-11-24T00:00:00', 21.7],
            ['2020-11-25T00:00:00', 19.55],
            ['2020-11-26T00:00:00', 19.1],
            ['2020-11-27T00:00:00', 24.58],
            ['2020-11-30T00:00:00', 28.05],
            ['2020-12-01T00:00:00', 34.68],
            ['2020-12-02T00:00:00', 39.56],
            ['2020-12-03T00:00:00', 42.12],
            ['2020-12-04T00:00:00', 44.05],
            ['2020-12-07T00:00:00', 42.19],
            ['2020-12-08T00:00:00', 40.03],
            ['2020-12-09T00:00:00', 35.12],
            ['2020-12-10T00:00:00', 30.6],
            ['2020-12-11T00:00:00', 24.8],
            ['2020-12-14T00:00:00', 21.26],
            ['2020-12-15T00:00:00', 18.63],
            ['2020-12-16T00:00:00', 16.4],
            ['2020-12-17T00:00:00', 16.23],
            ['2020-12-18T00:00:00', 14.51],
            ['2020-12-21T00:00:00', 14.74],
            ['2020-12-22T00:00:00', 9.49],
            ['2020-12-23T00:00:00', 8.56],
            ['2020-12-24T00:00:00', 5.67],
            ['2020-12-25T00:00:00', 6.22],
            ['2020-12-28T00:00:00', 6.58],
            ['2020-12-29T00:00:00', 3.52],
            ['2020-12-30T00:00:00', 3.49],
            ['2020-12-31T00:00:00', 7.21],
            ['2021-01-04T00:00:00', 12.6],
            ['2021-01-05T00:00:00', 18.61],
            ['2021-01-06T00:00:00', 24.75],
            ['2021-01-07T00:00:00', 32.04],
            ['2021-01-08T00:00:00', 37.1],
            ['2021-01-11T00:00:00', 38.88],
            ['2021-01-12T00:00:00', 45.49],
            ['2021-01-13T00:00:00', 48.82],
            ['2021-01-14T00:00:00', 48.68],
            ['2021-01-15T00:00:00', 48.41],
            ['2021-01-18T00:00:00', 50.37],
            ['2021-01-19T00:00:00', 50.16],
            ['2021-01-20T00:00:00', 52.84],
            ['2021-01-21T00:00:00', 57.69],
            ['2021-01-22T00:00:00', 60.98],
            ['2021-01-25T00:00:00', 64.09],
            ['2021-01-26T00:00:00', 62.11],
            ['2021-01-27T00:00:00', 60.47],
            ['2021-01-28T00:00:00', 52.96],
            ['2021-01-29T00:00:00', 43.43],
            ['2021-02-01T00:00:00', 37.08],
            ['2021-02-02T00:00:00', 33.39],
            ['2021-02-03T00:00:00', 28.29],
            ['2021-02-04T00:00:00', 22.77],
            ['2021-02-05T00:00:00', 17.48],
            ['2021-02-08T00:00:00', 17.01],
            ['2021-02-09T00:00:00', 18.95],
            ['2021-02-10T00:00:00', 24.05],
            ['2021-02-18T00:00:00', 28.7],
            ['2021-02-19T00:00:00', 33.46],
            ['2021-02-22T00:00:00', 31.15],
            ['2021-02-23T00:00:00', 27.81],
            ['2021-02-24T00:00:00', 18.47],
            ['2021-02-25T00:00:00', 12.05],
            ['2021-02-26T00:00:00', 1.0],
            ['2021-03-01T00:00:00', -4.49],
            ['2021-03-02T00:00:00', -10.68],
            ['2021-03-03T00:00:00', -9.28],
            ['2021-03-04T00:00:00', -12.22],
            ['2021-03-05T00:00:00', -14.3],
            ['2021-03-08T00:00:00', -20.69],
            ['2021-03-09T00:00:00', -30.76],
            ['2021-03-10T00:00:00', -38.42],
            ['2021-03-11T00:00:00', -37.24],
            ['2021-03-12T00:00:00', -33.59],
            ['2021-03-15T00:00:00', -34.03],
            ['2021-03-16T00:00:00', -32.83],
            ['2021-03-17T00:00:00', -32.21],
            ['2021-03-18T00:00:00', -31.1],
            ['2021-03-19T00:00:00', -35.46],
            ['2021-03-22T00:00:00', -36.39],
            ['2021-03-23T00:00:00', -40.04],
            ['2021-03-24T00:00:00', -46.58],
            ['2021-03-25T00:00:00', -53.09],
            ['2021-03-26T00:00:00', -52.1],
            ['2021-03-29T00:00:00', -50.19],
            ['2021-03-30T00:00:00', -46.37],
            ['2021-03-31T00:00:00', -46.25],
            ['2021-04-01T00:00:00', -43.15],
            ['2021-04-02T00:00:00', -38.55],
            ['2021-04-06T00:00:00', -31.88],
            ['2021-04-07T00:00:00', -24.0],
            ['2021-04-08T00:00:00', -16.11],
            ['2021-04-09T00:00:00', -12.49],
            ['2021-04-12T00:00:00', -13.07],
            ['2021-04-13T00:00:00', -13.39],
            ['2021-04-14T00:00:00', -13.1],
            ['2021-04-15T00:00:00', -13.17],
            ['2021-04-16T00:00:00', -12.13],
            ['2021-04-19T00:00:00', -4.68],
            ['2021-04-20T00:00:00', -0.12],
            ['2021-04-21T00:00:00', 5.97],
            ['2021-04-22T00:00:00', 10.28],
            ['2021-04-23T00:00:00', 14.88],
            ['2021-04-26T00:00:00', 13.41],
            ['2021-04-27T00:00:00', 10.58],
            ['2021-04-28T00:00:00', 8.5],
            ['2021-04-29T00:00:00', 9.75],
            ['2021-04-30T00:00:00', 8.33],
            ['2021-05-06T00:00:00', 5.69],
            ['2021-05-07T00:00:00', 2.19],
            ['2021-05-10T00:00:00', 0.13],
            ['2021-05-11T00:00:00', -0.14],
            ['2021-05-12T00:00:00', 0.72],
            ['2021-05-13T00:00:00', 0.17],
            ['2021-05-14T00:00:00', 4.5],
            ['2021-05-17T00:00:00', 8.84],
            ['2021-05-18T00:00:00', 13.15],
            ['2021-05-19T00:00:00', 15.31],
            ['2021-05-20T00:00:00', 15.62],
            ['2021-05-21T00:00:00', 14.35],
            ['2021-05-24T00:00:00', 13.65],
            ['2021-05-25T00:00:00', 20.21],
            ['2021-05-26T00:00:00', 26.21],
            ['2021-05-27T00:00:00', 32.75],
            ['2021-05-28T00:00:00', 37.18],
            ['2021-05-31T00:00:00', 41.59],
            ['2021-06-01T00:00:00', 44.6],
            ['2021-06-02T00:00:00', 44.16],
            ['2021-06-03T00:00:00', 42.06],
            ['2021-06-04T00:00:00', 41.47],
            ['2021-06-07T00:00:00', 41.05],
            ['2021-06-08T00:00:00', 39.34],
            ['2021-06-09T00:00:00', 39.51],
            ['2021-06-10T00:00:00', 41.98],
            ['2021-06-11T00:00:00', 41.2],
            ['2021-06-15T00:00:00', 37.21],
            ['2021-06-16T00:00:00', 30.79],
            ['2021-06-17T00:00:00', 25.89],
            ['2021-06-18T00:00:00', 21.96],
            ['2021-06-21T00:00:00', 19.86],
            ['2021-06-22T00:00:00', 20.64],
            ['2021-06-23T00:00:00', 19.62],
            ['2021-06-24T00:00:00', 18.08],
            ['2021-06-25T00:00:00', 18.73],
            ['2021-06-28T00:00:00', 18.58],
            ['2021-06-29T00:00:00', 14.79],
            ['2021-06-30T00:00:00', 12.5],
            ['2021-07-01T00:00:00', 9.49],
            ['2021-07-02T00:00:00', 2.16],
            ['2021-07-05T00:00:00', -2.4],
            ['2021-07-06T00:00:00', -6.55],
            ['2021-07-07T00:00:00', -8.11],
            ['2021-07-08T00:00:00', -12.03],
            ['2021-07-09T00:00:00', -16.26],
            ['2021-07-12T00:00:00', -17.42],
            ['2021-07-13T00:00:00', -14.61],
            ['2021-07-14T00:00:00', -14.15],
            ['2021-07-15T00:00:00', -10.47],
            ['2021-07-16T00:00:00', -9.43],
            ['2021-07-19T00:00:00', -8.1],
            ['2021-07-20T00:00:00', -7.27],
            ['2021-07-21T00:00:00', -5.09],
            ['2021-07-22T00:00:00', -2.62],
            ['2021-07-23T00:00:00', -3.3],
            ['2021-07-26T00:00:00', -10.33],
            ['2021-07-27T00:00:00', -22.43],
            ['2021-07-28T00:00:00', -33.37],
            ['2021-07-29T00:00:00', -37.21],
            ['2021-07-30T00:00:00', -39.84],
            ['2021-08-02T00:00:00', -36.87],
            ['2021-08-03T00:00:00', -36.0],
            ['2021-08-04T00:00:00', -32.95],
            ['2021-08-05T00:00:00', -30.65],
            ['2021-08-06T00:00:00', -29.42],
            ['2021-08-09T00:00:00', -25.75],
            ['2021-08-10T00:00:00', -20.59],
            ['2021-08-11T00:00:00', -15.78],
            ['2021-08-12T00:00:00', -12.73],
            ['2021-08-13T00:00:00', -10.48],
            ['2021-08-16T00:00:00', -9.22],
            ['2021-08-17T00:00:00', -13.43],
            ['2021-08-18T00:00:00', -14.16],
            ['2021-08-19T00:00:00', -18.02],
            ['2021-08-20T00:00:00', -23.45],
            ['2021-08-23T00:00:00', -21.87],
            ['2021-08-24T00:00:00', -14.79],
            ['2021-08-25T00:00:00', -6.37],
            ['2021-08-26T00:00:00', -3.16],
            ['2021-08-27T00:00:00', 2.34],
            ['2021-08-30T00:00:00', 6.58],
            ['2021-08-31T00:00:00', 11.08],
            ['2021-09-01T00:00:00', 16.71],
            ['2021-09-02T00:00:00', 23.28],
            ['2021-09-03T00:00:00', 27.49],
            ['2021-09-06T00:00:00', 33.38],
            ['2021-09-07T00:00:00', 42.16],
            ['2021-09-08T00:00:00', 48.91],
            ['2021-09-09T00:00:00', 55.14],
            ['2021-09-10T00:00:00', 59.01],
            ['2021-09-13T00:00:00', 60.59],
            ['2021-09-14T00:00:00', 57.8],
            ['2021-09-15T00:00:00', 54.66],
            ['2021-09-16T00:00:00', 47.84],
            ['2021-09-17T00:00:00', 44.93],
            ['2021-09-22T00:00:00', 42.71],
            ['2021-09-23T00:00:00', 41.9],
            ['2021-09-24T00:00:00', 38.22],
            ['2021-09-27T00:00:00', 33.55],
            ['2021-09-28T00:00:00', 31.89],
            ['2021-09-29T00:00:00', 26.01],
            ['2021-09-30T00:00:00', 23.88],
            ['2021-10-08T00:00:00', 23.51],
            ['2021-10-11T00:00:00', 22.28],
            ['2021-10-12T00:00:00', 17.9],
            ['2021-10-13T00:00:00', 13.24],
            ['2021-10-14T00:00:00', 8.24],
            ['2021-10-15T00:00:00', 4.26],
            ['2021-10-18T00:00:00', -1.28],
            ['2021-10-19T00:00:00', -4.12],
            ['2021-10-20T00:00:00', -7.38],
            ['2021-10-21T00:00:00', -8.51],
            ['2021-10-22T00:00:00', -11.6],
            ['2021-10-25T00:00:00', -11.17],
            ['2021-10-26T00:00:00', -12.34],
            ['2021-10-27T00:00:00', -16.56],
            ['2021-10-28T00:00:00', -23.63],
            ['2021-10-29T00:00:00', -25.93],
            ['2021-11-01T00:00:00', -27.6],
            ['2021-11-02T00:00:00', -31.49],
            ['2021-11-03T00:00:00', -32.26],
            ['2021-11-04T00:00:00', -30.49],
            ['2021-11-05T00:00:00', -31.31],
            ['2021-11-08T00:00:00', -30.47],
            ['2021-11-09T00:00:00', -27.28],
            ['2021-11-10T00:00:00', -26.55],
            ['2021-11-11T00:00:00', -22.22],
            ['2021-11-12T00:00:00', -19.13],
            ['2021-11-15T00:00:00', -16.85],
            ['2021-11-16T00:00:00', -16.09],
            ['2021-11-17T00:00:00', -13.71],
            ['2021-11-18T00:00:00', -13.83],
            ['2021-11-19T00:00:00', -11.16],
            ['2021-11-22T00:00:00', -7.64],
            ['2021-11-23T00:00:00', -5.65],
            ['2021-11-24T00:00:00', -2.75],
            ['2021-11-25T00:00:00', 0.21],
            ['2021-11-26T00:00:00', 0.47],
            ['2021-11-29T00:00:00', -0.27],
            ['2021-11-30T00:00:00', 0.29],
            ['2021-12-01T00:00:00', 1.97],
            ['2021-12-02T00:00:00', 2.98],
            ['2021-12-03T00:00:00', 7.39],
            ['2021-12-06T00:00:00', 10.16],
            ['2021-12-07T00:00:00', 12.95],
            ['2021-12-08T00:00:00', 19.35],
            ['2021-12-09T00:00:00', 26.42],
            ['2021-12-10T00:00:00', 31.88],
            ['2021-12-13T00:00:00', 37.31],
            ['2021-12-14T00:00:00', 39.57],
            ['2021-12-15T00:00:00', 38.93],
            ['2021-12-16T00:00:00', 41.34],
            ['2021-12-17T00:00:00', 38.81],
            ['2021-12-20T00:00:00', 32.22],
            ['2021-12-21T00:00:00', 28.97],
            ['2021-12-22T00:00:00', 26.46],
            ['2021-12-23T00:00:00', 25.46],
            ['2021-12-24T00:00:00', 23.0],
            ['2021-12-27T00:00:00', 20.94],
            ['2021-12-28T00:00:00', 19.99],
            ['2021-12-29T00:00:00', 17.0],
            ['2021-12-30T00:00:00', 16.46],
            ['2021-12-31T00:00:00', 16.62],
            ['2022-01-04T00:00:00', 15.98],
            ['2022-01-05T00:00:00', 12.42],
            ['2022-01-06T00:00:00', 7.45],
            ['2022-01-07T00:00:00', 2.58],
            ['2022-01-10T00:00:00', 0.02],
            ['2022-01-11T00:00:00', -4.55],
            ['2022-01-12T00:00:00', -5.63],
            ['2022-01-13T00:00:00', -9.91],
            ['2022-01-14T00:00:00', -17.1],
            ['2022-01-17T00:00:00', -20.5],
            ['2022-01-18T00:00:00', -20.24],
            ['2022-01-19T00:00:00', -21.44],
            ['2022-01-20T00:00:00', -22.78],
            ['2022-01-21T00:00:00', -26.3],
            ['2022-01-24T00:00:00', -28.85],
            ['2022-01-25T00:00:00', -37.93],
            ['2022-01-26T00:00:00', -42.64],
            ['2022-01-27T00:00:00', -49.66],
            ['2022-01-28T00:00:00', -57.79],
            ['2022-02-07T00:00:00', -58.22],
            ['2022-02-08T00:00:00', -56.37],
            ['2022-02-09T00:00:00', -51.73],
            ['2022-02-10T00:00:00', -46.37],
            ['2022-02-11T00:00:00', -44.21],
            ['2022-02-14T00:00:00', -46.2],
            ['2022-02-15T00:00:00', -45.23],
            ['2022-02-16T00:00:00', -43.58],
            ['2022-02-17T00:00:00', -40.96],
            ['2022-02-18T00:00:00', -36.59],
            ['2022-02-21T00:00:00', -33.28],
            ['2022-02-22T00:00:00', -33.34],
            ['2022-02-23T00:00:00', -30.98],
            ['2022-02-24T00:00:00', -33.25],
            ['2022-02-25T00:00:00', -32.01],
            ['2022-02-28T00:00:00', -29.88],
            ['2022-03-01T00:00:00', -24.39],
            ['2022-03-02T00:00:00', -20.38],
            ['2022-03-03T00:00:00', -15.62],
            ['2022-03-04T00:00:00', -12.67],
            ['2022-03-07T00:00:00', -17.31],
            ['2022-03-08T00:00:00', -26.4],
            ['2022-03-09T00:00:00', -37.2],
            ['2022-03-10T00:00:00', -42.67],
            ['2022-03-11T00:00:00', -44.36],
            ['2022-03-14T00:00:00', -50.33],
            ['2022-03-15T00:00:00', -67.08],
            ['2022-03-16T00:00:00', -70.87],
            ['2022-03-17T00:00:00', -69.88],
            ['2022-03-18T00:00:00', -65.98],
            ['2022-03-21T00:00:00', -64.26],
            ['2022-03-22T00:00:00', -61.39],
            ['2022-03-23T00:00:00', -60.06],
            ['2022-03-24T00:00:00', -60.38],
            ['2022-03-25T00:00:00', -63.27],
            ['2022-03-28T00:00:00', -65.8],
            ['2022-03-29T00:00:00', -68.92],
            ['2022-03-30T00:00:00', -66.25],
            ['2022-03-31T00:00:00', -65.72],
            ['2022-04-01T00:00:00', -62.93],
            ['2022-04-06T00:00:00', -58.79],
            ['2022-04-07T00:00:00', -56.81],
            ['2022-04-08T00:00:00', -52.47],
            ['2022-04-11T00:00:00', -55.42],
            ['2022-04-12T00:00:00', -53.55],
            ['2022-04-13T00:00:00', -52.24],
            ['2022-04-14T00:00:00', -42.74],
            ['2022-04-15T00:00:00', -37.91],
            ['2022-04-18T00:00:00', -34.48],
            ['2022-04-19T00:00:00', -31.18],
            ['2022-04-20T00:00:00', -30.47],
            ['2022-04-21T00:00:00', -34.62],
            ['2022-04-22T00:00:00', -36.55],
            ['2022-04-25T00:00:00', -49.78],
            ['2022-04-26T00:00:00', -62.95],
            ['2022-04-27T00:00:00', -67.85],
            ['2022-04-28T00:00:00', -69.8],
            ['2022-04-29T00:00:00', -65.24],
            ['2022-05-05T00:00:00', -58.69],
            ['2022-05-06T00:00:00', -60.67],
            ['2022-05-09T00:00:00', -65.44],
            ['2022-05-10T00:00:00', -64.3],
            ['2022-05-11T00:00:00', -61.68],
            ['2022-05-12T00:00:00', -57.99],
            ['2022-05-13T00:00:00', -53.65],
            ['2022-05-16T00:00:00', -50.44],
            ['2022-05-17T00:00:00', -46.7],
            ['2022-05-18T00:00:00', -44.39],
            ['2022-05-19T00:00:00', -42.26],
            ['2022-05-20T00:00:00', -36.77],
            ['2022-05-23T00:00:00', -31.86],
            ['2022-05-24T00:00:00', -31.19],
            ['2022-05-25T00:00:00', -27.51],
            ['2022-05-26T00:00:00', -18.61],
            ['2022-05-27T00:00:00', -8.62],
            ['2022-05-30T00:00:00', -0.08],
            ['2022-05-31T00:00:00', 10.7],
            ['2022-06-01T00:00:00', 16.62],
            ['2022-06-02T00:00:00', 23.36],
            ['2022-06-06T00:00:00', 33.36],
            ['2022-06-07T00:00:00', 42.86],
            ['2022-06-08T00:00:00', 51.78],
            ['2022-06-09T00:00:00', 56.44],
            ['2022-06-10T00:00:00', 64.13],
            ['2022-06-13T00:00:00', 66.63],
            ['2022-06-14T00:00:00', 70.1],
            ['2022-06-15T00:00:00', 73.65],
            ['2022-06-16T00:00:00', 71.47],
            ['2022-06-17T00:00:00', 70.34],
            ['2022-06-20T00:00:00', 68.69],
            ['2022-06-21T00:00:00', 66.1],
            ['2022-06-22T00:00:00', 63.14],
            ['2022-06-23T00:00:00', 64.57],
            ['2022-06-24T00:00:00', 66.07],
            ['2022-06-27T00:00:00', 68.91],
            ['2022-06-28T00:00:00', 73.14],
            ['2022-06-29T00:00:00', 71.63],
            ['2022-06-30T00:00:00', 72.73],
            ['2022-07-01T00:00:00', 72.3],
            ['2022-07-04T00:00:00', 71.53],
            ['2022-07-05T00:00:00', 70.04],
            ['2022-07-06T00:00:00', 63.48],
            ['2022-07-07T00:00:00', 58.96],
            ['2022-07-08T00:00:00', 54.21],
            ['2022-07-11T00:00:00', 47.23],
            ['2022-07-12T00:00:00', 36.48],
            ['2022-07-13T00:00:00', 27.93],
            ['2022-07-14T00:00:00', 21.16],
            ['2022-07-15T00:00:00', 10.67],
            ['2022-07-18T00:00:00', 6.4],
            ['2022-07-19T00:00:00', 3.65],
            ['2022-07-20T00:00:00', 4.05],
            ['2022-07-21T00:00:00', 0.71],
            ['2022-07-22T00:00:00', -2.34],
            ['2022-07-25T00:00:00', -7.33],
            ['2022-07-26T00:00:00', -9.88],
            ['2022-07-27T00:00:00', -12.18],
            ['2022-07-28T00:00:00', -13.98],
            ['2022-07-29T00:00:00', -18.63],
            ['2022-08-01T00:00:00', -22.0],
            ['2022-08-02T00:00:00', -30.59],
            ['2022-08-03T00:00:00', -38.87],
            ['2022-08-04T00:00:00', -43.06],
            ['2022-08-05T00:00:00', -43.24],
            ['2022-08-08T00:00:00', -41.84],
            ['2022-08-09T00:00:00', -39.86],
            ['2022-08-10T00:00:00', -38.56],
            ['2022-08-11T00:00:00', -32.51],
            ['2022-08-12T00:00:00', -26.06],
            ['2022-08-15T00:00:00', -21.11],
            ['2022-08-16T00:00:00', -17.84],
            ['2022-08-17T00:00:00', -13.77],
            ['2022-08-18T00:00:00', -11.04],
            ['2022-08-19T00:00:00', -9.66],
            ['2022-08-22T00:00:00', -6.09],
            ['2022-08-23T00:00:00', -4.44],
            ['2022-08-24T00:00:00', -7.43],
            ['2022-08-25T00:00:00', -7.15],
            ['2022-08-26T00:00:00', -7.57],
            ['2022-08-29T00:00:00', -7.98],
            ['2022-08-30T00:00:00', -7.45],
            ['2022-08-31T00:00:00', -8.02],
            ['2022-09-01T00:00:00', -10.84],
            ['2022-09-02T00:00:00', -12.32],
            ['2022-09-05T00:00:00', -12.18],
            ['2022-09-06T00:00:00', -8.04],
            ['2022-09-07T00:00:00', -4.52],
            ['2022-09-08T00:00:00', -3.54],
            ['2022-09-09T00:00:00', -1.0],
            ['2022-09-13T00:00:00', 1.54],
            ['2022-09-14T00:00:00', 1.19],
            ['2022-09-15T00:00:00', -2.49],
            ['2022-09-16T00:00:00', -11.74],
            ['2022-09-19T00:00:00', -19.38],
            ['2022-09-20T00:00:00', -26.56],
            ['2022-09-21T00:00:00', -33.19],
            ['2022-09-22T00:00:00', -37.37],
            ['2022-09-23T00:00:00', -41.86],
            ['2022-09-26T00:00:00', -47.54],
            ['2022-09-27T00:00:00', -48.03],
            ['2022-09-28T00:00:00', -52.06],
            ['2022-09-29T00:00:00', -53.77],
            ['2022-09-30T00:00:00', -55.66],
            ['2022-10-10T00:00:00', -60.58],
            ['2022-10-11T00:00:00', -63.58],
            ['2022-10-12T00:00:00', -62.3],
            ['2022-10-13T00:00:00', -62.01],
            ['2022-10-14T00:00:00', -57.26],
            ['2022-10-17T00:00:00', -52.43],
            ['2022-10-18T00:00:00', -49.02],
            ['2022-10-19T00:00:00', -50.02],
            ['2022-10-20T00:00:00', -50.37],
            ['2022-10-21T00:00:00', -49.02],
            ['2022-10-24T00:00:00', -52.25],
            ['2022-10-25T00:00:00', -54.79],
            ['2022-10-26T00:00:00', -54.84],
            ['2022-10-27T00:00:00', -55.78],
            ['2022-10-28T00:00:00', -60.77],
            ['2022-10-31T00:00:00', -64.61],
            ['2022-11-01T00:00:00', -60.65],
            ['2022-11-02T00:00:00', -52.75],
            ['2022-11-03T00:00:00', -46.05],
            ['2022-11-04T00:00:00', -33.18],
            ['2022-11-07T00:00:00', -20.41],
            ['2022-11-08T00:00:00', -10.93],
            ['2022-11-09T00:00:00', -5.65],
            ['2022-11-10T00:00:00', -3.47],
            ['2022-11-11T00:00:00', 0.9],
            ['2022-11-14T00:00:00', 3.78],
            ['2022-11-15T00:00:00', 10.0],
            ['2022-11-16T00:00:00', 14.29],
            ['2022-11-17T00:00:00', 17.28],
            ['2022-11-18T00:00:00', 17.47],
            ['2022-11-21T00:00:00', 18.79],
            ['2022-11-22T00:00:00', 19.52],
            ['2022-11-23T00:00:00', 20.3],
            ['2022-11-24T00:00:00', 20.5],
            ['2022-11-25T00:00:00', 22.27],
            ['2022-11-28T00:00:00', 22.85],
            ['2022-11-29T00:00:00', 28.81],
            ['2022-11-30T00:00:00', 32.97],
            ['2022-12-01T00:00:00', 38.64],
            ['2022-12-02T00:00:00', 41.2],
            ['2022-12-05T00:00:00', 47.31],
            ['2022-12-06T00:00:00', 51.49],
            ['2022-12-07T00:00:00', 53.53],
            ['2022-12-08T00:00:00', 54.95],
            ['2022-12-09T00:00:00', 54.14],
            ['2022-12-12T00:00:00', 50.71],
            ['2022-12-13T00:00:00', 46.58],
            ['2022-12-14T00:00:00', 42.64],
            ['2022-12-15T00:00:00', 36.98],
            ['2022-12-16T00:00:00', 31.63],
            ['2022-12-19T00:00:00', 23.66],
            ['2022-12-20T00:00:00', 15.03],
            ['2022-12-21T00:00:00', 7.41],
            ['2022-12-22T00:00:00', 1.87],
            ['2022-12-23T00:00:00', -3.05],
            ['2022-12-26T00:00:00', -4.88],
            ['2022-12-27T00:00:00', -5.51],
            ['2022-12-28T00:00:00', -7.1],
            ['2022-12-29T00:00:00', -8.95],
            ['2022-12-30T00:00:00', -9.33],
            ['2023-01-03T00:00:00', -7.98],
            ['2023-01-04T00:00:00', -7.15],
            ['2023-01-05T00:00:00', -4.39],
            ['2023-01-06T00:00:00', -2.99],
            ['2023-01-09T00:00:00', -1.56],
            ['2023-01-10T00:00:00', -1.01],
            ['2023-01-11T00:00:00', -1.63],
            ['2023-01-12T00:00:00', -2.78],
            ['2023-01-13T00:00:00', -1.34],
            ['2023-01-16T00:00:00', 1.41],
            ['2023-01-17T00:00:00', 5.51],
            ['2023-01-18T00:00:00', 9.55],
            ['2023-01-19T00:00:00', 14.62],
            ['2023-01-20T00:00:00', 20.98],
            ['2023-01-30T00:00:00', 27.94],
            ['2023-01-31T00:00:00', 32.39],
            ['2023-02-01T00:00:00', 37.72],
            ['2023-02-02T00:00:00', 42.38],
            ['2023-02-03T00:00:00', 45.15],
            ['2023-02-06T00:00:00', 44.92],
            ['2023-02-07T00:00:00', 45.16],
            ['2023-02-08T00:00:00', 44.19],
            ['2023-02-09T00:00:00', 45.95],
            ['2023-02-10T00:00:00', 46.6],
            ['2023-02-13T00:00:00', 47.42],
            ['2023-02-14T00:00:00', 48.01],
            ['2023-02-15T00:00:00', 47.14],
            ['2023-02-16T00:00:00', 43.17],
            ['2023-02-17T00:00:00', 36.63],
            ['2023-02-20T00:00:00', 35.79],
            ['2023-02-21T00:00:00', 36.32],
            ['2023-02-22T00:00:00', 34.74],
            ['2023-02-23T00:00:00', 31.91],
            ['2023-02-24T00:00:00', 27.13],
            ['2023-02-27T00:00:00', 22.38],
            ['2023-02-28T00:00:00', 20.14],
            ['2023-03-01T00:00:00', 20.31],
            ['2023-03-02T00:00:00', 19.59],
            ['2023-03-03T00:00:00', 20.75],
            ['2023-03-06T00:00:00', 21.16],
            ['2023-03-07T00:00:00', 17.81],
            ['2023-03-08T00:00:00', 14.98],
            ['2023-03-09T00:00:00', 11.61],
            ['2023-03-10T00:00:00', 5.74],
            ['2023-03-13T00:00:00', 3.53],
            ['2023-03-14T00:00:00', -0.39],
            ['2023-03-15T00:00:00', -1.55],
            ['2023-03-16T00:00:00', -4.13],
            ['2023-03-17T00:00:00', -3.32],
            ['2023-03-20T00:00:00', -5.35],
            ['2023-03-21T00:00:00', -5.06],
            ['2023-03-22T00:00:00', -3.72],
            ['2023-03-23T00:00:00', -1.47],
            ['2023-03-24T00:00:00', -1.56],
            ['2023-03-27T00:00:00', -2.55],
            ['2023-03-28T00:00:00', -4.68],
            ['2023-03-29T00:00:00', -7.0],
            ['2023-03-30T00:00:00', -7.51],
            ['2023-03-31T00:00:00', -7.17],
            ['2023-04-03T00:00:00', -4.92],
            ['2023-04-04T00:00:00', -1.47],
            ['2023-04-06T00:00:00', 0.6],
            ['2023-04-07T00:00:00', 2.81],
            ['2023-04-10T00:00:00', 5.53],
            ['2023-04-11T00:00:00', 7.08],
            ['2023-04-12T00:00:00', 9.51],
            ['2023-04-13T00:00:00', 10.29],
            ['2023-04-14T00:00:00', 12.83],
            ['2023-04-17T00:00:00', 18.02],
            ['2023-04-18T00:00:00', 23.45],
            ['2023-04-19T00:00:00', 26.16],
            ['2023-04-20T00:00:00', 27.93],
            ['2023-04-21T00:00:00', 24.03],
            ['2023-04-24T00:00:00', 19.33],
            ['2023-04-25T00:00:00', 14.61],
            ['2023-04-26T00:00:00', 11.16],
            ['2023-04-27T00:00:00', 10.53],
            ['2023-04-28T00:00:00', 12.12],
            ['2023-05-04T00:00:00', 16.27],
            ['2023-05-05T00:00:00', 17.44],
            ['2023-05-08T00:00:00', 23.1],
            ['2023-05-09T00:00:00', 23.42],
            ['2023-05-10T00:00:00', 20.14],
            ['2023-05-11T00:00:00', 16.13],
            ['2023-05-12T00:00:00', 9.91],
            ['2023-05-15T00:00:00', 7.67],
            ['2023-05-16T00:00:00', 4.74],
            ['2023-05-17T00:00:00', 1.03],
            ['2023-05-18T00:00:00', -2.33],
            ['2023-05-19T00:00:00', -6.67],
            ['2023-05-22T00:00:00', -9.14],
            ['2023-05-23T00:00:00', -15.08],
            ['2023-05-24T00:00:00', -21.79],
            ['2023-05-25T00:00:00', -26.33],
            ['2023-05-26T00:00:00', -28.1],
            ['2023-05-29T00:00:00', -28.34],
            ['2023-05-30T00:00:00', -28.05],
            ['2023-05-31T00:00:00', -30.05],
            ['2023-06-01T00:00:00', -32.06],
            ['2023-06-02T00:00:00', -30.92],
            ['2023-06-05T00:00:00', -31.34],
            ['2023-06-06T00:00:00', -33.84],
            ['2023-06-07T00:00:00', -33.94],
            ['2023-06-08T00:00:00', -32.09],
            ['2023-06-09T00:00:00', -28.49],
            ['2023-06-12T00:00:00', -26.35],
            ['2023-06-13T00:00:00', -25.03],
            ['2023-06-14T00:00:00', -24.79],
            ['2023-06-15T00:00:00', -23.22],
            ['2023-06-16T00:00:00', -20.28],
            ['2023-06-19T00:00:00', -19.48],
            ['2023-06-20T00:00:00', -18.43],
            ['2023-06-21T00:00:00', -19.28],
            ['2023-06-26T00:00:00', -23.33],
            ['2023-06-27T00:00:00', -21.72],
            ['2023-06-28T00:00:00', -20.52],
            ['2023-06-29T00:00:00', -20.26],
            ['2023-06-30T00:00:00', -17.61],
            ['2023-07-03T00:00:00', -12.22],
            ['2023-07-04T00:00:00', -7.19],
            ['2023-07-05T00:00:00', -5.09],
            ['2023-07-06T00:00:00', -4.01],
            ['2023-07-07T00:00:00', -3.46],
            ['2023-07-10T00:00:00', -2.72],
            ['2023-07-11T00:00:00', -0.62],
            ['2023-07-12T00:00:00', -1.78],
            ['2023-07-13T00:00:00', -0.35],
            ['2023-07-14T00:00:00', 0.86],
            ['2023-07-17T00:00:00', -0.78],
            ['2023-07-18T00:00:00', -3.46],
            ['2023-07-19T00:00:00', -5.32],
            ['2023-07-20T00:00:00', -9.38],
            ['2023-07-21T00:00:00', -11.9],
            ['2023-07-24T00:00:00', -12.48],
            ['2023-07-25T00:00:00', -7.91],
            ['2023-07-26T00:00:00', -5.43],
            ['2023-07-27T00:00:00', -3.66],
            ['2023-07-28T00:00:00', 2.44],
            ['2023-07-31T00:00:00', 7.83],
            ['2023-08-01T00:00:00', 11.72],
            ['2023-08-02T00:00:00', 12.63],
            ['2023-08-03T00:00:00', 14.85],
            ['2023-08-04T00:00:00', 17.69],
            ['2023-08-07T00:00:00', 18.76],
            ['2023-08-08T00:00:00', 18.33],
            ['2023-08-09T00:00:00', 16.96],
            ['2023-08-10T00:00:00', 15.19],
            ['2023-08-11T00:00:00', 7.52],
            ['2023-08-14T00:00:00', 1.83],
            ['2023-08-15T00:00:00', -2.48],
            ['2023-08-16T00:00:00', -7.87],
            ['2023-08-17T00:00:00', -9.71],
            ['2023-08-18T00:00:00', -12.32],
            ['2023-08-21T00:00:00', -16.69],
            ['2023-08-22T00:00:00', -19.15],
            ['2023-08-23T00:00:00', -24.29],
            ['2023-08-24T00:00:00', -27.91],
            ['2023-08-25T00:00:00', -33.2],
            ['2023-08-28T00:00:00', -34.65],
            ['2023-08-29T00:00:00', -33.48],
            ['2023-08-30T00:00:00', -31.27],
            ['2023-08-31T00:00:00', -31.41],
            ['2023-09-01T00:00:00', -31.49],
            ['2023-09-04T00:00:00', -28.31],
            ['2023-09-05T00:00:00', -27.98],
          ],
          hoverAnimation: true,
          label: {
            show: false,
            position: 'top',
            margin: 8,
          },
          lineStyle: {
            show: true,
            width: 1,
            opacity: 1,
            curveness: 0,
            type: 'solid',
            color: '#E7DA05',
          },
          areaStyle: {
            opacity: 0,
          },
          zlevel: 0,
          z: 0,
        },
        {
          type: 'bar',
          name: 'macd',
          xAxisIndex: 1,
          yAxisIndex: 1,
          data: [
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: null,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 6.63,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 6.75,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 8.25,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 7.17,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 6.78,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 4.46,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -3.22,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -8.66,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -13.52,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -15.18,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -18.83,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -18.03,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -11.65,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -9.76,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -9.98,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -11.13,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -7.18,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -4.52,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -4.1,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -6.03,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -8.09,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -7.42,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -3.71,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -1.76,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 4.86,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 11.95,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 15.22,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 17.91,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 19.57,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 18.77,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 23.69,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 29.13,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 38.76,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 42.66,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 42.68,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 37.43,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 25.9,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 18.42,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 12.04,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 9.69,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 7.21,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 9.33,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 11.42,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 15.56,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 16.11,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 14.44,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 13.25,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 5.07,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 1.12,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -3.85,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -3.77,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -4.85,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -7.08,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -11.34,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -13.49,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -13.55,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -21.81,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -25.86,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -40.5,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -81.67,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -102.63,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -106.81,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -97.13,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -80.98,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -63.22,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -43.8,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -23.23,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -16.08,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -7.32,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 9.82,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 25.61,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 36.05,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 51.61,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 63.25,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 70.62,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 72.83,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 68.29,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 66.43,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 45.39,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 31.67,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 24.42,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 21.96,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 28.41,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 25.44,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 7.55,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 1.12,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -10.97,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -27.11,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -43.82,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -66.26,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -78.88,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -92.51,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -99.83,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -87.89,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -85.82,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -70.47,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -47.55,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -31.25,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -21.1,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -12.97,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -4.65,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 3.42,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 19.23,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 29.23,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 43.81,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 57.1,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 67.64,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 68.75,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 65.9,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 64.69,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 60.43,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 53.94,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 51.22,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 49.81,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 38.71,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 31.96,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 24.97,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 14.08,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 6.86,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -2.39,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -7.94,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -7.66,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -4.33,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -3.16,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 2.69,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 5.75,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 7.37,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 6.18,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 0.62,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -2.62,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -5.49,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -4.23,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -4.58,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -5.95,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -15.54,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -19.86,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -19.35,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -21.09,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -21.07,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -19.55,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -9.09,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 0.79,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 7.49,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 9.75,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 13.82,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 16.44,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 19.5,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 18.7,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 14.22,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 10.01,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 2.71,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 4.86,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 6.57,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 7.73,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 9.94,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 9.31,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 8.54,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 7.09,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 0.69,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -1.55,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 4.06,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 16.04,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 32.51,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 66.84,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 88.56,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 106.19,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 118.36,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 108.71,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 102.1,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 85.53,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 57.21,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 14.71,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -17.87,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -27.47,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -33.36,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -38.84,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -44.16,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -67.72,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -86.3,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -100.48,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -102.02,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -100.72,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -93.25,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -70.03,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -49.71,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -33.59,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -17.79,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -12.94,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -1.16,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 1.95,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 0.68,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -4.64,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -1.99,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 16.06,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 31.43,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 35.29,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 30.82,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 19.69,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 8.92,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -3.64,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -18.37,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -24.54,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -20.84,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -17.39,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -12.94,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -7.94,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -6.28,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -12.2,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -25.5,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -32.84,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -46.09,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -54.94,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -52.81,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -41.78,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -28.13,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -19.03,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -15.88,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -2.14,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 4.2,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 1.58,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -1.2,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -9.45,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -12.12,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -12.15,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -8.07,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -1.84,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 11.75,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 32.31,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 44.09,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 48.82,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 46.68,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 41.75,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 33.03,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 27.31,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 23.35,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 18.95,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 9.28,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 1.38,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -4.3,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -7.38,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -8.83,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -18.45,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -24.31,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -22.2,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -19.4,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -12.35,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -7.24,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 8.87,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 18.26,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 19.98,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 19.2,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 13.56,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 13.33,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 13.65,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 14.37,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 18.72,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 22.27,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 27.71,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 28.25,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 21.13,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 15.65,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 11.81,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 4.0,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 7.19,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 7.53,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 7.46,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 6.04,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 1.08,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -5.05,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -15.7,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -23.59,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -32.55,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -33.43,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -33.89,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -32.84,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -24.71,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -18.83,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -9.97,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -12.9,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -14.11,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -17.14,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -13.58,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -11.42,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -8.28,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 0.1,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 16.19,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 30.39,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 42.6,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 51.86,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 58.15,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 58.18,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 46.53,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 46.88,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 43.61,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 33.78,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 25.78,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 22.43,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 14.56,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 6.37,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 3.58,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -2.61,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -5.2,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -17.85,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -28.91,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -46.18,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -59.72,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -64.11,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -59.59,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -55.87,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -51.92,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -48.54,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -40.91,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -20.97,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 0.19,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 17.08,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 31.18,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 33.06,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 30.58,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 16.78,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 10.64,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -4.03,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -5.31,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -12.57,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -5.48,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -13.47,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -19.03,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -35.52,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -53.05,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -65.44,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -60.49,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -54.28,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -49.12,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -37.29,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -24.33,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -10.37,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -7.46,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 0.2,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 4.12,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 1.01,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 1.29,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 8.61,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 19.52,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 30.83,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 40.09,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 49.03,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 57.0,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 55.7,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 47.55,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 39.24,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 29.41,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 17.36,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 3.79,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -0.79,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -6.25,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -3.54,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 3.04,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 9.3,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 10.29,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 6.51,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 2.3,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -3.44,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -6.93,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -6.1,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -3.54,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -4.83,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -4.69,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -7.54,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -7.21,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -4.92,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -0.37,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -3.76,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 2.51,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 11.33,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 17.4,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 17.47,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 17.62,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 13.49,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 11.9,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 20.35,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 27.81,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 32.77,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 33.36,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 33.77,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 34.01,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 26.34,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 16.31,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 8.37,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 2.94,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -4.6,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -9.49,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -12.58,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -16.09,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -23.07,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -33.5,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -39.72,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -43.64,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -46.8,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -44.18,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -35.55,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -27.45,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -14.09,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -5.38,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -3.18,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 1.42,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 3.88,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -5.1,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -8.51,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -10.42,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -6.86,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -6.27,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -3.79,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 2.52,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 8.25,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 3.45,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 4.86,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 1.41,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -1.21,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -3.33,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -0.7,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 3.42,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 5.17,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -5.81,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -25.26,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -39.59,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -40.11,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -42.71,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -30.62,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -20.83,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -6.34,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 1.38,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 4.08,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 10.95,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 22.13,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 27.73,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 32.05,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 31.85,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 32.16,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 21.19,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 19.77,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 18.78,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 13.5,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 14.73,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 16.61,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 19.65,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 16.92,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 13.72,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 13.7,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 13.75,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 14.87,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 18.5,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 17.04,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 22.04,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 31.83,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 36.35,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 39.37,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 40.99,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 42.74,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 31.32,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 19.9,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 2.35,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -12.52,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -20.04,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -22.41,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -26.72,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -34.93,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -38.39,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -50.59,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -54.79,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -52.77,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -48.89,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -51.76,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -47.91,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -42.39,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -33.44,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -25.46,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -13.63,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -3.26,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 4.59,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 8.95,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 14.43,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 16.81,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 14.4,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 7.35,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 6.45,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 5.1,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -0.05,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -7.79,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -6.55,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -8.96,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -9.25,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -10.79,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -11.78,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -6.13,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -0.35,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 2.54,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 4.58,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 7.3,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 7.69,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 13.89,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 21.97,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 29.96,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 34.17,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 32.54,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 28.08,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 24.52,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 18.77,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 15.74,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 13.77,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 15.11,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 12.33,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 10.27,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 12.19,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 18.53,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 19.85,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 20.68,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 15.77,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 9.98,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 6.2,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -2.95,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -13.71,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -15.5,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -16.98,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -14.03,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -16.52,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -18.96,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -17.6,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -22.08,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -23.32,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -19.26,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -18.04,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -21.95,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -23.84,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -24.0,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -20.89,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -21.1,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -15.87,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -16.7,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -18.71,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -17.61,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -13.31,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -9.86,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -6.33,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -7.23,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -6.31,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -18.14,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -22.2,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -33.58,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -42.0,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -33.98,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -21.34,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -7.43,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 4.73,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 9.73,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 10.95,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 12.27,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 16.73,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 19.55,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 24.19,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 29.04,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 29.4,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 34.45,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 28.57,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 26.16,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 26.62,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 27.04,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 27.42,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 23.75,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 12.16,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -4.21,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -27.9,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -46.73,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -50.74,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -49.06,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -58.9,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -83.85,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -80.44,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -67.33,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -49.15,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -31.76,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -16.18,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 2.44,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 11.59,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 10.02,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 12.07,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 15.56,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 30.13,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 41.28,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 55.17,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 63.46,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 58.67,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 54.03,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 38.79,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 34.6,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 25.57,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 15.67,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 10.77,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 6.34,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 3.43,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -4.61,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -21.79,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -30.73,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -59.12,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -81.98,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -82.41,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -75.53,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -52.99,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -30.93,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -19.81,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -4.82,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 6.74,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 18.19,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 23.15,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 33.21,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 39.56,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 49.87,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 55.06,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 60.23,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 71.52,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 77.94,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 66.21,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 62.7,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 52.45,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 40.6,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 34.49,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 32.76,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 33.47,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 34.29,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 33.63,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 29.88,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 28.01,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 20.47,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 18.15,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 11.42,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 8.95,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 7.62,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 4.09,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 5.56,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 6.6,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 3.95,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -8.87,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -11.92,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -9.55,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -4.72,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 0.89,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -2.23,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -1.14,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -4.17,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -5.03,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -6.95,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -15.08,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -20.95,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -24.64,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -35.67,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -44.39,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -48.55,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -50.67,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -56.27,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -51.69,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -46.73,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -39.62,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -36.71,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -33.5,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -31.21,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -22.7,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -18.17,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -10.7,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -7.84,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -1.84,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -6.79,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -13.06,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -11.94,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -2.99,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 5.27,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 13.62,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 16.93,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 26.95,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 28.4,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 28.7,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 28.79,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 31.19,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 28.67,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 22.82,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 19.05,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 18.09,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 8.15,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 6.09,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 2.77,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 2.55,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -2.9,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -12.32,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -19.44,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -22.67,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -23.21,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -16.1,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -10.79,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -5.8,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 1.96,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 6.84,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 5.83,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -0.08,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -14.56,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -25.22,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -27.64,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -27.12,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -27.38,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -26.58,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -29.08,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -22.23,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -23.73,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -25.45,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -27.88,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -34.81,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -35.96,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -25.95,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -17.7,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -0.31,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 16.26,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 29.41,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 34.42,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 34.38,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 31.74,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 21.11,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 14.07,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 14.8,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 14.84,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 6.23,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -3.74,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 2.95,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 12.03,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 16.8,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 28.99,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 35.29,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 35.72,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 32.06,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 25.0,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 29.32,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 31.86,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 39.63,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 41.82,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 41.49,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 37.74,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 27.91,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 19.44,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 14.83,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 8.53,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 3.26,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -6.87,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -2.93,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -0.18,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 0.65,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 0.96,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 8.12,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 11.06,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 8.56,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 2.57,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 1.98,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -3.83,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -6.95,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -10.05,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -11.37,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -12.16,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -22.78,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -34.84,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -42.3,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -50.05,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -54.9,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -54.58,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -44.8,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -36.27,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -31.43,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -25.41,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -14.5,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -4.73,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 6.67,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 15.89,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 26.64,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 32.01,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 33.75,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 35.08,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 40.22,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 48.38,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 49.57,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 46.98,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 44.93,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 43.61,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 38.54,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 30.0,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 26.91,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 22.2,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 11.81,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -1.0,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -9.5,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -18.67,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -19.13,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -21.95,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -18.95,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -15.59,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -16.37,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -22.63,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -29.54,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -24.12,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -17.73,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -15.6,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -13.2,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -12.82,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -13.51,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -11.79,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -5.62,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -0.02,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 5.94,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 7.69,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 3.5,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -1.45,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -5.58,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -15.75,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -15.14,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -16.6,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -14.12,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -18.32,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -19.28,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -18.64,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -13.92,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -10.1,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -3.14,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -2.05,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -3.52,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -5.02,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -5.12,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -1.75,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 3.85,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 11.77,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 19.13,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 24.21,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 29.22,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 26.66,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 24.11,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 22.27,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 19.23,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 17.74,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 23.59,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 26.23,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 23.56,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 19.55,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 6.17,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -8.75,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -21.91,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -31.52,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -34.01,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -27.7,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -18.92,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -13.11,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -1.01,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 1.38,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -3.33,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -9.24,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -19.27,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -19.47,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -21.87,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -22.69,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -15.86,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -10.61,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -4.53,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -6.35,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -16.13,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -23.23,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -26.62,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -26.92,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -24.51,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -22.42,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -17.12,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -9.39,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 0.02,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 0.96,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 0.96,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 3.69,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 7.48,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 11.4,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 15.33,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 18.53,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 25.75,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 32.75,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 35.25,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 31.9,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 20.62,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 5.0,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -1.13,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -4.83,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -6.94,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -5.12,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 3.0,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 9.45,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 10.09,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 4.55,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -1.23,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -4.22,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -3.92,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -6.63,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -0.71,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 4.18,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 4.56,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 4.15,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 3.6,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -0.75,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -4.96,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -11.31,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -3.63,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 1.51,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 3.41,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 13.27,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 23.39,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 29.06,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 26.2,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 24.1,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 22.4,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 16.5,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 10.38,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 2.94,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 1.55,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: -7.48,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -16.99,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -23.42,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -30.18,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -33.61,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -40.43,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -50.24,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -47.86,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -51.25,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -51.26,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -48.72,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -38.8,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -22.57,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -10.78,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: -3.23,
              itemStyle: {
                color: '#14b143',
              },
            },
            {
              value: 7.1,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 20.66,
              itemStyle: {
                color: '#ef232a',
              },
            },
            {
              value: 27.39,
              itemStyle: {
                color: '#ef232a',
              },
            },
          ],
          barCategoryGap: '20%',
          label: {
            show: false,
            position: 'top',
            margin: 8,
          },
          rippleEffect: {
            show: true,
            brushType: 'stroke',
            scale: 2.5,
            period: 4,
          },
        },
        {
          type: 'scatter',
          name: 'macd\u6807\u51c6\u6a21\u578b',
          xAxisIndex: 1,
          yAxisIndex: 1,
          symbol: 'circle',
          symbolSize: 1,
          symbolRotate: 0,
          data: [
            ['2019-11-05T00:00:00', 8.25, 40.04],
            ['2019-12-19T00:00:00', 42.68, 462.22],
            ['2020-02-25T00:00:00', 72.83, 650.47],
            ['2020-04-10T00:00:00', 68.75, 751.76],
            ['2020-05-12T00:00:00', 7.37, 22.61],
            ['2020-06-09T00:00:00', 19.5, 168.14],
            ['2020-07-09T00:00:00', 118.36, 800.81],
            ['2020-08-11T00:00:00', 1.95, 2.62],
            ['2020-08-19T00:00:00', 35.29, 142.22],
            ['2020-09-21T00:00:00', 4.2, 5.78],
            ['2020-10-14T00:00:00', 48.82, 338.69],
            ['2020-11-24T00:00:00', 28.25, 300.06],
            ['2021-01-08T00:00:00', 58.18, 500.99],
            ['2021-02-22T00:00:00', 33.06, 139.49],
            ['2021-04-02T00:00:00', 57.0, 404.76],
            ['2021-04-21T00:00:00', 10.29, 31.43],
            ['2021-06-01T00:00:00', 34.01, 327.76],
            ['2021-07-01T00:00:00', 3.88, 5.3],
            ['2021-07-13T00:00:00', 8.25, 20.49],
            ['2021-07-23T00:00:00', 5.17, 8.58],
            ['2021-09-13T00:00:00', 42.74, 661.94],
            ['2021-10-26T00:00:00', 16.81, 78.09],
            ['2021-11-24T00:00:00', 34.17, 396.43],
            ['2022-02-23T00:00:00', 34.45, 362.76],
            ['2022-04-06T00:00:00', 63.46, 489.6],
            ['2022-05-23T00:00:00', 77.94, 970.75],
            ['2022-06-28T00:00:00', 0.89, 0.89],
            ['2022-08-17T00:00:00', 31.19, 288.04],
            ['2022-09-13T00:00:00', 6.84, 14.64],
            ['2022-10-19T00:00:00', 34.42, 217.28],
            ['2022-11-16T00:00:00', 41.82, 484.68],
            ['2022-12-06T00:00:00', 11.06, 33.89],
            ['2023-01-17T00:00:00', 49.57, 553.2],
            ['2023-03-06T00:00:00', 7.69, 17.14],
            ['2023-04-07T00:00:00', 29.22, 297.29],
            ['2023-05-09T00:00:00', 1.38, 1.38],
            ['2023-06-19T00:00:00', 35.25, 209.66],
            ['2023-07-05T00:00:00', 10.09, 27.09],
            ['2023-07-17T00:00:00', 4.56, 16.49],
            ['2023-08-01T00:00:00', 29.06, 174.72],
            ['2023-09-05T00:00:00', 27.39, 55.15],
          ],
          label: {
            show: true,
            position: 'top',
            color: '#ef232a',
            margin: 8,
            formatter: function (params: any) {
              return params.value[2];
            },
          },
          itemStyle: {
            color: '#ef232a',
          },
        },
        {
          type: 'scatter',
          name: 'macd\u6807\u51c6\u6a21\u578b',
          xAxisIndex: 1,
          yAxisIndex: 1,
          symbol: 'circle',
          symbolSize: 1,
          symbolRotate: 0,
          data: [
            ['2019-11-15T00:00:00', -18.83, -162.76],
            ['2020-02-05T00:00:00', -106.81, -768.96],
            ['2020-03-19T00:00:00', -99.83, -781.08],
            ['2020-04-29T00:00:00', -7.94, -25.47],
            ['2020-05-27T00:00:00', -21.09, -148.42],
            ['2020-06-30T00:00:00', -1.55, -1.55],
            ['2020-07-29T00:00:00', -102.02, -897.39],
            ['2020-08-13T00:00:00', -4.64, -6.64],
            ['2020-09-10T00:00:00', -54.94, -443.28],
            ['2020-09-28T00:00:00', -12.15, -44.82],
            ['2020-11-02T00:00:00', -24.31, -124.45],
            ['2020-12-15T00:00:00', -33.89, -308.01],
            ['2021-02-01T00:00:00', -64.11, -502.37],
            ['2021-03-10T00:00:00', -65.44, -457.25],
            ['2021-04-15T00:00:00', -6.25, -10.58],
            ['2021-05-07T00:00:00', -7.54, -53.33],
            ['2021-06-21T00:00:00', -46.8, -359.34],
            ['2021-07-06T00:00:00', -10.42, -40.94],
            ['2021-07-20T00:00:00', -3.33, -5.23],
            ['2021-07-30T00:00:00', -42.71, -211.28],
            ['2021-09-30T00:00:00', -54.79, -579.89],
            ['2021-11-10T00:00:00', -11.78, -61.65],
            ['2022-01-28T00:00:00', -42.0, -601.29],
            ['2022-03-15T00:00:00', -83.85, -566.25],
            ['2022-04-27T00:00:00', -82.41, -464.71],
            ['2022-06-23T00:00:00', -11.92, -35.06],
            ['2022-07-15T00:00:00', -56.27, -651.24],
            ['2022-09-05T00:00:00', -23.21, -113.22],
            ['2022-10-11T00:00:00', -35.96, -391.69],
            ['2022-10-31T00:00:00', -3.74, -3.74],
            ['2022-11-28T00:00:00', -6.87, -9.98],
            ['2022-12-23T00:00:00', -54.9, -460.94],
            ['2023-02-17T00:00:00', -29.54, -287.73],
            ['2023-03-17T00:00:00', -19.28, -169.51],
            ['2023-04-27T00:00:00', -34.01, -156.94],
            ['2023-05-29T00:00:00', -26.92, -299.58],
            ['2023-06-29T00:00:00', -6.94, -18.02],
            ['2023-07-12T00:00:00', -6.63, -16.71],
            ['2023-07-24T00:00:00', -11.31, -20.64],
            ['2023-08-24T00:00:00', -51.26, -476.82],
          ],
          label: {
            show: true,
            position: 'bottom',
            color: '#14b143',
            margin: 8,
            formatter: function (params: any) {
              return params.value[2];
            },
          },
          itemStyle: {
            color: '#14b143',
          },
        },
      ],
      legend: [
        {
          data: ['K\u7ebf', '\u5206\u7b14', '\u5206\u7b14', '\u7ebf\u6bb5', '\u7ebf\u6bb5'],
          selected: {
            'K\u7ebf': true,
          },
          show: false,
          padding: 5,
          itemGap: 10,
          itemWidth: 25,
          itemHeight: 14,
        },
        {
          data: ['dea', 'dif', 'macd', 'macd\u6807\u51c6\u6a21\u578b', 'macd\u6807\u51c6\u6a21\u578b'],
          selected: {
            dea: true,
            dif: true,
          },
          show: false,
          padding: 5,
          itemGap: 10,
          itemWidth: 25,
          itemHeight: 14,
        },
      ],
      tooltip: {
        show: true,
        trigger: 'axis',
        triggerOn: 'mousemove|click',
        axisPointer: {
          type: 'cross',
        },
        textStyle: {
          color: '#000',
        },
        backgroundColor: 'rgba(245, 245, 245, 0.8)',
        borderColor: '#ccc',
        borderWidth: 1,
      },
      xAxis: [
        {
          type: 'category',
          show: true,
          scale: false,
          nameLocation: 'end',
          nameGap: 15,
          gridIndex: 0,
          axisTick: {
            show: false,
            alignWithLabel: false,
            inside: false,
          },
          axisLabel: {
            show: false,
            position: 'top',
            margin: 8,
          },
          inverse: false,
          offset: 0,
          splitNumber: 5,
          minInterval: 0,
          splitLine: {
            show: false,
            lineStyle: {
              show: true,
              width: 1,
              opacity: 1,
              curveness: 0,
              type: 'solid',
            },
          },
          data: [
            '2019-09-09T00:00:00',
            '2019-09-10T00:00:00',
            '2019-09-11T00:00:00',
            '2019-09-12T00:00:00',
            '2019-09-16T00:00:00',
            '2019-09-17T00:00:00',
            '2019-09-18T00:00:00',
            '2019-09-19T00:00:00',
            '2019-09-20T00:00:00',
            '2019-09-23T00:00:00',
            '2019-09-24T00:00:00',
            '2019-09-25T00:00:00',
            '2019-09-26T00:00:00',
            '2019-09-27T00:00:00',
            '2019-09-30T00:00:00',
            '2019-10-08T00:00:00',
            '2019-10-09T00:00:00',
            '2019-10-10T00:00:00',
            '2019-10-11T00:00:00',
            '2019-10-14T00:00:00',
            '2019-10-15T00:00:00',
            '2019-10-16T00:00:00',
            '2019-10-17T00:00:00',
            '2019-10-18T00:00:00',
            '2019-10-21T00:00:00',
            '2019-10-22T00:00:00',
            '2019-10-23T00:00:00',
            '2019-10-24T00:00:00',
            '2019-10-25T00:00:00',
            '2019-10-28T00:00:00',
            '2019-10-29T00:00:00',
            '2019-10-30T00:00:00',
            '2019-10-31T00:00:00',
            '2019-11-01T00:00:00',
            '2019-11-04T00:00:00',
            '2019-11-05T00:00:00',
            '2019-11-06T00:00:00',
            '2019-11-07T00:00:00',
            '2019-11-08T00:00:00',
            '2019-11-11T00:00:00',
            '2019-11-12T00:00:00',
            '2019-11-13T00:00:00',
            '2019-11-14T00:00:00',
            '2019-11-15T00:00:00',
            '2019-11-18T00:00:00',
            '2019-11-19T00:00:00',
            '2019-11-20T00:00:00',
            '2019-11-21T00:00:00',
            '2019-11-22T00:00:00',
            '2019-11-25T00:00:00',
            '2019-11-26T00:00:00',
            '2019-11-27T00:00:00',
            '2019-11-28T00:00:00',
            '2019-11-29T00:00:00',
            '2019-12-02T00:00:00',
            '2019-12-03T00:00:00',
            '2019-12-04T00:00:00',
            '2019-12-05T00:00:00',
            '2019-12-06T00:00:00',
            '2019-12-09T00:00:00',
            '2019-12-10T00:00:00',
            '2019-12-11T00:00:00',
            '2019-12-12T00:00:00',
            '2019-12-13T00:00:00',
            '2019-12-16T00:00:00',
            '2019-12-17T00:00:00',
            '2019-12-18T00:00:00',
            '2019-12-19T00:00:00',
            '2019-12-20T00:00:00',
            '2019-12-23T00:00:00',
            '2019-12-24T00:00:00',
            '2019-12-25T00:00:00',
            '2019-12-26T00:00:00',
            '2019-12-27T00:00:00',
            '2019-12-30T00:00:00',
            '2019-12-31T00:00:00',
            '2020-01-02T00:00:00',
            '2020-01-03T00:00:00',
            '2020-01-06T00:00:00',
            '2020-01-07T00:00:00',
            '2020-01-08T00:00:00',
            '2020-01-09T00:00:00',
            '2020-01-10T00:00:00',
            '2020-01-13T00:00:00',
            '2020-01-14T00:00:00',
            '2020-01-15T00:00:00',
            '2020-01-16T00:00:00',
            '2020-01-17T00:00:00',
            '2020-01-20T00:00:00',
            '2020-01-21T00:00:00',
            '2020-01-22T00:00:00',
            '2020-01-23T00:00:00',
            '2020-02-03T00:00:00',
            '2020-02-04T00:00:00',
            '2020-02-05T00:00:00',
            '2020-02-06T00:00:00',
            '2020-02-07T00:00:00',
            '2020-02-10T00:00:00',
            '2020-02-11T00:00:00',
            '2020-02-12T00:00:00',
            '2020-02-13T00:00:00',
            '2020-02-14T00:00:00',
            '2020-02-17T00:00:00',
            '2020-02-18T00:00:00',
            '2020-02-19T00:00:00',
            '2020-02-20T00:00:00',
            '2020-02-21T00:00:00',
            '2020-02-24T00:00:00',
            '2020-02-25T00:00:00',
            '2020-02-26T00:00:00',
            '2020-02-27T00:00:00',
            '2020-02-28T00:00:00',
            '2020-03-02T00:00:00',
            '2020-03-03T00:00:00',
            '2020-03-04T00:00:00',
            '2020-03-05T00:00:00',
            '2020-03-06T00:00:00',
            '2020-03-09T00:00:00',
            '2020-03-10T00:00:00',
            '2020-03-11T00:00:00',
            '2020-03-12T00:00:00',
            '2020-03-13T00:00:00',
            '2020-03-16T00:00:00',
            '2020-03-17T00:00:00',
            '2020-03-18T00:00:00',
            '2020-03-19T00:00:00',
            '2020-03-20T00:00:00',
            '2020-03-23T00:00:00',
            '2020-03-24T00:00:00',
            '2020-03-25T00:00:00',
            '2020-03-26T00:00:00',
            '2020-03-27T00:00:00',
            '2020-03-30T00:00:00',
            '2020-03-31T00:00:00',
            '2020-04-01T00:00:00',
            '2020-04-02T00:00:00',
            '2020-04-03T00:00:00',
            '2020-04-07T00:00:00',
            '2020-04-08T00:00:00',
            '2020-04-09T00:00:00',
            '2020-04-10T00:00:00',
            '2020-04-13T00:00:00',
            '2020-04-14T00:00:00',
            '2020-04-15T00:00:00',
            '2020-04-16T00:00:00',
            '2020-04-17T00:00:00',
            '2020-04-20T00:00:00',
            '2020-04-21T00:00:00',
            '2020-04-22T00:00:00',
            '2020-04-23T00:00:00',
            '2020-04-24T00:00:00',
            '2020-04-27T00:00:00',
            '2020-04-28T00:00:00',
            '2020-04-29T00:00:00',
            '2020-04-30T00:00:00',
            '2020-05-06T00:00:00',
            '2020-05-07T00:00:00',
            '2020-05-08T00:00:00',
            '2020-05-11T00:00:00',
            '2020-05-12T00:00:00',
            '2020-05-13T00:00:00',
            '2020-05-14T00:00:00',
            '2020-05-15T00:00:00',
            '2020-05-18T00:00:00',
            '2020-05-19T00:00:00',
            '2020-05-20T00:00:00',
            '2020-05-21T00:00:00',
            '2020-05-22T00:00:00',
            '2020-05-25T00:00:00',
            '2020-05-26T00:00:00',
            '2020-05-27T00:00:00',
            '2020-05-28T00:00:00',
            '2020-05-29T00:00:00',
            '2020-06-01T00:00:00',
            '2020-06-02T00:00:00',
            '2020-06-03T00:00:00',
            '2020-06-04T00:00:00',
            '2020-06-05T00:00:00',
            '2020-06-08T00:00:00',
            '2020-06-09T00:00:00',
            '2020-06-10T00:00:00',
            '2020-06-11T00:00:00',
            '2020-06-12T00:00:00',
            '2020-06-15T00:00:00',
            '2020-06-16T00:00:00',
            '2020-06-17T00:00:00',
            '2020-06-18T00:00:00',
            '2020-06-19T00:00:00',
            '2020-06-22T00:00:00',
            '2020-06-23T00:00:00',
            '2020-06-24T00:00:00',
            '2020-06-29T00:00:00',
            '2020-06-30T00:00:00',
            '2020-07-01T00:00:00',
            '2020-07-02T00:00:00',
            '2020-07-03T00:00:00',
            '2020-07-06T00:00:00',
            '2020-07-07T00:00:00',
            '2020-07-08T00:00:00',
            '2020-07-09T00:00:00',
            '2020-07-10T00:00:00',
            '2020-07-13T00:00:00',
            '2020-07-14T00:00:00',
            '2020-07-15T00:00:00',
            '2020-07-16T00:00:00',
            '2020-07-17T00:00:00',
            '2020-07-20T00:00:00',
            '2020-07-21T00:00:00',
            '2020-07-22T00:00:00',
            '2020-07-23T00:00:00',
            '2020-07-24T00:00:00',
            '2020-07-27T00:00:00',
            '2020-07-28T00:00:00',
            '2020-07-29T00:00:00',
            '2020-07-30T00:00:00',
            '2020-07-31T00:00:00',
            '2020-08-03T00:00:00',
            '2020-08-04T00:00:00',
            '2020-08-05T00:00:00',
            '2020-08-06T00:00:00',
            '2020-08-07T00:00:00',
            '2020-08-10T00:00:00',
            '2020-08-11T00:00:00',
            '2020-08-12T00:00:00',
            '2020-08-13T00:00:00',
            '2020-08-14T00:00:00',
            '2020-08-17T00:00:00',
            '2020-08-18T00:00:00',
            '2020-08-19T00:00:00',
            '2020-08-20T00:00:00',
            '2020-08-21T00:00:00',
            '2020-08-24T00:00:00',
            '2020-08-25T00:00:00',
            '2020-08-26T00:00:00',
            '2020-08-27T00:00:00',
            '2020-08-28T00:00:00',
            '2020-08-31T00:00:00',
            '2020-09-01T00:00:00',
            '2020-09-02T00:00:00',
            '2020-09-03T00:00:00',
            '2020-09-04T00:00:00',
            '2020-09-07T00:00:00',
            '2020-09-08T00:00:00',
            '2020-09-09T00:00:00',
            '2020-09-10T00:00:00',
            '2020-09-11T00:00:00',
            '2020-09-14T00:00:00',
            '2020-09-15T00:00:00',
            '2020-09-16T00:00:00',
            '2020-09-17T00:00:00',
            '2020-09-18T00:00:00',
            '2020-09-21T00:00:00',
            '2020-09-22T00:00:00',
            '2020-09-23T00:00:00',
            '2020-09-24T00:00:00',
            '2020-09-25T00:00:00',
            '2020-09-28T00:00:00',
            '2020-09-29T00:00:00',
            '2020-09-30T00:00:00',
            '2020-10-09T00:00:00',
            '2020-10-12T00:00:00',
            '2020-10-13T00:00:00',
            '2020-10-14T00:00:00',
            '2020-10-15T00:00:00',
            '2020-10-16T00:00:00',
            '2020-10-19T00:00:00',
            '2020-10-20T00:00:00',
            '2020-10-21T00:00:00',
            '2020-10-22T00:00:00',
            '2020-10-23T00:00:00',
            '2020-10-26T00:00:00',
            '2020-10-27T00:00:00',
            '2020-10-28T00:00:00',
            '2020-10-29T00:00:00',
            '2020-10-30T00:00:00',
            '2020-11-02T00:00:00',
            '2020-11-03T00:00:00',
            '2020-11-04T00:00:00',
            '2020-11-05T00:00:00',
            '2020-11-06T00:00:00',
            '2020-11-09T00:00:00',
            '2020-11-10T00:00:00',
            '2020-11-11T00:00:00',
            '2020-11-12T00:00:00',
            '2020-11-13T00:00:00',
            '2020-11-16T00:00:00',
            '2020-11-17T00:00:00',
            '2020-11-18T00:00:00',
            '2020-11-19T00:00:00',
            '2020-11-20T00:00:00',
            '2020-11-23T00:00:00',
            '2020-11-24T00:00:00',
            '2020-11-25T00:00:00',
            '2020-11-26T00:00:00',
            '2020-11-27T00:00:00',
            '2020-11-30T00:00:00',
            '2020-12-01T00:00:00',
            '2020-12-02T00:00:00',
            '2020-12-03T00:00:00',
            '2020-12-04T00:00:00',
            '2020-12-07T00:00:00',
            '2020-12-08T00:00:00',
            '2020-12-09T00:00:00',
            '2020-12-10T00:00:00',
            '2020-12-11T00:00:00',
            '2020-12-14T00:00:00',
            '2020-12-15T00:00:00',
            '2020-12-16T00:00:00',
            '2020-12-17T00:00:00',
            '2020-12-18T00:00:00',
            '2020-12-21T00:00:00',
            '2020-12-22T00:00:00',
            '2020-12-23T00:00:00',
            '2020-12-24T00:00:00',
            '2020-12-25T00:00:00',
            '2020-12-28T00:00:00',
            '2020-12-29T00:00:00',
            '2020-12-30T00:00:00',
            '2020-12-31T00:00:00',
            '2021-01-04T00:00:00',
            '2021-01-05T00:00:00',
            '2021-01-06T00:00:00',
            '2021-01-07T00:00:00',
            '2021-01-08T00:00:00',
            '2021-01-11T00:00:00',
            '2021-01-12T00:00:00',
            '2021-01-13T00:00:00',
            '2021-01-14T00:00:00',
            '2021-01-15T00:00:00',
            '2021-01-18T00:00:00',
            '2021-01-19T00:00:00',
            '2021-01-20T00:00:00',
            '2021-01-21T00:00:00',
            '2021-01-22T00:00:00',
            '2021-01-25T00:00:00',
            '2021-01-26T00:00:00',
            '2021-01-27T00:00:00',
            '2021-01-28T00:00:00',
            '2021-01-29T00:00:00',
            '2021-02-01T00:00:00',
            '2021-02-02T00:00:00',
            '2021-02-03T00:00:00',
            '2021-02-04T00:00:00',
            '2021-02-05T00:00:00',
            '2021-02-08T00:00:00',
            '2021-02-09T00:00:00',
            '2021-02-10T00:00:00',
            '2021-02-18T00:00:00',
            '2021-02-19T00:00:00',
            '2021-02-22T00:00:00',
            '2021-02-23T00:00:00',
            '2021-02-24T00:00:00',
            '2021-02-25T00:00:00',
            '2021-02-26T00:00:00',
            '2021-03-01T00:00:00',
            '2021-03-02T00:00:00',
            '2021-03-03T00:00:00',
            '2021-03-04T00:00:00',
            '2021-03-05T00:00:00',
            '2021-03-08T00:00:00',
            '2021-03-09T00:00:00',
            '2021-03-10T00:00:00',
            '2021-03-11T00:00:00',
            '2021-03-12T00:00:00',
            '2021-03-15T00:00:00',
            '2021-03-16T00:00:00',
            '2021-03-17T00:00:00',
            '2021-03-18T00:00:00',
            '2021-03-19T00:00:00',
            '2021-03-22T00:00:00',
            '2021-03-23T00:00:00',
            '2021-03-24T00:00:00',
            '2021-03-25T00:00:00',
            '2021-03-26T00:00:00',
            '2021-03-29T00:00:00',
            '2021-03-30T00:00:00',
            '2021-03-31T00:00:00',
            '2021-04-01T00:00:00',
            '2021-04-02T00:00:00',
            '2021-04-06T00:00:00',
            '2021-04-07T00:00:00',
            '2021-04-08T00:00:00',
            '2021-04-09T00:00:00',
            '2021-04-12T00:00:00',
            '2021-04-13T00:00:00',
            '2021-04-14T00:00:00',
            '2021-04-15T00:00:00',
            '2021-04-16T00:00:00',
            '2021-04-19T00:00:00',
            '2021-04-20T00:00:00',
            '2021-04-21T00:00:00',
            '2021-04-22T00:00:00',
            '2021-04-23T00:00:00',
            '2021-04-26T00:00:00',
            '2021-04-27T00:00:00',
            '2021-04-28T00:00:00',
            '2021-04-29T00:00:00',
            '2021-04-30T00:00:00',
            '2021-05-06T00:00:00',
            '2021-05-07T00:00:00',
            '2021-05-10T00:00:00',
            '2021-05-11T00:00:00',
            '2021-05-12T00:00:00',
            '2021-05-13T00:00:00',
            '2021-05-14T00:00:00',
            '2021-05-17T00:00:00',
            '2021-05-18T00:00:00',
            '2021-05-19T00:00:00',
            '2021-05-20T00:00:00',
            '2021-05-21T00:00:00',
            '2021-05-24T00:00:00',
            '2021-05-25T00:00:00',
            '2021-05-26T00:00:00',
            '2021-05-27T00:00:00',
            '2021-05-28T00:00:00',
            '2021-05-31T00:00:00',
            '2021-06-01T00:00:00',
            '2021-06-02T00:00:00',
            '2021-06-03T00:00:00',
            '2021-06-04T00:00:00',
            '2021-06-07T00:00:00',
            '2021-06-08T00:00:00',
            '2021-06-09T00:00:00',
            '2021-06-10T00:00:00',
            '2021-06-11T00:00:00',
            '2021-06-15T00:00:00',
            '2021-06-16T00:00:00',
            '2021-06-17T00:00:00',
            '2021-06-18T00:00:00',
            '2021-06-21T00:00:00',
            '2021-06-22T00:00:00',
            '2021-06-23T00:00:00',
            '2021-06-24T00:00:00',
            '2021-06-25T00:00:00',
            '2021-06-28T00:00:00',
            '2021-06-29T00:00:00',
            '2021-06-30T00:00:00',
            '2021-07-01T00:00:00',
            '2021-07-02T00:00:00',
            '2021-07-05T00:00:00',
            '2021-07-06T00:00:00',
            '2021-07-07T00:00:00',
            '2021-07-08T00:00:00',
            '2021-07-09T00:00:00',
            '2021-07-12T00:00:00',
            '2021-07-13T00:00:00',
            '2021-07-14T00:00:00',
            '2021-07-15T00:00:00',
            '2021-07-16T00:00:00',
            '2021-07-19T00:00:00',
            '2021-07-20T00:00:00',
            '2021-07-21T00:00:00',
            '2021-07-22T00:00:00',
            '2021-07-23T00:00:00',
            '2021-07-26T00:00:00',
            '2021-07-27T00:00:00',
            '2021-07-28T00:00:00',
            '2021-07-29T00:00:00',
            '2021-07-30T00:00:00',
            '2021-08-02T00:00:00',
            '2021-08-03T00:00:00',
            '2021-08-04T00:00:00',
            '2021-08-05T00:00:00',
            '2021-08-06T00:00:00',
            '2021-08-09T00:00:00',
            '2021-08-10T00:00:00',
            '2021-08-11T00:00:00',
            '2021-08-12T00:00:00',
            '2021-08-13T00:00:00',
            '2021-08-16T00:00:00',
            '2021-08-17T00:00:00',
            '2021-08-18T00:00:00',
            '2021-08-19T00:00:00',
            '2021-08-20T00:00:00',
            '2021-08-23T00:00:00',
            '2021-08-24T00:00:00',
            '2021-08-25T00:00:00',
            '2021-08-26T00:00:00',
            '2021-08-27T00:00:00',
            '2021-08-30T00:00:00',
            '2021-08-31T00:00:00',
            '2021-09-01T00:00:00',
            '2021-09-02T00:00:00',
            '2021-09-03T00:00:00',
            '2021-09-06T00:00:00',
            '2021-09-07T00:00:00',
            '2021-09-08T00:00:00',
            '2021-09-09T00:00:00',
            '2021-09-10T00:00:00',
            '2021-09-13T00:00:00',
            '2021-09-14T00:00:00',
            '2021-09-15T00:00:00',
            '2021-09-16T00:00:00',
            '2021-09-17T00:00:00',
            '2021-09-22T00:00:00',
            '2021-09-23T00:00:00',
            '2021-09-24T00:00:00',
            '2021-09-27T00:00:00',
            '2021-09-28T00:00:00',
            '2021-09-29T00:00:00',
            '2021-09-30T00:00:00',
            '2021-10-08T00:00:00',
            '2021-10-11T00:00:00',
            '2021-10-12T00:00:00',
            '2021-10-13T00:00:00',
            '2021-10-14T00:00:00',
            '2021-10-15T00:00:00',
            '2021-10-18T00:00:00',
            '2021-10-19T00:00:00',
            '2021-10-20T00:00:00',
            '2021-10-21T00:00:00',
            '2021-10-22T00:00:00',
            '2021-10-25T00:00:00',
            '2021-10-26T00:00:00',
            '2021-10-27T00:00:00',
            '2021-10-28T00:00:00',
            '2021-10-29T00:00:00',
            '2021-11-01T00:00:00',
            '2021-11-02T00:00:00',
            '2021-11-03T00:00:00',
            '2021-11-04T00:00:00',
            '2021-11-05T00:00:00',
            '2021-11-08T00:00:00',
            '2021-11-09T00:00:00',
            '2021-11-10T00:00:00',
            '2021-11-11T00:00:00',
            '2021-11-12T00:00:00',
            '2021-11-15T00:00:00',
            '2021-11-16T00:00:00',
            '2021-11-17T00:00:00',
            '2021-11-18T00:00:00',
            '2021-11-19T00:00:00',
            '2021-11-22T00:00:00',
            '2021-11-23T00:00:00',
            '2021-11-24T00:00:00',
            '2021-11-25T00:00:00',
            '2021-11-26T00:00:00',
            '2021-11-29T00:00:00',
            '2021-11-30T00:00:00',
            '2021-12-01T00:00:00',
            '2021-12-02T00:00:00',
            '2021-12-03T00:00:00',
            '2021-12-06T00:00:00',
            '2021-12-07T00:00:00',
            '2021-12-08T00:00:00',
            '2021-12-09T00:00:00',
            '2021-12-10T00:00:00',
            '2021-12-13T00:00:00',
            '2021-12-14T00:00:00',
            '2021-12-15T00:00:00',
            '2021-12-16T00:00:00',
            '2021-12-17T00:00:00',
            '2021-12-20T00:00:00',
            '2021-12-21T00:00:00',
            '2021-12-22T00:00:00',
            '2021-12-23T00:00:00',
            '2021-12-24T00:00:00',
            '2021-12-27T00:00:00',
            '2021-12-28T00:00:00',
            '2021-12-29T00:00:00',
            '2021-12-30T00:00:00',
            '2021-12-31T00:00:00',
            '2022-01-04T00:00:00',
            '2022-01-05T00:00:00',
            '2022-01-06T00:00:00',
            '2022-01-07T00:00:00',
            '2022-01-10T00:00:00',
            '2022-01-11T00:00:00',
            '2022-01-12T00:00:00',
            '2022-01-13T00:00:00',
            '2022-01-14T00:00:00',
            '2022-01-17T00:00:00',
            '2022-01-18T00:00:00',
            '2022-01-19T00:00:00',
            '2022-01-20T00:00:00',
            '2022-01-21T00:00:00',
            '2022-01-24T00:00:00',
            '2022-01-25T00:00:00',
            '2022-01-26T00:00:00',
            '2022-01-27T00:00:00',
            '2022-01-28T00:00:00',
            '2022-02-07T00:00:00',
            '2022-02-08T00:00:00',
            '2022-02-09T00:00:00',
            '2022-02-10T00:00:00',
            '2022-02-11T00:00:00',
            '2022-02-14T00:00:00',
            '2022-02-15T00:00:00',
            '2022-02-16T00:00:00',
            '2022-02-17T00:00:00',
            '2022-02-18T00:00:00',
            '2022-02-21T00:00:00',
            '2022-02-22T00:00:00',
            '2022-02-23T00:00:00',
            '2022-02-24T00:00:00',
            '2022-02-25T00:00:00',
            '2022-02-28T00:00:00',
            '2022-03-01T00:00:00',
            '2022-03-02T00:00:00',
            '2022-03-03T00:00:00',
            '2022-03-04T00:00:00',
            '2022-03-07T00:00:00',
            '2022-03-08T00:00:00',
            '2022-03-09T00:00:00',
            '2022-03-10T00:00:00',
            '2022-03-11T00:00:00',
            '2022-03-14T00:00:00',
            '2022-03-15T00:00:00',
            '2022-03-16T00:00:00',
            '2022-03-17T00:00:00',
            '2022-03-18T00:00:00',
            '2022-03-21T00:00:00',
            '2022-03-22T00:00:00',
            '2022-03-23T00:00:00',
            '2022-03-24T00:00:00',
            '2022-03-25T00:00:00',
            '2022-03-28T00:00:00',
            '2022-03-29T00:00:00',
            '2022-03-30T00:00:00',
            '2022-03-31T00:00:00',
            '2022-04-01T00:00:00',
            '2022-04-06T00:00:00',
            '2022-04-07T00:00:00',
            '2022-04-08T00:00:00',
            '2022-04-11T00:00:00',
            '2022-04-12T00:00:00',
            '2022-04-13T00:00:00',
            '2022-04-14T00:00:00',
            '2022-04-15T00:00:00',
            '2022-04-18T00:00:00',
            '2022-04-19T00:00:00',
            '2022-04-20T00:00:00',
            '2022-04-21T00:00:00',
            '2022-04-22T00:00:00',
            '2022-04-25T00:00:00',
            '2022-04-26T00:00:00',
            '2022-04-27T00:00:00',
            '2022-04-28T00:00:00',
            '2022-04-29T00:00:00',
            '2022-05-05T00:00:00',
            '2022-05-06T00:00:00',
            '2022-05-09T00:00:00',
            '2022-05-10T00:00:00',
            '2022-05-11T00:00:00',
            '2022-05-12T00:00:00',
            '2022-05-13T00:00:00',
            '2022-05-16T00:00:00',
            '2022-05-17T00:00:00',
            '2022-05-18T00:00:00',
            '2022-05-19T00:00:00',
            '2022-05-20T00:00:00',
            '2022-05-23T00:00:00',
            '2022-05-24T00:00:00',
            '2022-05-25T00:00:00',
            '2022-05-26T00:00:00',
            '2022-05-27T00:00:00',
            '2022-05-30T00:00:00',
            '2022-05-31T00:00:00',
            '2022-06-01T00:00:00',
            '2022-06-02T00:00:00',
            '2022-06-06T00:00:00',
            '2022-06-07T00:00:00',
            '2022-06-08T00:00:00',
            '2022-06-09T00:00:00',
            '2022-06-10T00:00:00',
            '2022-06-13T00:00:00',
            '2022-06-14T00:00:00',
            '2022-06-15T00:00:00',
            '2022-06-16T00:00:00',
            '2022-06-17T00:00:00',
            '2022-06-20T00:00:00',
            '2022-06-21T00:00:00',
            '2022-06-22T00:00:00',
            '2022-06-23T00:00:00',
            '2022-06-24T00:00:00',
            '2022-06-27T00:00:00',
            '2022-06-28T00:00:00',
            '2022-06-29T00:00:00',
            '2022-06-30T00:00:00',
            '2022-07-01T00:00:00',
            '2022-07-04T00:00:00',
            '2022-07-05T00:00:00',
            '2022-07-06T00:00:00',
            '2022-07-07T00:00:00',
            '2022-07-08T00:00:00',
            '2022-07-11T00:00:00',
            '2022-07-12T00:00:00',
            '2022-07-13T00:00:00',
            '2022-07-14T00:00:00',
            '2022-07-15T00:00:00',
            '2022-07-18T00:00:00',
            '2022-07-19T00:00:00',
            '2022-07-20T00:00:00',
            '2022-07-21T00:00:00',
            '2022-07-22T00:00:00',
            '2022-07-25T00:00:00',
            '2022-07-26T00:00:00',
            '2022-07-27T00:00:00',
            '2022-07-28T00:00:00',
            '2022-07-29T00:00:00',
            '2022-08-01T00:00:00',
            '2022-08-02T00:00:00',
            '2022-08-03T00:00:00',
            '2022-08-04T00:00:00',
            '2022-08-05T00:00:00',
            '2022-08-08T00:00:00',
            '2022-08-09T00:00:00',
            '2022-08-10T00:00:00',
            '2022-08-11T00:00:00',
            '2022-08-12T00:00:00',
            '2022-08-15T00:00:00',
            '2022-08-16T00:00:00',
            '2022-08-17T00:00:00',
            '2022-08-18T00:00:00',
            '2022-08-19T00:00:00',
            '2022-08-22T00:00:00',
            '2022-08-23T00:00:00',
            '2022-08-24T00:00:00',
            '2022-08-25T00:00:00',
            '2022-08-26T00:00:00',
            '2022-08-29T00:00:00',
            '2022-08-30T00:00:00',
            '2022-08-31T00:00:00',
            '2022-09-01T00:00:00',
            '2022-09-02T00:00:00',
            '2022-09-05T00:00:00',
            '2022-09-06T00:00:00',
            '2022-09-07T00:00:00',
            '2022-09-08T00:00:00',
            '2022-09-09T00:00:00',
            '2022-09-13T00:00:00',
            '2022-09-14T00:00:00',
            '2022-09-15T00:00:00',
            '2022-09-16T00:00:00',
            '2022-09-19T00:00:00',
            '2022-09-20T00:00:00',
            '2022-09-21T00:00:00',
            '2022-09-22T00:00:00',
            '2022-09-23T00:00:00',
            '2022-09-26T00:00:00',
            '2022-09-27T00:00:00',
            '2022-09-28T00:00:00',
            '2022-09-29T00:00:00',
            '2022-09-30T00:00:00',
            '2022-10-10T00:00:00',
            '2022-10-11T00:00:00',
            '2022-10-12T00:00:00',
            '2022-10-13T00:00:00',
            '2022-10-14T00:00:00',
            '2022-10-17T00:00:00',
            '2022-10-18T00:00:00',
            '2022-10-19T00:00:00',
            '2022-10-20T00:00:00',
            '2022-10-21T00:00:00',
            '2022-10-24T00:00:00',
            '2022-10-25T00:00:00',
            '2022-10-26T00:00:00',
            '2022-10-27T00:00:00',
            '2022-10-28T00:00:00',
            '2022-10-31T00:00:00',
            '2022-11-01T00:00:00',
            '2022-11-02T00:00:00',
            '2022-11-03T00:00:00',
            '2022-11-04T00:00:00',
            '2022-11-07T00:00:00',
            '2022-11-08T00:00:00',
            '2022-11-09T00:00:00',
            '2022-11-10T00:00:00',
            '2022-11-11T00:00:00',
            '2022-11-14T00:00:00',
            '2022-11-15T00:00:00',
            '2022-11-16T00:00:00',
            '2022-11-17T00:00:00',
            '2022-11-18T00:00:00',
            '2022-11-21T00:00:00',
            '2022-11-22T00:00:00',
            '2022-11-23T00:00:00',
            '2022-11-24T00:00:00',
            '2022-11-25T00:00:00',
            '2022-11-28T00:00:00',
            '2022-11-29T00:00:00',
            '2022-11-30T00:00:00',
            '2022-12-01T00:00:00',
            '2022-12-02T00:00:00',
            '2022-12-05T00:00:00',
            '2022-12-06T00:00:00',
            '2022-12-07T00:00:00',
            '2022-12-08T00:00:00',
            '2022-12-09T00:00:00',
            '2022-12-12T00:00:00',
            '2022-12-13T00:00:00',
            '2022-12-14T00:00:00',
            '2022-12-15T00:00:00',
            '2022-12-16T00:00:00',
            '2022-12-19T00:00:00',
            '2022-12-20T00:00:00',
            '2022-12-21T00:00:00',
            '2022-12-22T00:00:00',
            '2022-12-23T00:00:00',
            '2022-12-26T00:00:00',
            '2022-12-27T00:00:00',
            '2022-12-28T00:00:00',
            '2022-12-29T00:00:00',
            '2022-12-30T00:00:00',
            '2023-01-03T00:00:00',
            '2023-01-04T00:00:00',
            '2023-01-05T00:00:00',
            '2023-01-06T00:00:00',
            '2023-01-09T00:00:00',
            '2023-01-10T00:00:00',
            '2023-01-11T00:00:00',
            '2023-01-12T00:00:00',
            '2023-01-13T00:00:00',
            '2023-01-16T00:00:00',
            '2023-01-17T00:00:00',
            '2023-01-18T00:00:00',
            '2023-01-19T00:00:00',
            '2023-01-20T00:00:00',
            '2023-01-30T00:00:00',
            '2023-01-31T00:00:00',
            '2023-02-01T00:00:00',
            '2023-02-02T00:00:00',
            '2023-02-03T00:00:00',
            '2023-02-06T00:00:00',
            '2023-02-07T00:00:00',
            '2023-02-08T00:00:00',
            '2023-02-09T00:00:00',
            '2023-02-10T00:00:00',
            '2023-02-13T00:00:00',
            '2023-02-14T00:00:00',
            '2023-02-15T00:00:00',
            '2023-02-16T00:00:00',
            '2023-02-17T00:00:00',
            '2023-02-20T00:00:00',
            '2023-02-21T00:00:00',
            '2023-02-22T00:00:00',
            '2023-02-23T00:00:00',
            '2023-02-24T00:00:00',
            '2023-02-27T00:00:00',
            '2023-02-28T00:00:00',
            '2023-03-01T00:00:00',
            '2023-03-02T00:00:00',
            '2023-03-03T00:00:00',
            '2023-03-06T00:00:00',
            '2023-03-07T00:00:00',
            '2023-03-08T00:00:00',
            '2023-03-09T00:00:00',
            '2023-03-10T00:00:00',
            '2023-03-13T00:00:00',
            '2023-03-14T00:00:00',
            '2023-03-15T00:00:00',
            '2023-03-16T00:00:00',
            '2023-03-17T00:00:00',
            '2023-03-20T00:00:00',
            '2023-03-21T00:00:00',
            '2023-03-22T00:00:00',
            '2023-03-23T00:00:00',
            '2023-03-24T00:00:00',
            '2023-03-27T00:00:00',
            '2023-03-28T00:00:00',
            '2023-03-29T00:00:00',
            '2023-03-30T00:00:00',
            '2023-03-31T00:00:00',
            '2023-04-03T00:00:00',
            '2023-04-04T00:00:00',
            '2023-04-06T00:00:00',
            '2023-04-07T00:00:00',
            '2023-04-10T00:00:00',
            '2023-04-11T00:00:00',
            '2023-04-12T00:00:00',
            '2023-04-13T00:00:00',
            '2023-04-14T00:00:00',
            '2023-04-17T00:00:00',
            '2023-04-18T00:00:00',
            '2023-04-19T00:00:00',
            '2023-04-20T00:00:00',
            '2023-04-21T00:00:00',
            '2023-04-24T00:00:00',
            '2023-04-25T00:00:00',
            '2023-04-26T00:00:00',
            '2023-04-27T00:00:00',
            '2023-04-28T00:00:00',
            '2023-05-04T00:00:00',
            '2023-05-05T00:00:00',
            '2023-05-08T00:00:00',
            '2023-05-09T00:00:00',
            '2023-05-10T00:00:00',
            '2023-05-11T00:00:00',
            '2023-05-12T00:00:00',
            '2023-05-15T00:00:00',
            '2023-05-16T00:00:00',
            '2023-05-17T00:00:00',
            '2023-05-18T00:00:00',
            '2023-05-19T00:00:00',
            '2023-05-22T00:00:00',
            '2023-05-23T00:00:00',
            '2023-05-24T00:00:00',
            '2023-05-25T00:00:00',
            '2023-05-26T00:00:00',
            '2023-05-29T00:00:00',
            '2023-05-30T00:00:00',
            '2023-05-31T00:00:00',
            '2023-06-01T00:00:00',
            '2023-06-02T00:00:00',
            '2023-06-05T00:00:00',
            '2023-06-06T00:00:00',
            '2023-06-07T00:00:00',
            '2023-06-08T00:00:00',
            '2023-06-09T00:00:00',
            '2023-06-12T00:00:00',
            '2023-06-13T00:00:00',
            '2023-06-14T00:00:00',
            '2023-06-15T00:00:00',
            '2023-06-16T00:00:00',
            '2023-06-19T00:00:00',
            '2023-06-20T00:00:00',
            '2023-06-21T00:00:00',
            '2023-06-26T00:00:00',
            '2023-06-27T00:00:00',
            '2023-06-28T00:00:00',
            '2023-06-29T00:00:00',
            '2023-06-30T00:00:00',
            '2023-07-03T00:00:00',
            '2023-07-04T00:00:00',
            '2023-07-05T00:00:00',
            '2023-07-06T00:00:00',
            '2023-07-07T00:00:00',
            '2023-07-10T00:00:00',
            '2023-07-11T00:00:00',
            '2023-07-12T00:00:00',
            '2023-07-13T00:00:00',
            '2023-07-14T00:00:00',
            '2023-07-17T00:00:00',
            '2023-07-18T00:00:00',
            '2023-07-19T00:00:00',
            '2023-07-20T00:00:00',
            '2023-07-21T00:00:00',
            '2023-07-24T00:00:00',
            '2023-07-25T00:00:00',
            '2023-07-26T00:00:00',
            '2023-07-27T00:00:00',
            '2023-07-28T00:00:00',
            '2023-07-31T00:00:00',
            '2023-08-01T00:00:00',
            '2023-08-02T00:00:00',
            '2023-08-03T00:00:00',
            '2023-08-04T00:00:00',
            '2023-08-07T00:00:00',
            '2023-08-08T00:00:00',
            '2023-08-09T00:00:00',
            '2023-08-10T00:00:00',
            '2023-08-11T00:00:00',
            '2023-08-14T00:00:00',
            '2023-08-15T00:00:00',
            '2023-08-16T00:00:00',
            '2023-08-17T00:00:00',
            '2023-08-18T00:00:00',
            '2023-08-21T00:00:00',
            '2023-08-22T00:00:00',
            '2023-08-23T00:00:00',
            '2023-08-24T00:00:00',
            '2023-08-25T00:00:00',
            '2023-08-28T00:00:00',
            '2023-08-29T00:00:00',
            '2023-08-30T00:00:00',
            '2023-08-31T00:00:00',
            '2023-09-01T00:00:00',
            '2023-09-04T00:00:00',
            '2023-09-05T00:00:00',
          ],
        },
        {
          type: 'category',
          show: true,
          scale: false,
          nameLocation: 'end',
          nameGap: 15,
          gridIndex: 1,
          axisLabel: {
            show: false,
            position: 'top',
            margin: 8,
          },
          inverse: false,
          offset: 0,
          splitNumber: 5,
          minInterval: 0,
          splitLine: {
            show: false,
            lineStyle: {
              show: true,
              width: 1,
              opacity: 1,
              curveness: 0,
              type: 'solid',
            },
          },
          data: [
            '2019-09-09T00:00:00',
            '2019-09-10T00:00:00',
            '2019-09-11T00:00:00',
            '2019-09-12T00:00:00',
            '2019-09-16T00:00:00',
            '2019-09-17T00:00:00',
            '2019-09-18T00:00:00',
            '2019-09-19T00:00:00',
            '2019-09-20T00:00:00',
            '2019-09-23T00:00:00',
            '2019-09-24T00:00:00',
            '2019-09-25T00:00:00',
            '2019-09-26T00:00:00',
            '2019-09-27T00:00:00',
            '2019-09-30T00:00:00',
            '2019-10-08T00:00:00',
            '2019-10-09T00:00:00',
            '2019-10-10T00:00:00',
            '2019-10-11T00:00:00',
            '2019-10-14T00:00:00',
            '2019-10-15T00:00:00',
            '2019-10-16T00:00:00',
            '2019-10-17T00:00:00',
            '2019-10-18T00:00:00',
            '2019-10-21T00:00:00',
            '2019-10-22T00:00:00',
            '2019-10-23T00:00:00',
            '2019-10-24T00:00:00',
            '2019-10-25T00:00:00',
            '2019-10-28T00:00:00',
            '2019-10-29T00:00:00',
            '2019-10-30T00:00:00',
            '2019-10-31T00:00:00',
            '2019-11-01T00:00:00',
            '2019-11-04T00:00:00',
            '2019-11-05T00:00:00',
            '2019-11-06T00:00:00',
            '2019-11-07T00:00:00',
            '2019-11-08T00:00:00',
            '2019-11-11T00:00:00',
            '2019-11-12T00:00:00',
            '2019-11-13T00:00:00',
            '2019-11-14T00:00:00',
            '2019-11-15T00:00:00',
            '2019-11-18T00:00:00',
            '2019-11-19T00:00:00',
            '2019-11-20T00:00:00',
            '2019-11-21T00:00:00',
            '2019-11-22T00:00:00',
            '2019-11-25T00:00:00',
            '2019-11-26T00:00:00',
            '2019-11-27T00:00:00',
            '2019-11-28T00:00:00',
            '2019-11-29T00:00:00',
            '2019-12-02T00:00:00',
            '2019-12-03T00:00:00',
            '2019-12-04T00:00:00',
            '2019-12-05T00:00:00',
            '2019-12-06T00:00:00',
            '2019-12-09T00:00:00',
            '2019-12-10T00:00:00',
            '2019-12-11T00:00:00',
            '2019-12-12T00:00:00',
            '2019-12-13T00:00:00',
            '2019-12-16T00:00:00',
            '2019-12-17T00:00:00',
            '2019-12-18T00:00:00',
            '2019-12-19T00:00:00',
            '2019-12-20T00:00:00',
            '2019-12-23T00:00:00',
            '2019-12-24T00:00:00',
            '2019-12-25T00:00:00',
            '2019-12-26T00:00:00',
            '2019-12-27T00:00:00',
            '2019-12-30T00:00:00',
            '2019-12-31T00:00:00',
            '2020-01-02T00:00:00',
            '2020-01-03T00:00:00',
            '2020-01-06T00:00:00',
            '2020-01-07T00:00:00',
            '2020-01-08T00:00:00',
            '2020-01-09T00:00:00',
            '2020-01-10T00:00:00',
            '2020-01-13T00:00:00',
            '2020-01-14T00:00:00',
            '2020-01-15T00:00:00',
            '2020-01-16T00:00:00',
            '2020-01-17T00:00:00',
            '2020-01-20T00:00:00',
            '2020-01-21T00:00:00',
            '2020-01-22T00:00:00',
            '2020-01-23T00:00:00',
            '2020-02-03T00:00:00',
            '2020-02-04T00:00:00',
            '2020-02-05T00:00:00',
            '2020-02-06T00:00:00',
            '2020-02-07T00:00:00',
            '2020-02-10T00:00:00',
            '2020-02-11T00:00:00',
            '2020-02-12T00:00:00',
            '2020-02-13T00:00:00',
            '2020-02-14T00:00:00',
            '2020-02-17T00:00:00',
            '2020-02-18T00:00:00',
            '2020-02-19T00:00:00',
            '2020-02-20T00:00:00',
            '2020-02-21T00:00:00',
            '2020-02-24T00:00:00',
            '2020-02-25T00:00:00',
            '2020-02-26T00:00:00',
            '2020-02-27T00:00:00',
            '2020-02-28T00:00:00',
            '2020-03-02T00:00:00',
            '2020-03-03T00:00:00',
            '2020-03-04T00:00:00',
            '2020-03-05T00:00:00',
            '2020-03-06T00:00:00',
            '2020-03-09T00:00:00',
            '2020-03-10T00:00:00',
            '2020-03-11T00:00:00',
            '2020-03-12T00:00:00',
            '2020-03-13T00:00:00',
            '2020-03-16T00:00:00',
            '2020-03-17T00:00:00',
            '2020-03-18T00:00:00',
            '2020-03-19T00:00:00',
            '2020-03-20T00:00:00',
            '2020-03-23T00:00:00',
            '2020-03-24T00:00:00',
            '2020-03-25T00:00:00',
            '2020-03-26T00:00:00',
            '2020-03-27T00:00:00',
            '2020-03-30T00:00:00',
            '2020-03-31T00:00:00',
            '2020-04-01T00:00:00',
            '2020-04-02T00:00:00',
            '2020-04-03T00:00:00',
            '2020-04-07T00:00:00',
            '2020-04-08T00:00:00',
            '2020-04-09T00:00:00',
            '2020-04-10T00:00:00',
            '2020-04-13T00:00:00',
            '2020-04-14T00:00:00',
            '2020-04-15T00:00:00',
            '2020-04-16T00:00:00',
            '2020-04-17T00:00:00',
            '2020-04-20T00:00:00',
            '2020-04-21T00:00:00',
            '2020-04-22T00:00:00',
            '2020-04-23T00:00:00',
            '2020-04-24T00:00:00',
            '2020-04-27T00:00:00',
            '2020-04-28T00:00:00',
            '2020-04-29T00:00:00',
            '2020-04-30T00:00:00',
            '2020-05-06T00:00:00',
            '2020-05-07T00:00:00',
            '2020-05-08T00:00:00',
            '2020-05-11T00:00:00',
            '2020-05-12T00:00:00',
            '2020-05-13T00:00:00',
            '2020-05-14T00:00:00',
            '2020-05-15T00:00:00',
            '2020-05-18T00:00:00',
            '2020-05-19T00:00:00',
            '2020-05-20T00:00:00',
            '2020-05-21T00:00:00',
            '2020-05-22T00:00:00',
            '2020-05-25T00:00:00',
            '2020-05-26T00:00:00',
            '2020-05-27T00:00:00',
            '2020-05-28T00:00:00',
            '2020-05-29T00:00:00',
            '2020-06-01T00:00:00',
            '2020-06-02T00:00:00',
            '2020-06-03T00:00:00',
            '2020-06-04T00:00:00',
            '2020-06-05T00:00:00',
            '2020-06-08T00:00:00',
            '2020-06-09T00:00:00',
            '2020-06-10T00:00:00',
            '2020-06-11T00:00:00',
            '2020-06-12T00:00:00',
            '2020-06-15T00:00:00',
            '2020-06-16T00:00:00',
            '2020-06-17T00:00:00',
            '2020-06-18T00:00:00',
            '2020-06-19T00:00:00',
            '2020-06-22T00:00:00',
            '2020-06-23T00:00:00',
            '2020-06-24T00:00:00',
            '2020-06-29T00:00:00',
            '2020-06-30T00:00:00',
            '2020-07-01T00:00:00',
            '2020-07-02T00:00:00',
            '2020-07-03T00:00:00',
            '2020-07-06T00:00:00',
            '2020-07-07T00:00:00',
            '2020-07-08T00:00:00',
            '2020-07-09T00:00:00',
            '2020-07-10T00:00:00',
            '2020-07-13T00:00:00',
            '2020-07-14T00:00:00',
            '2020-07-15T00:00:00',
            '2020-07-16T00:00:00',
            '2020-07-17T00:00:00',
            '2020-07-20T00:00:00',
            '2020-07-21T00:00:00',
            '2020-07-22T00:00:00',
            '2020-07-23T00:00:00',
            '2020-07-24T00:00:00',
            '2020-07-27T00:00:00',
            '2020-07-28T00:00:00',
            '2020-07-29T00:00:00',
            '2020-07-30T00:00:00',
            '2020-07-31T00:00:00',
            '2020-08-03T00:00:00',
            '2020-08-04T00:00:00',
            '2020-08-05T00:00:00',
            '2020-08-06T00:00:00',
            '2020-08-07T00:00:00',
            '2020-08-10T00:00:00',
            '2020-08-11T00:00:00',
            '2020-08-12T00:00:00',
            '2020-08-13T00:00:00',
            '2020-08-14T00:00:00',
            '2020-08-17T00:00:00',
            '2020-08-18T00:00:00',
            '2020-08-19T00:00:00',
            '2020-08-20T00:00:00',
            '2020-08-21T00:00:00',
            '2020-08-24T00:00:00',
            '2020-08-25T00:00:00',
            '2020-08-26T00:00:00',
            '2020-08-27T00:00:00',
            '2020-08-28T00:00:00',
            '2020-08-31T00:00:00',
            '2020-09-01T00:00:00',
            '2020-09-02T00:00:00',
            '2020-09-03T00:00:00',
            '2020-09-04T00:00:00',
            '2020-09-07T00:00:00',
            '2020-09-08T00:00:00',
            '2020-09-09T00:00:00',
            '2020-09-10T00:00:00',
            '2020-09-11T00:00:00',
            '2020-09-14T00:00:00',
            '2020-09-15T00:00:00',
            '2020-09-16T00:00:00',
            '2020-09-17T00:00:00',
            '2020-09-18T00:00:00',
            '2020-09-21T00:00:00',
            '2020-09-22T00:00:00',
            '2020-09-23T00:00:00',
            '2020-09-24T00:00:00',
            '2020-09-25T00:00:00',
            '2020-09-28T00:00:00',
            '2020-09-29T00:00:00',
            '2020-09-30T00:00:00',
            '2020-10-09T00:00:00',
            '2020-10-12T00:00:00',
            '2020-10-13T00:00:00',
            '2020-10-14T00:00:00',
            '2020-10-15T00:00:00',
            '2020-10-16T00:00:00',
            '2020-10-19T00:00:00',
            '2020-10-20T00:00:00',
            '2020-10-21T00:00:00',
            '2020-10-22T00:00:00',
            '2020-10-23T00:00:00',
            '2020-10-26T00:00:00',
            '2020-10-27T00:00:00',
            '2020-10-28T00:00:00',
            '2020-10-29T00:00:00',
            '2020-10-30T00:00:00',
            '2020-11-02T00:00:00',
            '2020-11-03T00:00:00',
            '2020-11-04T00:00:00',
            '2020-11-05T00:00:00',
            '2020-11-06T00:00:00',
            '2020-11-09T00:00:00',
            '2020-11-10T00:00:00',
            '2020-11-11T00:00:00',
            '2020-11-12T00:00:00',
            '2020-11-13T00:00:00',
            '2020-11-16T00:00:00',
            '2020-11-17T00:00:00',
            '2020-11-18T00:00:00',
            '2020-11-19T00:00:00',
            '2020-11-20T00:00:00',
            '2020-11-23T00:00:00',
            '2020-11-24T00:00:00',
            '2020-11-25T00:00:00',
            '2020-11-26T00:00:00',
            '2020-11-27T00:00:00',
            '2020-11-30T00:00:00',
            '2020-12-01T00:00:00',
            '2020-12-02T00:00:00',
            '2020-12-03T00:00:00',
            '2020-12-04T00:00:00',
            '2020-12-07T00:00:00',
            '2020-12-08T00:00:00',
            '2020-12-09T00:00:00',
            '2020-12-10T00:00:00',
            '2020-12-11T00:00:00',
            '2020-12-14T00:00:00',
            '2020-12-15T00:00:00',
            '2020-12-16T00:00:00',
            '2020-12-17T00:00:00',
            '2020-12-18T00:00:00',
            '2020-12-21T00:00:00',
            '2020-12-22T00:00:00',
            '2020-12-23T00:00:00',
            '2020-12-24T00:00:00',
            '2020-12-25T00:00:00',
            '2020-12-28T00:00:00',
            '2020-12-29T00:00:00',
            '2020-12-30T00:00:00',
            '2020-12-31T00:00:00',
            '2021-01-04T00:00:00',
            '2021-01-05T00:00:00',
            '2021-01-06T00:00:00',
            '2021-01-07T00:00:00',
            '2021-01-08T00:00:00',
            '2021-01-11T00:00:00',
            '2021-01-12T00:00:00',
            '2021-01-13T00:00:00',
            '2021-01-14T00:00:00',
            '2021-01-15T00:00:00',
            '2021-01-18T00:00:00',
            '2021-01-19T00:00:00',
            '2021-01-20T00:00:00',
            '2021-01-21T00:00:00',
            '2021-01-22T00:00:00',
            '2021-01-25T00:00:00',
            '2021-01-26T00:00:00',
            '2021-01-27T00:00:00',
            '2021-01-28T00:00:00',
            '2021-01-29T00:00:00',
            '2021-02-01T00:00:00',
            '2021-02-02T00:00:00',
            '2021-02-03T00:00:00',
            '2021-02-04T00:00:00',
            '2021-02-05T00:00:00',
            '2021-02-08T00:00:00',
            '2021-02-09T00:00:00',
            '2021-02-10T00:00:00',
            '2021-02-18T00:00:00',
            '2021-02-19T00:00:00',
            '2021-02-22T00:00:00',
            '2021-02-23T00:00:00',
            '2021-02-24T00:00:00',
            '2021-02-25T00:00:00',
            '2021-02-26T00:00:00',
            '2021-03-01T00:00:00',
            '2021-03-02T00:00:00',
            '2021-03-03T00:00:00',
            '2021-03-04T00:00:00',
            '2021-03-05T00:00:00',
            '2021-03-08T00:00:00',
            '2021-03-09T00:00:00',
            '2021-03-10T00:00:00',
            '2021-03-11T00:00:00',
            '2021-03-12T00:00:00',
            '2021-03-15T00:00:00',
            '2021-03-16T00:00:00',
            '2021-03-17T00:00:00',
            '2021-03-18T00:00:00',
            '2021-03-19T00:00:00',
            '2021-03-22T00:00:00',
            '2021-03-23T00:00:00',
            '2021-03-24T00:00:00',
            '2021-03-25T00:00:00',
            '2021-03-26T00:00:00',
            '2021-03-29T00:00:00',
            '2021-03-30T00:00:00',
            '2021-03-31T00:00:00',
            '2021-04-01T00:00:00',
            '2021-04-02T00:00:00',
            '2021-04-06T00:00:00',
            '2021-04-07T00:00:00',
            '2021-04-08T00:00:00',
            '2021-04-09T00:00:00',
            '2021-04-12T00:00:00',
            '2021-04-13T00:00:00',
            '2021-04-14T00:00:00',
            '2021-04-15T00:00:00',
            '2021-04-16T00:00:00',
            '2021-04-19T00:00:00',
            '2021-04-20T00:00:00',
            '2021-04-21T00:00:00',
            '2021-04-22T00:00:00',
            '2021-04-23T00:00:00',
            '2021-04-26T00:00:00',
            '2021-04-27T00:00:00',
            '2021-04-28T00:00:00',
            '2021-04-29T00:00:00',
            '2021-04-30T00:00:00',
            '2021-05-06T00:00:00',
            '2021-05-07T00:00:00',
            '2021-05-10T00:00:00',
            '2021-05-11T00:00:00',
            '2021-05-12T00:00:00',
            '2021-05-13T00:00:00',
            '2021-05-14T00:00:00',
            '2021-05-17T00:00:00',
            '2021-05-18T00:00:00',
            '2021-05-19T00:00:00',
            '2021-05-20T00:00:00',
            '2021-05-21T00:00:00',
            '2021-05-24T00:00:00',
            '2021-05-25T00:00:00',
            '2021-05-26T00:00:00',
            '2021-05-27T00:00:00',
            '2021-05-28T00:00:00',
            '2021-05-31T00:00:00',
            '2021-06-01T00:00:00',
            '2021-06-02T00:00:00',
            '2021-06-03T00:00:00',
            '2021-06-04T00:00:00',
            '2021-06-07T00:00:00',
            '2021-06-08T00:00:00',
            '2021-06-09T00:00:00',
            '2021-06-10T00:00:00',
            '2021-06-11T00:00:00',
            '2021-06-15T00:00:00',
            '2021-06-16T00:00:00',
            '2021-06-17T00:00:00',
            '2021-06-18T00:00:00',
            '2021-06-21T00:00:00',
            '2021-06-22T00:00:00',
            '2021-06-23T00:00:00',
            '2021-06-24T00:00:00',
            '2021-06-25T00:00:00',
            '2021-06-28T00:00:00',
            '2021-06-29T00:00:00',
            '2021-06-30T00:00:00',
            '2021-07-01T00:00:00',
            '2021-07-02T00:00:00',
            '2021-07-05T00:00:00',
            '2021-07-06T00:00:00',
            '2021-07-07T00:00:00',
            '2021-07-08T00:00:00',
            '2021-07-09T00:00:00',
            '2021-07-12T00:00:00',
            '2021-07-13T00:00:00',
            '2021-07-14T00:00:00',
            '2021-07-15T00:00:00',
            '2021-07-16T00:00:00',
            '2021-07-19T00:00:00',
            '2021-07-20T00:00:00',
            '2021-07-21T00:00:00',
            '2021-07-22T00:00:00',
            '2021-07-23T00:00:00',
            '2021-07-26T00:00:00',
            '2021-07-27T00:00:00',
            '2021-07-28T00:00:00',
            '2021-07-29T00:00:00',
            '2021-07-30T00:00:00',
            '2021-08-02T00:00:00',
            '2021-08-03T00:00:00',
            '2021-08-04T00:00:00',
            '2021-08-05T00:00:00',
            '2021-08-06T00:00:00',
            '2021-08-09T00:00:00',
            '2021-08-10T00:00:00',
            '2021-08-11T00:00:00',
            '2021-08-12T00:00:00',
            '2021-08-13T00:00:00',
            '2021-08-16T00:00:00',
            '2021-08-17T00:00:00',
            '2021-08-18T00:00:00',
            '2021-08-19T00:00:00',
            '2021-08-20T00:00:00',
            '2021-08-23T00:00:00',
            '2021-08-24T00:00:00',
            '2021-08-25T00:00:00',
            '2021-08-26T00:00:00',
            '2021-08-27T00:00:00',
            '2021-08-30T00:00:00',
            '2021-08-31T00:00:00',
            '2021-09-01T00:00:00',
            '2021-09-02T00:00:00',
            '2021-09-03T00:00:00',
            '2021-09-06T00:00:00',
            '2021-09-07T00:00:00',
            '2021-09-08T00:00:00',
            '2021-09-09T00:00:00',
            '2021-09-10T00:00:00',
            '2021-09-13T00:00:00',
            '2021-09-14T00:00:00',
            '2021-09-15T00:00:00',
            '2021-09-16T00:00:00',
            '2021-09-17T00:00:00',
            '2021-09-22T00:00:00',
            '2021-09-23T00:00:00',
            '2021-09-24T00:00:00',
            '2021-09-27T00:00:00',
            '2021-09-28T00:00:00',
            '2021-09-29T00:00:00',
            '2021-09-30T00:00:00',
            '2021-10-08T00:00:00',
            '2021-10-11T00:00:00',
            '2021-10-12T00:00:00',
            '2021-10-13T00:00:00',
            '2021-10-14T00:00:00',
            '2021-10-15T00:00:00',
            '2021-10-18T00:00:00',
            '2021-10-19T00:00:00',
            '2021-10-20T00:00:00',
            '2021-10-21T00:00:00',
            '2021-10-22T00:00:00',
            '2021-10-25T00:00:00',
            '2021-10-26T00:00:00',
            '2021-10-27T00:00:00',
            '2021-10-28T00:00:00',
            '2021-10-29T00:00:00',
            '2021-11-01T00:00:00',
            '2021-11-02T00:00:00',
            '2021-11-03T00:00:00',
            '2021-11-04T00:00:00',
            '2021-11-05T00:00:00',
            '2021-11-08T00:00:00',
            '2021-11-09T00:00:00',
            '2021-11-10T00:00:00',
            '2021-11-11T00:00:00',
            '2021-11-12T00:00:00',
            '2021-11-15T00:00:00',
            '2021-11-16T00:00:00',
            '2021-11-17T00:00:00',
            '2021-11-18T00:00:00',
            '2021-11-19T00:00:00',
            '2021-11-22T00:00:00',
            '2021-11-23T00:00:00',
            '2021-11-24T00:00:00',
            '2021-11-25T00:00:00',
            '2021-11-26T00:00:00',
            '2021-11-29T00:00:00',
            '2021-11-30T00:00:00',
            '2021-12-01T00:00:00',
            '2021-12-02T00:00:00',
            '2021-12-03T00:00:00',
            '2021-12-06T00:00:00',
            '2021-12-07T00:00:00',
            '2021-12-08T00:00:00',
            '2021-12-09T00:00:00',
            '2021-12-10T00:00:00',
            '2021-12-13T00:00:00',
            '2021-12-14T00:00:00',
            '2021-12-15T00:00:00',
            '2021-12-16T00:00:00',
            '2021-12-17T00:00:00',
            '2021-12-20T00:00:00',
            '2021-12-21T00:00:00',
            '2021-12-22T00:00:00',
            '2021-12-23T00:00:00',
            '2021-12-24T00:00:00',
            '2021-12-27T00:00:00',
            '2021-12-28T00:00:00',
            '2021-12-29T00:00:00',
            '2021-12-30T00:00:00',
            '2021-12-31T00:00:00',
            '2022-01-04T00:00:00',
            '2022-01-05T00:00:00',
            '2022-01-06T00:00:00',
            '2022-01-07T00:00:00',
            '2022-01-10T00:00:00',
            '2022-01-11T00:00:00',
            '2022-01-12T00:00:00',
            '2022-01-13T00:00:00',
            '2022-01-14T00:00:00',
            '2022-01-17T00:00:00',
            '2022-01-18T00:00:00',
            '2022-01-19T00:00:00',
            '2022-01-20T00:00:00',
            '2022-01-21T00:00:00',
            '2022-01-24T00:00:00',
            '2022-01-25T00:00:00',
            '2022-01-26T00:00:00',
            '2022-01-27T00:00:00',
            '2022-01-28T00:00:00',
            '2022-02-07T00:00:00',
            '2022-02-08T00:00:00',
            '2022-02-09T00:00:00',
            '2022-02-10T00:00:00',
            '2022-02-11T00:00:00',
            '2022-02-14T00:00:00',
            '2022-02-15T00:00:00',
            '2022-02-16T00:00:00',
            '2022-02-17T00:00:00',
            '2022-02-18T00:00:00',
            '2022-02-21T00:00:00',
            '2022-02-22T00:00:00',
            '2022-02-23T00:00:00',
            '2022-02-24T00:00:00',
            '2022-02-25T00:00:00',
            '2022-02-28T00:00:00',
            '2022-03-01T00:00:00',
            '2022-03-02T00:00:00',
            '2022-03-03T00:00:00',
            '2022-03-04T00:00:00',
            '2022-03-07T00:00:00',
            '2022-03-08T00:00:00',
            '2022-03-09T00:00:00',
            '2022-03-10T00:00:00',
            '2022-03-11T00:00:00',
            '2022-03-14T00:00:00',
            '2022-03-15T00:00:00',
            '2022-03-16T00:00:00',
            '2022-03-17T00:00:00',
            '2022-03-18T00:00:00',
            '2022-03-21T00:00:00',
            '2022-03-22T00:00:00',
            '2022-03-23T00:00:00',
            '2022-03-24T00:00:00',
            '2022-03-25T00:00:00',
            '2022-03-28T00:00:00',
            '2022-03-29T00:00:00',
            '2022-03-30T00:00:00',
            '2022-03-31T00:00:00',
            '2022-04-01T00:00:00',
            '2022-04-06T00:00:00',
            '2022-04-07T00:00:00',
            '2022-04-08T00:00:00',
            '2022-04-11T00:00:00',
            '2022-04-12T00:00:00',
            '2022-04-13T00:00:00',
            '2022-04-14T00:00:00',
            '2022-04-15T00:00:00',
            '2022-04-18T00:00:00',
            '2022-04-19T00:00:00',
            '2022-04-20T00:00:00',
            '2022-04-21T00:00:00',
            '2022-04-22T00:00:00',
            '2022-04-25T00:00:00',
            '2022-04-26T00:00:00',
            '2022-04-27T00:00:00',
            '2022-04-28T00:00:00',
            '2022-04-29T00:00:00',
            '2022-05-05T00:00:00',
            '2022-05-06T00:00:00',
            '2022-05-09T00:00:00',
            '2022-05-10T00:00:00',
            '2022-05-11T00:00:00',
            '2022-05-12T00:00:00',
            '2022-05-13T00:00:00',
            '2022-05-16T00:00:00',
            '2022-05-17T00:00:00',
            '2022-05-18T00:00:00',
            '2022-05-19T00:00:00',
            '2022-05-20T00:00:00',
            '2022-05-23T00:00:00',
            '2022-05-24T00:00:00',
            '2022-05-25T00:00:00',
            '2022-05-26T00:00:00',
            '2022-05-27T00:00:00',
            '2022-05-30T00:00:00',
            '2022-05-31T00:00:00',
            '2022-06-01T00:00:00',
            '2022-06-02T00:00:00',
            '2022-06-06T00:00:00',
            '2022-06-07T00:00:00',
            '2022-06-08T00:00:00',
            '2022-06-09T00:00:00',
            '2022-06-10T00:00:00',
            '2022-06-13T00:00:00',
            '2022-06-14T00:00:00',
            '2022-06-15T00:00:00',
            '2022-06-16T00:00:00',
            '2022-06-17T00:00:00',
            '2022-06-20T00:00:00',
            '2022-06-21T00:00:00',
            '2022-06-22T00:00:00',
            '2022-06-23T00:00:00',
            '2022-06-24T00:00:00',
            '2022-06-27T00:00:00',
            '2022-06-28T00:00:00',
            '2022-06-29T00:00:00',
            '2022-06-30T00:00:00',
            '2022-07-01T00:00:00',
            '2022-07-04T00:00:00',
            '2022-07-05T00:00:00',
            '2022-07-06T00:00:00',
            '2022-07-07T00:00:00',
            '2022-07-08T00:00:00',
            '2022-07-11T00:00:00',
            '2022-07-12T00:00:00',
            '2022-07-13T00:00:00',
            '2022-07-14T00:00:00',
            '2022-07-15T00:00:00',
            '2022-07-18T00:00:00',
            '2022-07-19T00:00:00',
            '2022-07-20T00:00:00',
            '2022-07-21T00:00:00',
            '2022-07-22T00:00:00',
            '2022-07-25T00:00:00',
            '2022-07-26T00:00:00',
            '2022-07-27T00:00:00',
            '2022-07-28T00:00:00',
            '2022-07-29T00:00:00',
            '2022-08-01T00:00:00',
            '2022-08-02T00:00:00',
            '2022-08-03T00:00:00',
            '2022-08-04T00:00:00',
            '2022-08-05T00:00:00',
            '2022-08-08T00:00:00',
            '2022-08-09T00:00:00',
            '2022-08-10T00:00:00',
            '2022-08-11T00:00:00',
            '2022-08-12T00:00:00',
            '2022-08-15T00:00:00',
            '2022-08-16T00:00:00',
            '2022-08-17T00:00:00',
            '2022-08-18T00:00:00',
            '2022-08-19T00:00:00',
            '2022-08-22T00:00:00',
            '2022-08-23T00:00:00',
            '2022-08-24T00:00:00',
            '2022-08-25T00:00:00',
            '2022-08-26T00:00:00',
            '2022-08-29T00:00:00',
            '2022-08-30T00:00:00',
            '2022-08-31T00:00:00',
            '2022-09-01T00:00:00',
            '2022-09-02T00:00:00',
            '2022-09-05T00:00:00',
            '2022-09-06T00:00:00',
            '2022-09-07T00:00:00',
            '2022-09-08T00:00:00',
            '2022-09-09T00:00:00',
            '2022-09-13T00:00:00',
            '2022-09-14T00:00:00',
            '2022-09-15T00:00:00',
            '2022-09-16T00:00:00',
            '2022-09-19T00:00:00',
            '2022-09-20T00:00:00',
            '2022-09-21T00:00:00',
            '2022-09-22T00:00:00',
            '2022-09-23T00:00:00',
            '2022-09-26T00:00:00',
            '2022-09-27T00:00:00',
            '2022-09-28T00:00:00',
            '2022-09-29T00:00:00',
            '2022-09-30T00:00:00',
            '2022-10-10T00:00:00',
            '2022-10-11T00:00:00',
            '2022-10-12T00:00:00',
            '2022-10-13T00:00:00',
            '2022-10-14T00:00:00',
            '2022-10-17T00:00:00',
            '2022-10-18T00:00:00',
            '2022-10-19T00:00:00',
            '2022-10-20T00:00:00',
            '2022-10-21T00:00:00',
            '2022-10-24T00:00:00',
            '2022-10-25T00:00:00',
            '2022-10-26T00:00:00',
            '2022-10-27T00:00:00',
            '2022-10-28T00:00:00',
            '2022-10-31T00:00:00',
            '2022-11-01T00:00:00',
            '2022-11-02T00:00:00',
            '2022-11-03T00:00:00',
            '2022-11-04T00:00:00',
            '2022-11-07T00:00:00',
            '2022-11-08T00:00:00',
            '2022-11-09T00:00:00',
            '2022-11-10T00:00:00',
            '2022-11-11T00:00:00',
            '2022-11-14T00:00:00',
            '2022-11-15T00:00:00',
            '2022-11-16T00:00:00',
            '2022-11-17T00:00:00',
            '2022-11-18T00:00:00',
            '2022-11-21T00:00:00',
            '2022-11-22T00:00:00',
            '2022-11-23T00:00:00',
            '2022-11-24T00:00:00',
            '2022-11-25T00:00:00',
            '2022-11-28T00:00:00',
            '2022-11-29T00:00:00',
            '2022-11-30T00:00:00',
            '2022-12-01T00:00:00',
            '2022-12-02T00:00:00',
            '2022-12-05T00:00:00',
            '2022-12-06T00:00:00',
            '2022-12-07T00:00:00',
            '2022-12-08T00:00:00',
            '2022-12-09T00:00:00',
            '2022-12-12T00:00:00',
            '2022-12-13T00:00:00',
            '2022-12-14T00:00:00',
            '2022-12-15T00:00:00',
            '2022-12-16T00:00:00',
            '2022-12-19T00:00:00',
            '2022-12-20T00:00:00',
            '2022-12-21T00:00:00',
            '2022-12-22T00:00:00',
            '2022-12-23T00:00:00',
            '2022-12-26T00:00:00',
            '2022-12-27T00:00:00',
            '2022-12-28T00:00:00',
            '2022-12-29T00:00:00',
            '2022-12-30T00:00:00',
            '2023-01-03T00:00:00',
            '2023-01-04T00:00:00',
            '2023-01-05T00:00:00',
            '2023-01-06T00:00:00',
            '2023-01-09T00:00:00',
            '2023-01-10T00:00:00',
            '2023-01-11T00:00:00',
            '2023-01-12T00:00:00',
            '2023-01-13T00:00:00',
            '2023-01-16T00:00:00',
            '2023-01-17T00:00:00',
            '2023-01-18T00:00:00',
            '2023-01-19T00:00:00',
            '2023-01-20T00:00:00',
            '2023-01-30T00:00:00',
            '2023-01-31T00:00:00',
            '2023-02-01T00:00:00',
            '2023-02-02T00:00:00',
            '2023-02-03T00:00:00',
            '2023-02-06T00:00:00',
            '2023-02-07T00:00:00',
            '2023-02-08T00:00:00',
            '2023-02-09T00:00:00',
            '2023-02-10T00:00:00',
            '2023-02-13T00:00:00',
            '2023-02-14T00:00:00',
            '2023-02-15T00:00:00',
            '2023-02-16T00:00:00',
            '2023-02-17T00:00:00',
            '2023-02-20T00:00:00',
            '2023-02-21T00:00:00',
            '2023-02-22T00:00:00',
            '2023-02-23T00:00:00',
            '2023-02-24T00:00:00',
            '2023-02-27T00:00:00',
            '2023-02-28T00:00:00',
            '2023-03-01T00:00:00',
            '2023-03-02T00:00:00',
            '2023-03-03T00:00:00',
            '2023-03-06T00:00:00',
            '2023-03-07T00:00:00',
            '2023-03-08T00:00:00',
            '2023-03-09T00:00:00',
            '2023-03-10T00:00:00',
            '2023-03-13T00:00:00',
            '2023-03-14T00:00:00',
            '2023-03-15T00:00:00',
            '2023-03-16T00:00:00',
            '2023-03-17T00:00:00',
            '2023-03-20T00:00:00',
            '2023-03-21T00:00:00',
            '2023-03-22T00:00:00',
            '2023-03-23T00:00:00',
            '2023-03-24T00:00:00',
            '2023-03-27T00:00:00',
            '2023-03-28T00:00:00',
            '2023-03-29T00:00:00',
            '2023-03-30T00:00:00',
            '2023-03-31T00:00:00',
            '2023-04-03T00:00:00',
            '2023-04-04T00:00:00',
            '2023-04-06T00:00:00',
            '2023-04-07T00:00:00',
            '2023-04-10T00:00:00',
            '2023-04-11T00:00:00',
            '2023-04-12T00:00:00',
            '2023-04-13T00:00:00',
            '2023-04-14T00:00:00',
            '2023-04-17T00:00:00',
            '2023-04-18T00:00:00',
            '2023-04-19T00:00:00',
            '2023-04-20T00:00:00',
            '2023-04-21T00:00:00',
            '2023-04-24T00:00:00',
            '2023-04-25T00:00:00',
            '2023-04-26T00:00:00',
            '2023-04-27T00:00:00',
            '2023-04-28T00:00:00',
            '2023-05-04T00:00:00',
            '2023-05-05T00:00:00',
            '2023-05-08T00:00:00',
            '2023-05-09T00:00:00',
            '2023-05-10T00:00:00',
            '2023-05-11T00:00:00',
            '2023-05-12T00:00:00',
            '2023-05-15T00:00:00',
            '2023-05-16T00:00:00',
            '2023-05-17T00:00:00',
            '2023-05-18T00:00:00',
            '2023-05-19T00:00:00',
            '2023-05-22T00:00:00',
            '2023-05-23T00:00:00',
            '2023-05-24T00:00:00',
            '2023-05-25T00:00:00',
            '2023-05-26T00:00:00',
            '2023-05-29T00:00:00',
            '2023-05-30T00:00:00',
            '2023-05-31T00:00:00',
            '2023-06-01T00:00:00',
            '2023-06-02T00:00:00',
            '2023-06-05T00:00:00',
            '2023-06-06T00:00:00',
            '2023-06-07T00:00:00',
            '2023-06-08T00:00:00',
            '2023-06-09T00:00:00',
            '2023-06-12T00:00:00',
            '2023-06-13T00:00:00',
            '2023-06-14T00:00:00',
            '2023-06-15T00:00:00',
            '2023-06-16T00:00:00',
            '2023-06-19T00:00:00',
            '2023-06-20T00:00:00',
            '2023-06-21T00:00:00',
            '2023-06-26T00:00:00',
            '2023-06-27T00:00:00',
            '2023-06-28T00:00:00',
            '2023-06-29T00:00:00',
            '2023-06-30T00:00:00',
            '2023-07-03T00:00:00',
            '2023-07-04T00:00:00',
            '2023-07-05T00:00:00',
            '2023-07-06T00:00:00',
            '2023-07-07T00:00:00',
            '2023-07-10T00:00:00',
            '2023-07-11T00:00:00',
            '2023-07-12T00:00:00',
            '2023-07-13T00:00:00',
            '2023-07-14T00:00:00',
            '2023-07-17T00:00:00',
            '2023-07-18T00:00:00',
            '2023-07-19T00:00:00',
            '2023-07-20T00:00:00',
            '2023-07-21T00:00:00',
            '2023-07-24T00:00:00',
            '2023-07-25T00:00:00',
            '2023-07-26T00:00:00',
            '2023-07-27T00:00:00',
            '2023-07-28T00:00:00',
            '2023-07-31T00:00:00',
            '2023-08-01T00:00:00',
            '2023-08-02T00:00:00',
            '2023-08-03T00:00:00',
            '2023-08-04T00:00:00',
            '2023-08-07T00:00:00',
            '2023-08-08T00:00:00',
            '2023-08-09T00:00:00',
            '2023-08-10T00:00:00',
            '2023-08-11T00:00:00',
            '2023-08-14T00:00:00',
            '2023-08-15T00:00:00',
            '2023-08-16T00:00:00',
            '2023-08-17T00:00:00',
            '2023-08-18T00:00:00',
            '2023-08-21T00:00:00',
            '2023-08-22T00:00:00',
            '2023-08-23T00:00:00',
            '2023-08-24T00:00:00',
            '2023-08-25T00:00:00',
            '2023-08-28T00:00:00',
            '2023-08-29T00:00:00',
            '2023-08-30T00:00:00',
            '2023-08-31T00:00:00',
            '2023-09-01T00:00:00',
            '2023-09-04T00:00:00',
            '2023-09-05T00:00:00',
          ],
        },
      ],
      yAxis: [
        {
          show: true,
          scale: true,
          nameLocation: 'end',
          nameGap: 15,
          gridIndex: 0,
          axisTick: {
            show: true,
            alignWithLabel: false,
            inside: false,
          },
          inverse: false,
          offset: 0,
          splitNumber: 5,
          minInterval: 0,
          splitLine: {
            show: true,
            lineStyle: {
              show: true,
              width: 1,
              opacity: 0.3,
              curveness: 0,
              type: 'solid',
            },
          },
          splitArea: {
            show: false,
            areaStyle: {
              opacity: 0.3,
            },
          },
        },
        {
          show: true,
          scale: false,
          nameLocation: 'end',
          nameGap: 15,
          gridIndex: 1,
          inverse: false,
          offset: 0,
          splitNumber: 5,
          minInterval: 0,
          splitLine: {
            show: false,
            lineStyle: {
              show: true,
              width: 1,
              opacity: 1,
              curveness: 0,
              type: 'solid',
            },
          },
        },
      ],
      // title: [
      //   {
      //     text: '000001 \u4e0a\u8bc1\u6307\u6570 \u65e5\u7ebf',
      //     padding: 5,
      //     itemGap: 10,
      //   },
      //   {
      //     padding: 5,
      //     itemGap: 10,
      //   },
      // ],
      dataZoom: [
        {
          show: false,
          type: 'inside',
          realtime: true,
          start: '70',
          end: 100,
          orient: 'horizontal',
          xAxisIndex: [0, 1],
          zoomLock: false,
        },
        {
          show: true,
          type: 'slider',
          realtime: true,
          start: '70',
          end: 100,
          orient: 'horizontal',
          xAxisIndex: [0, 1],
          zoomLock: false,
          top: 'bottom',
        },
      ],
      axisPointer: {
        show: true,
        type: 'line',
        link: [
          {
            xAxisIndex: 'all',
          },
        ],
      },
      grid: [
        {
          left: '50px',
          top: '5%',
          right: '50px',
          height: '65%',
          containLabel: false,
        },
        {
          left: '50px',
          top: '70%',
          right: '50px',
          height: '25%',
          containLabel: false,
        },
      ],
    };
    chart.setOption(option as any);
    const listener = () => {
      chart.resize();
    };
    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
      chart.dispose();
    };
  }, [chartRef]);
  return (
    <div ref={chartRef} style={{ width: width || '100%', height: height || '100%', overflow: 'hidden', flex: 1 }}></div>
  );
}
