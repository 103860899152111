import { TContractKLinePeriod } from '@/components/ContractPeriodSelector';
import Button from '@atlaskit/button';
import ChevronLeftLargeIcon from '@atlaskit/icon/glyph/chevron-left-large';
import ChevronRightLargeIcon from '@atlaskit/icon/glyph/chevron-right-large';
import VidFullScreenOffIcon from '@atlaskit/icon/glyph/vid-full-screen-off';
import VidFullScreenOnIcon from '@atlaskit/icon/glyph/vid-full-screen-on';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useCallback } from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import ContractFullPreviewView from './ContractFullPreviewView';
import { ContractPreviewPeriodSwitcher, useContractPeriodSwitcherStore } from './ContractPeriodSwitcher';
import ContractPreviewLayoutSwitcher, { useContractPrewiewLayoutStore } from './ContractPreviewLayoutSwitcher';

export const ContractFullScreenPreviewToolbar = () => {
  return (
    <Inline alignBlock="center" spread="space-between">
      <Inline separator="|" space="space.075" alignBlock="center">
        {null}
      </Inline>
      <Inline space="space.100" alignBlock="center">
        <ContractPreviewLayoutSwitcher />
        <ContractPreviewPeriodSwitcher />
        <Tooltip content="上一个" position="top">
          <Button isDisabled iconAfter={<ChevronLeftLargeIcon label="" />} />
        </Tooltip>
        <span>1/34</span>
        <Tooltip content="下一个" position="top">
          <Button iconAfter={<ChevronRightLargeIcon label="" />} />
        </Tooltip>
      </Inline>
    </Inline>
  );
};

export const ContractPreviewFullScreenButton = ({
  contract,
  period,
}: {
  contract: TKLineContract;
  period: TContractKLinePeriod;
}) => {
  const { shown = false, show, hide } = useContractPreviewFullScreenStore();
  const { select } = useContractPeriodSwitcherStore();
  const onFullScreenButtonClick = useCallback(() => {
    select(period);
    show(contract);
  }, [show, period, select]);
  return !shown ? (
    <Tooltip content="全屏" position="top">
      <Button iconAfter={<VidFullScreenOnIcon label="" />} onClick={onFullScreenButtonClick} />
    </Tooltip>
  ) : (
    <Tooltip content="还原" position="top">
      <Button iconAfter={<VidFullScreenOffIcon label="" />} onClick={hide} />
    </Tooltip>
  );
};

export declare type TKLineContract = [string, string];

export declare type TContractPreviewFullScreenStore = {
  contract?: TKLineContract;
  shown?: boolean;
  show: (contract: TKLineContract) => void;
  hide: () => void;
};
export const useContractPreviewFullScreenStore = create(
  persist<TContractPreviewFullScreenStore>(
    set => ({
      show: (contract: TKLineContract) => {
        set({ contract, shown: true });
      },
      hide() {
        set({ shown: false });
      },
    }),
    {
      name: 'contract-preview-full-screen-store',
    }
  )
);

const ContractFullScreenPreviewView = () => {
  const { contract } = useContractPreviewFullScreenStore();
  const { type } = useContractPrewiewLayoutStore();
  const [code, name] = contract || [];
  const { period } = useContractPeriodSwitcherStore();
  return (
    <Stack space="space.050" xcss={xcss({})}>
      <ContractFullScreenPreviewToolbar />
      <Box
        xcss={xcss({
          ':hover': {
            borderColor: 'color.border.focused',
          },
          width: '498px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: type === '2' ? '998px' : '1498px',
          minHeight: type === '2' ? '640px' : '960px',
        })}
      >
        {code && name && period && <ContractFullPreviewView code={code} name={name} period={period} />}
      </Box>
    </Stack>
  );
};

export default ContractFullScreenPreviewView;
