import { JsonValue } from './json';

declare interface ResponseInit {
  headers?: any | undefined;
  status?: number | undefined;
  statusText?: string | undefined;
}

export declare type Mocker = () => Promise<Response>;
export const mock = (body: any | undefined, init?: ResponseInit | undefined): Mocker => {
  return () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        init = init || {
          status: 200,
          statusText: 'mock ok',
        };

        const resp = new Response(body, init);
        resp.text = () => Promise.resolve(body as string);
        // resp.body.bodyUsed = false;
        resolve(resp);
      }, 1600);
    });
  };
};

export const mockJson = (body: JsonValue, init?: ResponseInit | undefined): Mocker => {
  init = init || {};
  init = {
    status: 200,
    statusText: 'mock ok',
    ...init,
  };
  return mock(JSON.stringify(body, null, 2), init);
};

export const unmock = (mocker: Mocker) => undefined;
