import Button, { ButtonGroup } from '@atlaskit/button';
import DropdownMenu, { DropdownItem, DropdownItemCheckbox, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { Label } from '@atlaskit/form';
import AddIcon from '@atlaskit/icon/glyph/add';
import AddItemIcon from '@atlaskit/icon/glyph/add-item';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import DocumentFilledIcon from '@atlaskit/icon/glyph/document-filled';
import MoreVerticalIcon from '@atlaskit/icon/glyph/more-vertical';
import { Box, Flex, Inline, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { forwardRef, useCallback, useMemo } from 'react';
import ContractListView from './ContractListView';
import { useNamePrompt } from './NamePromptModal';

import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import AppRightPanel, { AppRightPanelNavigationSwitchButton } from './AppRightPanel';
import ExportButton from './ExportButton';

export const AddToOptionalCollectionButton = ({ contracts }: { contracts?: TOptionalContractItem[] }) => {
  const { collections, addContractsTo, removeContractsFrom } = useOptionalContractStore();
  const handleAddToItemClick = useCallback(
    (name: string, selected: boolean) => {
      if (selected) {
        return removeContractsFrom(name, contracts);
      } else {
        return addContractsTo(name, contracts);
      }
    },
    [removeContractsFrom, addContractsTo, contracts]
  );
  const collectionItems = useMemo(
    () =>
      collections.map(({ name, datas }) => {
        const codes = datas.map(d => d[0]);
        const selected =
          !!contracts && contracts?.filter(c => codes.indexOf(c[0]) !== -1)?.length === contracts?.length;
        return (
          <DropdownItemCheckbox
            key={name}
            id={name}
            isSelected={selected}
            onClick={() => handleAddToItemClick(name, selected)}
          >
            {name}
          </DropdownItemCheckbox>
        );
      }),
    [collections, contracts]
  );
  return (
    <DropdownMenu<HTMLButtonElement>
      placement="bottom-end"
      spacing="compact"
      trigger={({ triggerRef, isSelected, testId, ...providedProps }) => (
        <Tooltip content="添加至.." position="top">
          <Button
            iconAfter={<AddItemIcon label="" />}
            {...providedProps}
            ref={triggerRef}
            isDisabled={(contracts?.length || 0) <= 0}
          />
        </Tooltip>
      )}
    >
      <DropdownItemGroup>
        <DropdownItem isDisabled>将{contracts?.length || 0}个合约添加至..</DropdownItem>
      </DropdownItemGroup>
      <DropdownItemGroup hasSeparator title="我的自选">
        {collectionItems}
      </DropdownItemGroup>
      <DropdownItemGroup hasSeparator>
        <DropdownItem elemBefore={<AddIcon label="" />}>新自选..</DropdownItem>
      </DropdownItemGroup>
    </DropdownMenu>
  );
};

export const OptionalContractCollectionDropdownItem = forwardRef(
  ({ children, ...otherprops }: any, ref: React.Ref<HTMLElement>) => {
    return (
      <Inline space="space.050" alignBlock="center" xcss={xcss({ paddingInline: 'space.050' })}>
        <Button
          type="button"
          ref={ref}
          shouldFitContainer
          style={{ alignItems: 'center', textAlign: 'left' }}
          appearance="subtle"
        >
          {children}
        </Button>
        <Tooltip content="删除" position="top">
          <Button iconAfter={<CrossIcon label="" />} appearance="subtle" />
        </Tooltip>
      </Inline>
    );
  }
);

const MoreButton = () => {
  const { nameModal, requestName } = useNamePrompt();
  const onSaveAsClick = useCallback(async () => {
    const name = await requestName('另存为..');
    console.log('--------->:', name);
    alert(name);
  }, [requestName]);
  return (
    <>
      {nameModal}
      <DropdownMenu<HTMLButtonElement>
        placement="bottom-end"
        spacing="compact"
        trigger={({ triggerRef, isSelected, testId, ...providedProps }) => (
          <Button iconAfter={<MoreVerticalIcon label="" />} {...providedProps} ref={triggerRef} />
        )}
      >
        <DropdownItemGroup>
          <DropdownItem onClick={onSaveAsClick} elemBefore={<DocumentFilledIcon label="" />}>
            另存为..
          </DropdownItem>
        </DropdownItemGroup>
        <DropdownItemGroup hasSeparator title="我的自选">
          <OptionalContractCollectionDropdownItem>自选30F盘背</OptionalContractCollectionDropdownItem>
          <OptionalContractCollectionDropdownItem>自选合约1203001</OptionalContractCollectionDropdownItem>
          <OptionalContractCollectionDropdownItem>自选15F盘背</OptionalContractCollectionDropdownItem>
          <OptionalContractCollectionDropdownItem>自选合约1203001</OptionalContractCollectionDropdownItem>
          <OptionalContractCollectionDropdownItem>自选合约1203001</OptionalContractCollectionDropdownItem>
        </DropdownItemGroup>
      </DropdownMenu>
    </>
  );
};

export declare type TOptionalContractItem = [string, string];
export declare type TOptionalCollection = {
  name: string;
  datas: TOptionalContractItem[];
};
export declare type TOptionalContractStore = {
  collections: TOptionalCollection[];
  selectedName: string;
  getCurrentCollection: () => TOptionalCollection | undefined;
  makeCollection: (name: string) => TOptionalCollection;
  removeCollection: (name: string) => boolean;
  addContractsTo: (name: string, contracts?: TOptionalContractItem[]) => TOptionalCollection;
  removeContractsFrom: (name: string, contracts?: TOptionalContractItem[]) => TOptionalCollection;
};
export const useOptionalContractStore = create(
  persist<TOptionalContractStore>(
    (set, get) => ({
      collections: [{ name: '默认自选', datas: [] }],
      selectedName: '默认自选',
      makeCollection: (name: string) => {
        let collection = get().collections.find(c => c.name === name);
        if (!collection) {
          collection = { name, datas: [] };
          set({ collections: get().collections.concat(collection) });
        }
        return collection;
      },
      removeCollection: (name: string) => {
        if (name === '默认自选') {
          return false;
        }
        const collections = get().collections.filter(c => c.name !== name);
        set({ collections });
        return true;
      },
      getCurrentCollection: () => get().collections.find(c => c.name === get().selectedName),
      addContractsTo: (name, contracts = []) => {
        const collection = get().makeCollection(name);
        const { datas } = collection;
        collection.datas = contracts.reduce((datas, contract) => {
          const [code, name] = contract;
          const exists = datas.find(d => d[0] === code);
          return exists ? datas : datas.concat([[code, name]]);
        }, datas);
        const collections = get().collections;
        collections.splice(collections.indexOf(collection), 1, { ...collection });
        set({
          collections: [...collections],
        });
        return collection;
      },
      removeContractsFrom: (name: string, contracts: TOptionalContractItem[] = []) => {
        const collection = get().makeCollection(name);
        const codes = contracts.map(c => c[0]);
        collection.datas = collection.datas.filter(c => !codes.includes(c[0]));
        const collections = get().collections;
        collections.splice(collections.indexOf(collection), 1, { ...collection });
        set({
          collections: [...collections],
        });
        return collection;
      },
    }),
    {
      name: 'optional-contract-store',
    }
  )
);

const OptionalContractPanel = forwardRef(({}: { className?: string }, ref: any) => {
  const { getCurrentCollection } = useOptionalContractStore();
  const currentCollection = getCurrentCollection();
  const codes = useMemo(() => currentCollection?.datas.map(data => data[0]), [currentCollection]);
  const contractListActions = (
    <ButtonGroup>
      <Tooltip position="top" content="添加合约..">
        <Button iconBefore={<AddItemIcon label="" />} />
      </Tooltip>
      <ExportButton name={currentCollection?.name} codes={codes} />
      <MoreButton />
    </ButtonGroup>
  );
  return (
    <AppRightPanel>
      <Flex
        xcss={xcss({
          paddingTop: 'space.150',
        })}
        direction="column"
        rowGap="space.100"
      >
        <Inline xcss={xcss({ paddingInline: 'space.100' })} space="space.100" alignBlock="center">
          <AppRightPanelNavigationSwitchButton />
          <Box xcss={xcss({ paddingTop: 'space.050' })}>
            <Label htmlFor="">我的自选</Label>
          </Box>
        </Inline>
        <ContractListView
          title={currentCollection?.name || '-'}
          subTitle={`${currentCollection?.datas?.length || '-'}个`}
          actions={contractListActions}
          contracts={currentCollection?.datas as any}
        />
      </Flex>
    </AppRightPanel>
  );
});

export default OptionalContractPanel;
