import Button from '@atlaskit/button';
import { Field, HelperMessage } from '@atlaskit/form';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import Textfield from '@atlaskit/textfield';
import { FormEvent, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

export const useNamePrompt = () => {
  const [request, setRequest] = useState<{ title: string; callback: (name: string | false) => void }>();
  const onConfirm = useCallback(
    (name: string) => (request!.callback(name), setRequest(undefined)),
    [request?.callback, setRequest]
  );
  const onCancel = useCallback(() => (request!.callback(false), setRequest(undefined)), [request?.callback]);
  const nameModal = useMemo(
    () => (!request ? undefined : <NamePromptModal title={request.title} onConfirm={onConfirm} onCancel={onCancel} />),
    [request]
  );
  const requestName = useCallback(
    (title: string) => {
      return new Promise<string | false>(resolve => {
        setRequest({
          title,
          callback: resolve,
        });
      });
    },
    [setRequest]
  );
  const { setNameModal } = useContext(NamePromptContext);
  useEffect(() => setNameModal?.(nameModal as typeof NamePromptModal | undefined), [nameModal, setNameModal]);
  return { nameModal, requestName };
};

export const NamePromptContext = createContext<{ setNameModal?: (modal: typeof NamePromptModal | undefined) => void }>(
  {}
);
export const NamePromptProvider = ({ children }: { children: any }) => {
  const [nameModal, setNameModal] = useState<typeof NamePromptModal>();
  return (
    <NamePromptContext.Provider value={{ setNameModal }}>
      <>
        {nameModal}
        {children}
      </>
    </NamePromptContext.Provider>
  );
};

export declare type TNamePromptModalProps = {
  title: string;
  onConfirm?: (name: string) => void;
  onCancel?: () => void;
};

const NamePromptModal = ({ title, onConfirm, onCancel }: TNamePromptModalProps) => {
  const [message, setMessage] = useState<string>();
  const onClose = useCallback(() => onCancel?.(), []);
  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      const data = new FormData(e.target as HTMLFormElement);
      const name = data.get('name');
      if (!name) {
        return setMessage('名称不能为空');
      }
      onConfirm?.(name as string);
    },
    [onConfirm, setMessage]
  );
  return (
    <ModalTransition>
      <Modal onClose={onClose} shouldCloseOnEscapePress shouldCloseOnOverlayClick={false} width="small">
        <form onSubmit={onSubmit}>
          <ModalHeader>
            <ModalTitle>{title}</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Field id="name" name="name" label="名称">
              {({ fieldProps }) => (
                <>
                  <Textfield autoFocus {...fieldProps} value={undefined} placeholder="请输入" />
                  <HelperMessage>{message}</HelperMessage>
                </>
              )}
            </Field>
          </ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={onClose}>
              取消
            </Button>
            <Button appearance="primary" type="submit">
              确定
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </ModalTransition>
  );
};
export default NamePromptModal;
