import { Settings } from '@atlaskit/atlassian-navigation';
import Button from '@atlaskit/button';
import { Label } from '@atlaskit/form';
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalTransition, OnCloseHandler } from '@atlaskit/modal-dialog';
import { Flex, Inline, Stack, xcss } from '@atlaskit/primitives';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import Toggle from '@atlaskit/toggle';
import { setGlobalTheme, useThemeObserver } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import prettyBytes from 'pretty-bytes';
import { SyntheticEvent, useCallback, useState } from 'react';

const AppSettingsModal = ({ onClose }: { onClose: OnCloseHandler }) => {
  const theme = useThemeObserver();
  const nextTheme = theme.colorMode === 'dark' ? 'light' : 'dark';
  const changeTheme = useCallback(() => {
    setGlobalTheme({
      colorMode: nextTheme,
    });
  }, [nextTheme]);

  const hmSize = prettyBytes(new Blob(Object.values(localStorage)).size, { locale: 'zh' });

  const onCleanClick = useCallback((event: SyntheticEvent) => {
    event.preventDefault();
    if (!window.confirm('该操作将您的个人数据全部删除，包括但不限于使用设置、自选股等..')) {
      return;
    }
    localStorage.clear();
    window.location.reload();
  }, []);
  return (
    <Modal onClose={onClose}>
      <ModalHeader>
        <ModalTitle>系统设置</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Stack space="space.200">
          <SectionMessage
            title="存储"
            actions={[
              <SectionMessageAction href="#" key="clean" onClick={onCleanClick}>
                清理
              </SectionMessageAction>,
            ]}
          >
            <p>系统已使用存储空间: {hmSize}</p>
            <p>
              你的自选股、使用设置等数据存储在浏览器中，清理浏览器缓存或者执行“清理”操作，将会导致数据丢失，请酌情使用。
            </p>
          </SectionMessage>
          <Inline alignBlock="center" space="space.100">
            <Label htmlFor="theme-toggle">主题</Label>
            <Tooltip content={theme.colorMode === 'dark' ? '暗色主题' : '亮色主题'} position="right">
              <Toggle id="theme-toggle" onChange={changeTheme} />
            </Tooltip>
          </Inline>
        </Stack>
      </ModalBody>
      <Flex
        justifyContent="end"
        xcss={xcss({ paddingLeft: 'space.300', paddingRight: 'space.300', paddingTop: '24px', paddingBottom: '24px' })}
      >
        <Inline space="space.100">
          <Button onClick={onClose}>关闭</Button>
          {/* <Button appearance="primary" onClick={onClose}>
            确定
          </Button> */}
        </Inline>
      </Flex>
    </Modal>
  );
};

export function SettingsButton() {
  const [isModalShown, setModalShown] = useState(false);
  const onClose = useCallback(() => setModalShown(false), [setModalShown]);
  return (
    <>
      <Settings tooltip="系统设置" onClick={() => setModalShown(true)} />
      <ModalTransition>{isModalShown && <AppSettingsModal onClose={onClose} />}</ModalTransition>
    </>
  );
}
