import Button, { ButtonGroup } from '@atlaskit/button';
import EditorLayoutThreeEqualIcon from '@atlaskit/icon/glyph/editor/layout-three-equal';
import EditorLayoutTwoEqualIcon from '@atlaskit/icon/glyph/editor/layout-two-equal';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export declare type TContractPreviewLayoutType = '2' | '3';
export declare type TContractPreviewLayoutStore = {
  type: TContractPreviewLayoutType;
  setType: (type: TContractPreviewLayoutType) => void;
};
export const useContractPrewiewLayoutStore = create(
  persist<TContractPreviewLayoutStore>(
    set => ({
      type: '2',
      setType: (type: TContractPreviewLayoutType) => {
        set({ type });
        setTimeout(() => window.dispatchEvent(new CustomEvent('resize')), 100);
      },
    }),
    { name: 'contract-preview-layout-store' }
  )
);

const ContractPreviewLayoutSwitcher = () => {
  const { type, setType } = useContractPrewiewLayoutStore();
  return (
    <ButtonGroup>
      <Button isSelected={type === '2'} onClick={() => setType('2')} iconAfter={<EditorLayoutTwoEqualIcon label="" />}>
        两列
      </Button>
      <Button
        isSelected={type === '3'}
        onClick={() => setType('3')}
        iconAfter={<EditorLayoutThreeEqualIcon label="" />}
      >
        三列
      </Button>
    </ButtonGroup>
  );
};

export default ContractPreviewLayoutSwitcher;
