import { Field, Label } from '@atlaskit/form';
import SearchIcon from '@atlaskit/icon/glyph/search';
import Modal, { ModalBody, ModalTransition } from '@atlaskit/modal-dialog';
import { Stack, xcss } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';
import Textfield from '@atlaskit/textfield';
import Tooltip from '@atlaskit/tooltip';
import { FormEvent, useCallback } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import AppSearchContractResultTableView from './AppSearchContractResultTableView';

const AppSearchButton = () => {
  const showSearchModal = useCallback(() => {
    // TODO
    alert('command + k');
  }, []);
  useHotkeys('mod+k', showSearchModal);
  return (
    <>
      {/* <SearchModal /> */}
      <Tooltip content="检索">
        <Textfield
          style={{ cursor: 'pointer' }}
          placeholder="⌘+K"
          isReadOnly
          width={71}
          appearance="subtle"
          isCompact
          elemBeforeInput={<SearchIcon label="" />}
          onClick={showSearchModal}
        />
      </Tooltip>
    </>
  );
};

export default AppSearchButton;

export declare type TSearchModalProps = {
  onConfirm?: (name: string) => void;
  onCancel?: () => void;
};

export const SearchModal = ({ onConfirm, onCancel }: TSearchModalProps) => {
  const onClose = useCallback(() => onCancel?.(), []);
  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      const data = new FormData(e.target as HTMLFormElement);
      const keywords = data.get('keywords');
      if (!keywords) {
        return;
      }
      onConfirm?.(keywords as string);
    },
    [onConfirm]
  );
  return (
    <ModalTransition>
      <Modal onClose={onClose} shouldCloseOnEscapePress shouldCloseOnOverlayClick shouldScrollInViewport>
        <Stack xcss={xcss({ paddingBlock: 'space.150', width: '100%' })}>
          <ModalBody>
            <Stack space="space.100">
              <form onSubmit={onSubmit}>
                <Field id="keywords" name="keywords">
                  {({ fieldProps }) => <Textfield autoFocus {...fieldProps} value={undefined} placeholder="股票代码" />}
                </Field>
              </form>
              <Stack space="space.100">
                <SectionMessage appearance="warning">
                  <p>无检索结果</p>
                </SectionMessage>
                <Label htmlFor="">选股结果</Label>
                <AppSearchContractResultTableView />
                <Label htmlFor="">自选30F盘背</Label>
                <AppSearchContractResultTableView />
                <Label htmlFor="">全市场</Label>
                <AppSearchContractResultTableView />
              </Stack>
            </Stack>
          </ModalBody>
        </Stack>
      </Modal>
    </ModalTransition>
  );
};
