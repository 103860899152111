import { JsonValue } from './json';

export class FetchError extends Error {
  status: number;
  statusText: string;
  response: Response;
  responseText: string | undefined;
  responseObject: JsonValue | undefined;

  constructor(
    response: Response,
    message?: string,
    responseText?: string,
    responseObject?: JsonValue,
    options?: ErrorOptions
  ) {
    super(message, options);
    this.response = response;
    const { status, statusText } = response;
    this.status = status;
    this.statusText = statusText;
    this.responseText = responseText;
    this.responseObject = responseObject;
  }
}

export const checkError = async (response: Response) => {
  const { status, statusText } = response;
  if (status === 200) {
    return;
  }

  const responseText = await response.text();
  console.warn(response.url, status, statusText, responseText);
  let responseObject: any;
  if (responseText) {
    try {
      responseObject = JSON.parse(responseText);
    } catch (err) {} // @ts-e
  }
  throw new FetchError(
    response,
    (responseObject || {}).message || responseText || statusText,
    responseText,
    responseObject
  );
};
