import DynamicTable from '@atlaskit/dynamic-table';
import { Box, Stack, xcss } from '@atlaskit/primitives';

const head = {
  cells: [
    {
      key: 'index',
      content: '',
      width: 8,
    },
    {
      key: 'code',
      content: '名称/代码',
      width: 30,
    },
    {
      key: 'price',
      content: <Box xcss={xcss({ width: '100%', textAlign: 'right' })}>最新价</Box>,
      width: 30,
    },
    {
      key: 'percent',
      content: <Box xcss={xcss({ width: '100%', textAlign: 'right' })}>涨跌幅</Box>,
    },
    {
      key: 'op',
      content: '',
      width: 12,
    },
  ],
};

const rows = [
  ['600519', '贵州茅台', 177.28, 0.043],
  ['600519', '贵州茅台', 177.28, 0.043],
  ['600519', '贵州茅台', 177.28, 0.043],
].map(([code, name, price, percent], index) => ({
  isHighlighted: index === 0,
  key: String(index),
  cells: [
    {
      key: index,
      content: <Box xcss={xcss({ fontSize: '10px', textAlign: 'right' })}>{index}</Box>,
    },
    {
      key: code,
      content: (
        <Stack>
          <Box xcss={xcss({ fontSize: '12px' })}>{name}</Box>
          <Box xcss={xcss({ color: 'color.text.subtlest', fontSize: '10px' })}>{code}</Box>
        </Stack>
      ),
    },
    {
      key: price,
      content: <Box xcss={xcss({ width: '100%', textAlign: 'right', fontSize: '12px' })}>{price}</Box>,
    },
    {
      key: percent,
      content: (
        <Box xcss={xcss({ width: '100%', textAlign: 'right', fontSize: '12px', color: 'color.text.accent.red' })}>
          {(percent as number) * 100}%
        </Box>
      ),
    },
    {
      key: `${index}-op`,
      content: <Box xcss={xcss({ paddingRight: 'space.150' })}></Box>,
    },
  ],
}));

export default function AppSearchContractResultTableView() {
  return <DynamicTable head={head} rows={rows} isFixedSize defaultSortKey="price" defaultSortOrder="ASC" />;
}
