import { Label } from '@atlaskit/form';
import { LeftSidebar } from '@atlaskit/page-layout';
import { LeftSidebarProps } from '@atlaskit/page-layout/dist/types/common/types';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import { ReactNode } from 'react';

import Button from '@atlaskit/button';
import Tooltip from '@atlaskit/tooltip';
import { useCallback } from 'react';

import ChevronLeftCircleIcon from '@atlaskit/icon/glyph/chevron-left-circle';
import ChevronRightCircleIcon from '@atlaskit/icon/glyph/chevron-right-circle';
import { create } from 'zustand';

export const useContractScreenerPanelStore = create<{ opened: boolean; show: () => void; hide: () => void }>(set => ({
  opened: true,
  show: () => set({ opened: true }),
  hide: () => set({ opened: false }),
}));

export const ModuleSidebarSwitchButton = ({
  label,
  tooltips,
  hidden,
}: {
  label: string;
  tooltips: [string, string];
  hidden?: boolean;
}) => {
  const { opened, show, hide } = useContractScreenerPanelStore();
  const onClick = useCallback(() => {
    opened ? hide() : show();
    window.dispatchEvent(new CustomEvent('resize'));
  }, [opened, show, hide]);
  if (hidden === true) {
    return null;
  }
  return (
    <Tooltip content={opened ? tooltips[1] : tooltips[0]}>
      <Button
        onClick={onClick}
        label={label}
        iconAfter={opened ? <ChevronRightCircleIcon label="" /> : <ChevronLeftCircleIcon label="" />}
      />
    </Tooltip>
  );
};

export default function ModuleSideBar({
  title,
  children,
  ...otherprops
}: { title: string; children: ReactNode } & LeftSidebarProps) {
  return (
    <LeftSidebar
      width={260}
      isFixed
      valueTextLabel="Width"
      overrides={{
        ResizeButton: {
          render: (Component, props) => (
            <Tooltip content={title} hideTooltipOnClick position="right" testId="tooltip">
              <Component {...props} />
            </Tooltip>
          ),
        },
      }}
      {...otherprops}
    >
      <Stack xcss={xcss({ width: '100%', minHeight: '100%', backgroundColor: 'elevation.surface' })}>
        <Inline spread="space-between" alignBlock="center" xcss={xcss({ paddingInline: 'space.150', height: '32px' })}>
          <Box xcss={xcss({ paddingTop: 'space.025' })}>
            <Label htmlFor="">{title}</Label>
          </Box>
          {/* <ModuleSidebarSwitchButton label={title} tooltips={[`打开${title}`, `隐藏${title}`]} /> */}
        </Inline>
        {children}
      </Stack>
    </LeftSidebar>
  );
}
