import fetchToCurl from 'fetch-to-curl';
import Cookies from 'js-cookie';
import { checkError } from './error';
import { JsonValue } from './json';
import { Mocker } from './mocker';

export * from './error';
export * from './json';
export * from './mocker';

const getHttpHeaders = async () => {
  const jwtsessiontoken = localStorage.getItem('token') || Cookies.get('token') || '';
  return {
    Accept: 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
    jwtsessiontoken,
  };
};

async function fetchMock(
  input: RequestInfo,
  init?: RequestInit,
  mocker?: Mocker
): Promise<{ response: Response; mocked: boolean }> {
  const headers = await getHttpHeaders();
  init = init || {};
  init.headers = {
    ...headers,
    ...init.headers,
  };
  const curl = fetchToCurl(input, init);
  console.log(curl);

  if (!mocker) {
    const response = await fetch(input, init);
    console.log(response);
    return { response, mocked: false };
  }

  const response = await mocker?.();
  console.log(response);
  return { response, mocked: true };
}

const _fetch = async (input: RequestInfo, init?: RequestInit, mocker?: Mocker) =>
  (await fetchMock(input, init, mocker)).response;

export { _fetch as fetch };

export const buildUrl = (url: string) => {
  return /^http(s):\/\//.test(url) ? url : process.env.BASE_URL + url;
};

const trace = (response: Response, ret?: JsonValue | undefined) => {
  if (response.ok) {
    console.log(response.url, response.status, response.statusText, ret);
  } else {
    console.error('ERROR:', response.url, response.status, response.statusText, ret);
  }
};

export const upload = async (
  url: string,
  file: File,
  others: any,
  mocker?: Mocker,
  translator?: TJsonTranslator | false
) => {
  const fd = new FormData();
  fd.append('file', file);

  Object.keys(others).forEach(key => {
    fd.append(key, others[key]);
  });
  const needMock = !translator;
  const { response, mocked } = await fetchMock(url, { method: 'POST', body: fd }, needMock ? mocker : undefined);
  await checkError(response);
  const ret = (await response.json()) as JsonValue | undefined;
  trace(response, ret);
  return !mocked && translator ? translator(ret) : ret;
};

export declare type TJsonTranslator = (input: JsonValue | undefined) => JsonValue | undefined;

export const get = async (
  url: string,
  params?: { [x: string]: any },
  mocker?: Mocker,
  translator?: TJsonTranslator | false
): Promise<JsonValue | undefined> => {
  const needMock = !translator;
  const { response, mocked } = await fetchMock(
    url + (url.indexOf('?') === -1 ? '?' : '') + (params ? new URLSearchParams(params).toString() : ''),
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    },
    needMock ? mocker : undefined
  );
  await checkError(response);
  const ret = (await response.json()) as JsonValue | undefined;
  trace(response, ret);
  return !mocked && translator ? translator(ret) : ret;
};

export const post = async (
  url: string,
  body?: any,
  mocker?: Mocker,
  translator?: TJsonTranslator | false,
  method?: string
): Promise<JsonValue | undefined> => {
  const needMock = !translator;
  const { response, mocked } = await fetchMock(
    url,
    {
      method: method || 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      ...(body ? { body: JSON.stringify(body) } : {}),
    },
    needMock ? mocker : undefined
  );
  await checkError(response);
  const ret = (await response.json()) as JsonValue | undefined;
  trace(response, ret);
  return !mocked && translator ? translator(ret) : ret;
};

export const patch = (
  url: string,
  body?: any,
  mocker?: Mocker,
  translator?: TJsonTranslator | false
): Promise<JsonValue | undefined> => {
  return post(url, body, mocker, translator, 'PATCH');
};
