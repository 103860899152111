import ContractListView from '@/components/ContractListView';
import ExportButton from '@/components/ExportButton';
import { AddToOptionalCollectionButton, TOptionalContractItem } from '@/components/OptionalContractPanel';
import { TScreenConditionItem, doScreenWith } from '@/services/screener';
import Button, { ButtonGroup } from '@atlaskit/button';
import { Label } from '@atlaskit/form';
import SelectClearIcon from '@atlaskit/icon/glyph/select-clear';
import { Box, Flex, Inline, Stack, xcss } from '@atlaskit/primitives';
import Tag from '@atlaskit/tag';
import TagGroup from '@atlaskit/tag-group';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { useAdvanceSettingStore } from './AdvanceButton';
import { useScreenResultStore } from './ConditionEditor';
import { SOURCE_SCREENER_RESULT, useContractPreviewSourceStore } from './ContractPreviewSourceSwitcher';

const ScreenerConditionPreview = () => {
  const { conditions, clean } = useScreenResultStore();
  const { hq, js } = useAdvanceSettingStore();
  const { setResult } = useScreenResultStore();
  const { set: setPreviewSource } = useContractPreviewSourceStore();
  const onConditionRemove = useCallback(
    async (condition: TScreenConditionItem) => {
      const conds = conditions?.filter(c => c !== condition);
      if (!conds || conds.length === 0) {
        return;
      }
      const { payload } = await doScreenWith(conds, { hq, js });
      setResult(conds, payload);
      setPreviewSource(SOURCE_SCREENER_RESULT);
    },
    [conditions, clean, hq, js, setResult, setPreviewSource]
  );
  const conditionTags = conditions?.map((condition, index) => (
    <Tag
      key={index}
      text={`${condition.period}(${condition.signals.join(',')})`}
      removeButtonLabel="删除"
      isRemovable={(conditions?.length || 0) > 1}
      onBeforeRemoveAction={() => {
        onConditionRemove.bind(undefined, condition)();
        return false;
      }}
    />
  ));
  if ((conditions?.length || 0) <= 0) {
    return null;
  }
  return (
    <Stack space="space.100" xcss={xcss({ paddingTop: 'space.200', paddingInline: 'space.150' })}>
      <Label htmlFor="">选股条件</Label>
      <Stack
        xcss={xcss({
          backgroundColor: 'color.background.information',
          padding: 'space.075',
          borderRadius: 'border.radius.050',
        })}
        space="space.050"
      >
        <TagGroup>{conditionTags}</TagGroup>
        <Inline alignInline="end">
          <ButtonGroup>
            <Button iconBefore={<SelectClearIcon label="" />} appearance="subtle" onClick={clean}>
              清除
            </Button>
          </ButtonGroup>
        </Inline>
      </Stack>
    </Stack>
  );
};

export function ScreenerResultView() {
  const { result } = useScreenResultStore();
  const name = `选股-${dayjs().format('YYMMDDHHmm')}`;
  const codes = useMemo(() => result?.datas.map(d => d[0]), [result]);
  return (
    <Stack>
      <ScreenerConditionPreview />

      <Flex
        xcss={xcss({
          paddingTop: 'space.200',
        })}
        direction="column"
        rowGap="space.100"
      >
        <Box xcss={xcss({ paddingInline: 'space.150' })}>
          <Label htmlFor="">选股结果</Label>
        </Box>
        <ContractListView
          title={`${result?.datas?.length || '-'}个`}
          contracts={result?.datas}
          actions={
            <ButtonGroup>
              <ExportButton name={name} codes={codes} />
              <AddToOptionalCollectionButton contracts={result?.datas as TOptionalContractItem[] | undefined} />
            </ButtonGroup>
          }
        />
      </Flex>
    </Stack>
  );
}
