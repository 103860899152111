import { IndexedDb } from 'minimongo';

export const init = () =>
  new Promise<IndexedDb>((resolve, reject) => {
    let db: IndexedDb;
    db = new IndexedDb({ namespace: 'lv-trader' }, () => resolve(db), reject);
  });

export const collection = (name: string) => {
  return new Promise((resolve, reject) => {
    const coll = global.db.collections[name];
    if (coll) {
      return coll;
    }
    global.db.addCollection(name, () => resolve(global.db.collections[name]), reject);
  });
};
