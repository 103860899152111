import Button, { ButtonGroup } from '@atlaskit/button';
import FilterIcon from '@atlaskit/icon/glyph/filter';
import FolderIcon from '@atlaskit/icon/glyph/folder';
import Tooltip from '@atlaskit/tooltip';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const SOURCE_SCREENER_RESULT = 'screener-result';
export const SOURCE_MY_OPTIONAL = 'my-optional';
export declare type TContractPreviewSourceStore = {
  source: typeof SOURCE_SCREENER_RESULT | typeof SOURCE_MY_OPTIONAL;
  set: (source: typeof SOURCE_SCREENER_RESULT | typeof SOURCE_MY_OPTIONAL) => void;
};
export const useContractPreviewSourceStore = create(
  persist<TContractPreviewSourceStore>(
    set => ({
      source: SOURCE_SCREENER_RESULT,
      set: (source: typeof SOURCE_SCREENER_RESULT | typeof SOURCE_MY_OPTIONAL) => set({ source }),
    }),
    {
      name: 'contract-preview-source-store',
    }
  )
);

const ContractPreviewSourceSwitcher = () => {
  const { source, set: setSource } = useContractPreviewSourceStore();
  return (
    <ButtonGroup>
      <Tooltip content={`${source === SOURCE_SCREENER_RESULT ? '' : '切换到'}选股结果`} position="top">
        <Button
          isSelected={source === SOURCE_SCREENER_RESULT}
          iconBefore={<FilterIcon label="" />}
          onClick={() => setSource(SOURCE_SCREENER_RESULT)}
        >
          选股
        </Button>
      </Tooltip>
      <Tooltip content={`${source === SOURCE_MY_OPTIONAL ? '' : '切换到'}我的自选`} position="top">
        <Button
          isSelected={source === SOURCE_MY_OPTIONAL}
          iconBefore={<FolderIcon label="" />}
          onClick={() => setSource(SOURCE_MY_OPTIONAL)}
        >
          自选
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
};

export default ContractPreviewSourceSwitcher;
