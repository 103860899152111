import { useEffect, useState } from 'react';
import { init } from './db';
import routes from './routes';

const App = () => {
  const [inited, setInited] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        global.db = await init();
        setInited(true);
      } catch (err) {
        alert(`初始化失败(${(err as Error).message || (err as any)})`);
      }
    })();
  }, []);
  return inited ? routes : null;
};
export default App;
