import Button from '@atlaskit/button';
import ExportIcon from '@atlaskit/icon/glyph/export';
import Tooltip from '@atlaskit/tooltip';
import { saveAs } from 'file-saver';
import { useCallback } from 'react';

const doExport = (filename: string, codes: string[]) => {
  const str = `\n${codes.join('\n')}`;
  const blob = new Blob([str], { type: 'text/plain;charset=utf-8' });
  saveAs(blob, filename);
};

const ExportButton = ({ name, codes }: { name?: string; codes?: string[] }) => {
  const onClick = useCallback(() => {
    if (!name || (codes || []).length === 0) {
      return;
    }
    doExport(`${name}.EBK`, codes!);
  }, [name, codes]);
  return (
    <Tooltip position="top" content="导出..">
      <Button isDisabled={!name || (codes || []).length === 0} iconBefore={<ExportIcon label="" />} onClick={onClick} />
    </Tooltip>
  );
};

export default ExportButton;
