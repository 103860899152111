import { get, mockJson } from '@/fetch';

export declare type TContractShortItem = [string, string]; // ['600519', '贵州茅台', 177.28, 0.043],
export declare type TContractShortItems = TContractShortItem[];

export type TConditionPeriod = '2d' | 'd' | '60f' | '15f' | '3f' | '180f' | '30f' | '5f' | '1f';
export type TConditionSignal = '盘背拉回' | '二买预期' | '三买预期' | '盘背预期' | '中枢突破' | '完备预期';
export declare type TScreenConditionItem = { period: TConditionPeriod; signals: TConditionSignal[] };
export declare type TScreenConditions = TScreenConditionItem[];

export declare type TScreenPL = { datas: TContractShortItems };
export declare type TGenerialRsp = { code?: string; message?: string };
export declare type TScreenRsp = TGenerialRsp & { payload: TScreenPL };

import { TContractKLinePeriod } from '@/components/ContractPeriodSelector';
import datas from './datas.json';

/** 筛选 */
export const doScreenWith = async (conditions: TScreenConditions, settings: any): Promise<TScreenRsp> => {
  const n = Math.ceil((Math.random() * 999999) % datas.length);
  return (await get(
    '/screen',
    { conditions },
    mockJson({
      payload: {
        datas: datas.sort(() => Math.random() - 0.5).slice(0, n),
      },
    })
  )) as TScreenRsp;
};

export declare type TRequestPreviewChartDataPL = {
  kline: any;
  macd: any;
};
export declare type TRequestPreviewChartDataRsp = TGenerialRsp & { payload: TRequestPreviewChartDataPL };
/** 获取图表数据  */
export const requestPreviewChartData = async (code: string, period: TContractKLinePeriod) => {
  return (await get(
    `/contract/${code}/chart/preview`,
    { period },
    mockJson({
      // TODO
    })
  )) as any;
};
