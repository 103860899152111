import { ContractPreviewFullScreenButton } from '@/components/ContractFullScreenPreviewView';
import { TContractKLinePeriod } from '@/components/ContractPeriodSelector';
import { AddToOptionalCollectionButton } from '@/components/OptionalContractPanel';
import Button, { ButtonGroup } from '@atlaskit/button';
import SelectClearIcon from '@atlaskit/icon/glyph/select-clear';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import ContractChartView from './ContractChartView';

require('intersection-observer');

export default function ContractPreviewView({
  code,
  name,
  period,
}: {
  code: string;
  name: string;
  period: TContractKLinePeriod;
}) {
  const containerRef = useRef(null);
  const { inViewport } = useInViewport(containerRef, {}, { disconnectOnLeave: false });
  return (
    <Box
      ref={containerRef}
      xcss={xcss({
        borderWidth: 'border.width.outline',
        borderRadius: 'border.radius.050',
        borderColor: 'color.border',
        padding: 'space.050',
        borderStyle: 'solid',
        ':hover': {
          borderColor: 'color.border.focused',
        },
        width: '498px',
        height: '320px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      })}
    >
      {!inViewport ? (
        <Inline xcss={xcss({ color: 'color.text.subtlest', fontSize: '18px' })} space="space.100">
          {code} {name} {period}
        </Inline>
      ) : (
        <Stack xcss={xcss({ width: '100%', height: '100%' })}>
          <Inline spread="space-between" alignBlock="center">
            <Inline alignBlock="baseline" separator="|" space="space.100" alignInline="center">
              <h5>
                {code} {name}
              </h5>
              <h6 style={{ margin: 0 }}>{period}</h6>
            </Inline>
            <ButtonGroup>
              <AddToOptionalCollectionButton contracts={[[code, name]]} />
              <Tooltip content="删除自选" position="top">
                <Button iconAfter={<SelectClearIcon label="" />}></Button>
              </Tooltip>
              <ContractPreviewFullScreenButton contract={[code, name]} period={period} />
            </ButtonGroup>
          </Inline>
          {false ? null : <ContractChartView />}
        </Stack>
      )}
    </Box>
  );
}
