import { AtlassianNavigation, CustomProductHome, PrimaryButton } from '@atlaskit/atlassian-navigation';

import { ButtonGroup } from '@atlaskit/button';

import { AppRightPanelNavigationSwitchButton, useAppRightPanelStore } from '@/components/AppRightPanel';
import AppSearchButton from '@/components/AppSearchButton';
import { Inline } from '@atlaskit/primitives';
import { SettingsButton } from './SettingsButton';
import icon from './icon.svg';
import logo from './logo.svg';

const AppNavigation = ({ actived }: { actived: 'screener' }) => {
  const { opened } = useAppRightPanelStore();
  return (
    <AtlassianNavigation
      label="site"
      moreLabel="更多"
      renderProductHome={() => (
        <CustomProductHome
          siteTitle="LTrader"
          href="/"
          iconAlt="LTrader"
          iconUrl={icon}
          logoAlt="LTrader"
          logoUrl={logo}
        />
      )}
      // renderAppSwitcher={() => <AppSwitcher tooltip="切换至..." />}
      // renderCreate={() => (
      //   <Create buttonTooltip="Create a new page" iconButtonTooltip="Create a new page" text="Create" href="#" />
      // )}
      renderSearch={() => <AppSearchButton />}
      renderSettings={() => (
        <Inline space="space.100" separator={opened ? undefined : '|'} alignBlock="center">
          <SettingsButton />
          <ButtonGroup>
            <AppRightPanelNavigationSwitchButton hidden={opened} />
          </ButtonGroup>
        </Inline>
      )}
      // renderHelp={() => <Help tooltip="Get help" />}
      // renderNotifications={() => (
      //   <NotificationIndicator onCountUpdated={console.log} notificationLogProvider={undefined} />
      // )}
      // renderProfile={() => (
      //   <Profile
      //     icon={<Avatar size="small" src={avatarUrl} name="Atlassian account: Emil Rottmayer" />}
      //     tooltip="Your profile and settings"
      //   />
      // )}
      primaryItems={[
        <PrimaryButton key="screener" isSelected={actived === 'screener'}>
          自动化选股
        </PrimaryButton>,
        <PrimaryButton key="gaobo">高波预警</PrimaryButton>,
        <PrimaryButton key="tongji">市场数据统计</PrimaryButton>,
      ]}
    />
  );
};

export default AppNavigation;
