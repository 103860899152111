import { Checkbox } from '@atlaskit/checkbox';
import { Box, Grid, Inline, Stack, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { ReactNode, SyntheticEvent, forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { TAdvanceSettingForwardedRef } from './AdvanceButton';

const Row = ({ children }: { children: ReactNode }) => (
  <Inline xcss={xcss({ minHeight: 'size.300' })} alignBlock="center">
    {children}
  </Inline>
);

const VolSettingRow = () => (
  <Row>
    <Grid columnGap="space.100" templateColumns="1fr 1fr" alignItems="center">
      <Checkbox label="成交额" />
      <Inline alignBlock="center" space="space.050">
        <Box xcss={xcss({ color: 'color.text.subtlest' })}>大于</Box>
        <Textfield width={60} isCompact appearance="subtle" />
        <Box xcss={xcss({ color: 'color.text.subtlest' })}>亿元</Box>
      </Inline>
    </Grid>
  </Row>
);

const LteSettingRow = () => (
  <Row>
    <Grid columnGap="space.100" templateColumns="1fr 1fr" alignItems="center">
      <Checkbox label="流通市值" />
      <Inline alignBlock="center" space="space.050">
        大于
        <Textfield width={60} isCompact />
        亿元
      </Inline>
    </Grid>
  </Row>
);

const CheckedValueRow = ({
  label,
  defaultValue,
  onChange,
}: {
  defaultValue: string;
  label: string;
  onChange: (value: string | undefined) => void;
}) => {
  const [checked, setChecked] = useState(!!defaultValue);
  const [value, setValue] = useState<string>();
  const onInputChange = useCallback(
    (event: SyntheticEvent<HTMLInputElement>) => {
      const value = (event.target as HTMLInputElement).value;
      setValue(value);
      setChecked(true);
      onChange(value);
    },
    [setChecked, setValue, onChange]
  );
  const onCheckboxChange = useCallback(
    (event: SyntheticEvent<HTMLInputElement>) => {
      const checked = (event.target as HTMLInputElement).checked;
      setChecked(checked);
      onChange(checked ? value : undefined);
    },
    [setChecked, value, onChange]
  );
  return (
    <Row>
      <Grid columnGap="space.100" templateColumns="1fr 1fr" alignItems="center">
        <Checkbox label={label} isChecked={checked} onChange={onCheckboxChange} />
        <Inline alignBlock="center" space="space.050">
          <Box xcss={xcss({ color: 'color.text.subtlest' })}>大于</Box>
          <Textfield
            width={60}
            isCompact
            appearance={checked ? 'standard' : 'subtle'}
            defaultValue={defaultValue}
            onChange={onInputChange}
          />
          <Box xcss={xcss({ ...(checked ? {} : { color: 'color.text.subtlest' }) })}>亿元</Box>
        </Inline>
      </Grid>
    </Row>
  );
};

const HqSettingView = forwardRef<TAdvanceSettingForwardedRef, any>(({ defaultValues }, ref) => {
  const [formValues, setFormValues] = useState<any>(defaultValues || {});
  const { chengjiaoe, liutongshizhi, noST, noCYB, noKCB, noETF } = formValues;
  useImperativeHandle(
    ref,
    () => ({
      get: () => formValues,
    }),
    [formValues]
  );
  return (
    <Stack space="space.050" xcss={xcss({ paddingBlock: 'space.250' })} spread="space-between">
      <CheckedValueRow
        defaultValue={chengjiaoe}
        label="成交额"
        onChange={chengjiaoe => setFormValues({ chengjiaoe })}
      />
      <CheckedValueRow
        defaultValue={liutongshizhi}
        label="流通市值"
        onChange={liutongshizhi => setFormValues({ liutongshizhi })}
      />
      <Row>
        <Checkbox
          label="去除ST和*ST"
          isChecked={noST}
          onChange={event => setFormValues({ noST: event.target.checked })}
        />
      </Row>
      <Row>
        <Grid columnGap="space.500" templateColumns="1fr 1fr" alignItems="center">
          <Checkbox
            label="去除创业板"
            isChecked={noCYB}
            onChange={event => setFormValues({ noCYB: event.target.checked })}
          />
          <Checkbox
            label="去除科创板"
            isChecked={noKCB}
            onChange={event => setFormValues({ noKCB: event.target.checked })}
          />
        </Grid>
      </Row>
      <Row>
        <Checkbox
          label="去除ETF"
          isChecked={noETF}
          onChange={event => setFormValues({ noETF: event.target.checked })}
        />
      </Row>
    </Stack>
  );
});

export default HqSettingView;
