import { TContractShortItems } from '@/services/screener';
import { Flex, Inline, xcss } from '@atlaskit/primitives';
import { ReactNode } from 'react';
import ContractTableView from './ContractTableView';

export declare type TContractListViewProps = {
  title?: ReactNode;
  subTitle?: ReactNode;
  actions?: ReactNode;
  contracts?: TContractShortItems;
  children?: ReactNode;
};

export default function ContractListView({ title, subTitle, actions, contracts, children }: TContractListViewProps) {
  return (
    <Flex
      xcss={xcss({
        paddingTop: 'space.200',
      })}
      direction="column"
      rowGap="space.100"
    >
      <Inline spread="space-between" alignBlock="center" xcss={xcss({ paddingInline: 'space.150' })}>
        <Inline alignBlock="baseline">
          {typeof title === 'string' ? <h5>{title}</h5> : title}
          {typeof subTitle === 'string' ? <h6 style={{ margin: 0 }}>({subTitle})</h6> : subTitle}
        </Inline>
        {actions}
      </Inline>
      {children || <ContractTableView datas={contracts} />}
    </Flex>
  );
}
