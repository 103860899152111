import Button from '@atlaskit/button';
import AddonIcon from '@atlaskit/icon/glyph/addon';
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import { Flex, Inline, xcss } from '@atlaskit/primitives';
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import HqSettingView from './HqSettingView';
import JsSettingView from './JsSettingView';

const DEF_HQ_SETTING = {};
const DEF_JS_SETTING = {};
export const useAdvanceSettingStore = create(
  persist<{ hq: any; js: any; setHq: (hq: any) => void; setJs: (js: any) => void; reset: () => void }, any>(
    (set, get) => ({
      hq: { ...((get() as any)?.hq || DEF_HQ_SETTING) },
      js: { ...((get() as any)?.js || DEF_JS_SETTING) },
      setHq: (hq: any) => set({ hq: { ...get().hq, ...hq } }),
      setJs: (js: any) => set({ js: { ...get().js, ...js } }),
      reset: () => {
        set({ hq: DEF_HQ_SETTING, js: DEF_JS_SETTING });
      },
    }),
    {
      name: 'screener-advance-storage',
    }
  )
);

export declare type TAdvanceSettingForwardedRef = {
  get: () => any;
};

const AdvanceSettingModal = ({ onClose }: { onClose: () => void }) => {
  const hqRef = useRef<TAdvanceSettingForwardedRef>(null);
  const jsRef = useRef<TAdvanceSettingForwardedRef>(null);

  const [reset, setReset] = useState(false);
  useEffect(() => {
    if (!reset) {
      return;
    }

    const inter = setTimeout(() => {
      setReset(false);
    }, 100);
    return () => inter && clearTimeout(inter);
  }, [reset, setReset]);

  const { hq: hqSetting, js: jsSetting, setHq, setJs, reset: resetSetting } = useAdvanceSettingStore();
  const onResetClick = useCallback(() => {
    resetSetting();
    setReset(true);
  }, [resetSetting, setReset]);

  const onConfirmClick = useCallback(() => {
    const hq = hqRef.current?.get();
    hq && setHq(hq);
    const js = jsRef.current?.get();
    js && setJs(js);
    onClose();
  }, [hqRef, jsRef, setHq, setJs, onClose]);
  return (
    <Modal onClose={onClose}>
      <ModalHeader>
        <ModalTitle>高级设置</ModalTitle>
      </ModalHeader>
      {!reset && (
        <ModalBody>
          <Tabs defaultSelected={0} onChange={index => console.log('Selected Tab', index + 1)} id="default">
            <TabList>
              <Tab>行情</Tab>
              <Tab>技术</Tab>
            </TabList>
            <TabPanel>
              <HqSettingView defaultValues={hqSetting} ref={hqRef} />
            </TabPanel>
            <TabPanel>
              <JsSettingView defaultValues={jsSetting} ref={jsRef} />
            </TabPanel>
          </Tabs>
        </ModalBody>
      )}
      <Flex
        justifyContent="space-between"
        xcss={xcss({
          paddingLeft: 'space.300',
          paddingRight: 'space.300',
          paddingTop: '24px',
          paddingBottom: '24px',
        })}
      >
        <Button onClick={onResetClick}>恢复默认</Button>
        <Inline space="space.100">
          <Button onClick={onClose}>取消</Button>
          <Button appearance="primary" onClick={onConfirmClick}>
            确定
          </Button>
        </Inline>
      </Flex>
    </Modal>
  );
};

export function AdvanceButton() {
  const [isModalShown, setModalShown] = useState(false);
  const onClose = useCallback(() => setModalShown(false), [setModalShown]);
  return (
    <>
      <Button iconBefore={<AddonIcon label="" />} onClick={() => setModalShown(true)}>
        高级
      </Button>
      <ModalTransition>{isModalShown && <AdvanceSettingModal onClose={onClose} />}</ModalTransition>
    </>
  );
}
