import { ContractPreviewPeriodSelector } from '@/components/ContractPeriodSelector';
import StockScreenerResultLayoutSwitcher from '@/components/ContractPreviewLayoutSwitcher';
import Button from '@atlaskit/button';
import ChevronLeftLargeIcon from '@atlaskit/icon/glyph/chevron-left-large';
import ChevronRightLargeIcon from '@atlaskit/icon/glyph/chevron-right-large';
import { Inline } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import ContractPreviewSourceSwitcher from './ContractPreviewSourceSwitcher';

export default function ContractMultiplePreviewToolbar() {
  return (
    <Inline alignBlock="center" spread="space-between">
      <Inline separator="|" space="space.075" alignBlock="center">
        <ContractPreviewSourceSwitcher />
      </Inline>
      <Inline space="space.100" alignBlock="center">
        <StockScreenerResultLayoutSwitcher />
        <ContractPreviewPeriodSelector />
        <Tooltip content="上一页" position="top">
          <Button isDisabled iconAfter={<ChevronLeftLargeIcon label="" />} />
        </Tooltip>
        <span>1/34</span>
        <Tooltip content="下一页" position="top">
          <Button iconAfter={<ChevronRightLargeIcon label="" />} />
        </Tooltip>
      </Inline>
    </Inline>
  );
}
