import Button from '@atlaskit/button';
import { RightPanel } from '@atlaskit/page-layout';
import { Box, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useCallback } from 'react';

import ChevronLeftCircleIcon from '@atlaskit/icon/glyph/chevron-left-circle';
import ChevronRightCircleIcon from '@atlaskit/icon/glyph/chevron-right-circle';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useAppRightPanelStore = create(
  persist<{ opened: boolean; show: () => void; hide: () => void }>(
    set => ({
      opened: true,
      show: () => set({ opened: true }),
      hide: () => set({ opened: false }),
    }),
    { name: 'app-right-panel-store' }
  )
);

export const AppRightPanelNavigationSwitchButton = ({ hidden }: { hidden?: boolean }) => {
  const { opened, show, hide } = useAppRightPanelStore();
  const onClick = useCallback(() => {
    opened ? hide() : show();
    setTimeout(() => window.dispatchEvent(new CustomEvent('resize')), 100);
  }, [opened, show, hide]);
  if (hidden === true) {
    return null;
  }
  return (
    <Tooltip content={opened ? '隐藏自选股' : '打开自选股'}>
      <Button
        onClick={onClick}
        label="我的自选"
        iconAfter={opened ? <ChevronRightCircleIcon label="" /> : <ChevronLeftCircleIcon label="" />}
      />
    </Tooltip>
  );
};

const AppRightPanel = ({ children }: { children: any }) => {
  return (
    <RightPanel isFixed width={360} shouldPersistWidth>
      <Box
        xcss={xcss({
          width: '100%',
          height: '100%',
          overflowY: 'auto',
          borderLeftStyle: 'solid',
          borderLeftWidth: 'border.width.outline',
          borderColor: 'color.border.bold',
          backgroundColor: 'elevation.surface',
        })}
      >
        {children}
      </Box>
    </RightPanel>
  );
};

export default AppRightPanel;
