import AppLayout from '@/AppLayout';
import { useAppRightPanelStore } from '@/components/AppRightPanel';
import ContractFullScreenPreviewView, {
  useContractPreviewFullScreenStore,
} from '@/components/ContractFullScreenPreviewView';
import ModuleSideBar from '@/components/ModuleSideBar';
import OptionalContractPanel from '@/components/OptionalContractPanel';
import { FadeIn } from '@atlaskit/motion';
import { Content, Main } from '@atlaskit/page-layout';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import { useEffect } from 'react';
import ConditionEditor from './ConditionEditor';
import ContractMultiplePreviewView from './ContractMultiplePreviewView';
import { ScreenerResultView } from './ScreenerResultView';

export default function StockScreener() {
  const { opened } = useAppRightPanelStore();
  useEffect(() => {
    const resize = () => {
      const contentEle = document.getElementById('__app_main_container')?.parentElement as HTMLDivElement | undefined;
      if (!contentEle?.style) {
        return;
      }
      contentEle.style.width = '100%';
    };
    resize();
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, []);
  const { contract, shown } = useContractPreviewFullScreenStore();
  return (
    <AppLayout navigationActived="screener">
      <Content>
        <ModuleSideBar title="自选筛选" width={360}>
          <Stack space="space.300" xcss={xcss({ backgroundColor: 'elevation.surface', minHeight: '100%' })}>
            <ConditionEditor />
            <ScreenerResultView />
          </Stack>
        </ModuleSideBar>
        <Main isFixed id="__app_main_container">
          <Inline>
            <Box xcss={xcss({ padding: 'space.050', minWidth: '1006px', minHeight: '640px' })}>
              {contract && shown ? <ContractFullScreenPreviewView /> : <ContractMultiplePreviewView />}
            </Box>
            {/* <Box xcss={xcss({ minWidth: '100%', minHeight: '100%', backgroundColor: 'elevation.surface.pressed' })}>
              fff
            </Box> */}
          </Inline>
        </Main>
      </Content>
      {opened && <FadeIn>{motion => <OptionalContractPanel {...motion} />}</FadeIn>}
    </AppLayout>
  );
}
