import { BrowserRouter, Route, Routes } from 'react-router-dom';
import StockScreener from './StockScreener';

export default (
  <BrowserRouter basename="/">
    <Routes>
      <Route path="/" element={<StockScreener />} />
    </Routes>
  </BrowserRouter>
);
