import Button, { ButtonGroup } from '@atlaskit/button';
import CalendarFilledIcon from '@atlaskit/icon/glyph/calendar-filled';
import Tooltip from '@atlaskit/tooltip';
import { MouseEvent, useCallback } from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const sort = (periods: TContractKLinePeriod[]) => {
  return (['2D', 'D', '180F', '60F', '30F', '15F', '5F', '3F', '1F'] as TContractKLinePeriod[]).filter(
    p => periods.indexOf(p) !== -1
  );
};

export declare type TContractKLinePeriod = '2D' | 'D' | '60F' | '15F' | '3F' | '180F' | '30F' | '5F' | '1F';
export declare type TContractPeriodsStore = {
  periods: TContractKLinePeriod[];
  select: (period: TContractKLinePeriod) => void;
  diselect: (period: TContractKLinePeriod) => void;
};
export const useContractPeriodSelectorStore = create(
  persist<TContractPeriodsStore>(
    set => ({
      periods: ['30F'],
      select: period =>
        set(state => {
          const periods = state.periods.concat(state.periods.indexOf(period) !== -1 ? [] : period);
          return { periods: sort(periods) };
        }),
      diselect: period =>
        set(state => {
          const periods = state.periods.filter(p => p !== period);
          return { periods: sort(periods) };
        }),
    }),
    { name: 'contract-period-selector-store' }
  )
);

const ContractPeriodSelector = () => {
  const { periods, select, diselect } = useContractPeriodSelectorStore();
  const onPeriodClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      const period = ((event.target as HTMLSpanElement).parentElement as HTMLButtonElement).getAttribute(
        'data-period'
      ) as TContractKLinePeriod;
      periods.indexOf(period) !== -1 ? diselect(period) : select(period);
    },
    [select, diselect, periods]
  );
  return (
    <ButtonGroup>
      {(['2D', 'D', '60F', '15F', '3F', '180F', '30F', '5F', '1F'] as TContractKLinePeriod[]).map(period => (
        <Button key={period} isSelected={periods.indexOf(period) !== -1} data-period={period} onClick={onPeriodClick}>
          {period}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default ContractPeriodSelector;

export const ContractPreviewPeriodSelector = () => {
  const { periods } = useContractPeriodSelectorStore();
  return (
    <ButtonGroup>
      <Tooltip content="多周期" position="top">
        <Button isSelected={periods.length > 1} iconAfter={<CalendarFilledIcon label="" />} />
      </Tooltip>
      <ContractPeriodSelector />
    </ButtonGroup>
  );
};
