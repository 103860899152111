import { TContractShortItems } from '@/services/screener';
import Button from '@atlaskit/button';
import DynamicTable from '@atlaskit/dynamic-table';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { Box, Stack, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useCallback, useMemo, useRef } from 'react';
import { TContractSubscribeTickData, useTickData } from './ContractTradeData';

const head = {
  cells: [
    {
      key: 'index',
      content: '',
      width: 10,
    },
    {
      key: 'code',
      content: '名称/代码',
      width: 30,
    },
    {
      key: 'price',
      content: <Box xcss={xcss({ width: '100%', textAlign: 'right' })}>最新价</Box>,
      width: 30,
    },
    {
      key: 'percent',
      content: <Box xcss={xcss({ width: '100%', textAlign: 'right' })}>涨跌幅</Box>,
    },
    {
      key: 'op',
      content: '',
      width: 12,
    },
  ],
};

const PriceView = ({ code }: { code: string }) => {
  const viewRef = useRef<HTMLDivElement>(null);
  const listener = useCallback(
    (_: any, data: TContractSubscribeTickData) => {
      if (!viewRef.current) {
        return;
      }
      viewRef.current.innerHTML = `<span>${data[0]}</span>`;
    },
    [viewRef]
  );
  useTickData(code, listener);
  return (
    <Box ref={viewRef} xcss={xcss({ width: '100%', textAlign: 'right', fontSize: '12px' })}>
      -
    </Box>
  );
};

const PercentView = ({ code }: { code: string }) => {
  const viewRef = useRef<HTMLDivElement>(null);
  const listener = useCallback(
    (_: any, data: TContractSubscribeTickData) => {
      if (!viewRef.current) {
        return;
      }
      viewRef.current.innerHTML = `<span style="color:${data[1] === 0 ? '' : data[1] > 0 ? 'red' : 'green'}">${
        data[1]
      }%</span>`;
    },
    [viewRef]
  );
  useTickData(code, listener);
  return (
    <Box ref={viewRef} xcss={xcss({ width: '100%', textAlign: 'right', fontSize: '12px' })}>
      -
    </Box>
  );
};

const mapToRow = ([code, name, price, percent]: [string, string, number?, number?], index: number) => ({
  key: String(index),
  cells: [
    {
      key: index,
      content: <Box xcss={xcss({ fontSize: '10px', textAlign: 'right', paddingLeft: 'space.150' })}>{index + 1}</Box>,
    },
    {
      key: code,
      content: (
        <Stack>
          <Box xcss={xcss({ fontSize: '12px' })}>{name}</Box>
          <Box xcss={xcss({ color: 'color.text.subtlest', fontSize: '10px' })}>{code}</Box>
        </Stack>
      ),
    },
    {
      key: `${index}-price`,
      content: <PriceView code={code} />,
    },
    {
      key: `${index}-percent`,
      content: <PercentView code={code} />,
    },
    {
      key: `${index}-op`,
      content: (
        <Box xcss={xcss({ paddingRight: 'space.150' })}>
          <Tooltip content="从自选中移除" position="top">
            <Button iconAfter={<CrossIcon label="" size="small" />} />
          </Tooltip>
        </Box>
      ),
    },
  ],
});

export default function ContractTableView({ datas, head: _head }: { head?: any; datas?: TContractShortItems }) {
  const rows = useMemo(() => datas?.map((data, index) => mapToRow(data as any, index)), [datas]);
  return (
    <DynamicTable
      emptyView={<h6>暂无数据</h6>}
      head={head}
      rows={rows || []}
      isFixedSize
      defaultSortKey="price"
      defaultSortOrder="ASC"
    />
  );
}
