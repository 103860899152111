import { Checkbox } from '@atlaskit/checkbox';
import { Box, Grid, Inline, Stack, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { ReactNode, SyntheticEvent, forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { TAdvanceSettingForwardedRef } from './AdvanceButton';

const Row = ({ children }: { children: ReactNode }) => (
  <Inline xcss={xcss({ minHeight: 'size.300' })} alignBlock="center">
    {children}
  </Inline>
);

const StrictBiPercentRow = ({
  defaultValue,
  onChange,
}: {
  defaultValue: string | undefined;
  onChange: (value: string | undefined) => void;
}) => (
  <Row>
    <Grid columnGap="space.100" templateColumns="3fr 1fr" alignItems="center">
      <Box xcss={xcss({ paddingLeft: '28px' })}>严格笔重叠百分比</Box>
      <Inline alignBlock="center" space="space.050">
        <Box xcss={xcss({ color: 'color.text.subtlest' })}>等于</Box>
        <Textfield
          width={60}
          isCompact
          defaultValue={defaultValue}
          onChange={event => onChange((event.target as HTMLInputElement).value)}
        />
        %
      </Inline>
    </Grid>
  </Row>
);

const CheckedValueRow = ({
  label,
  p,
  defaultValue,
  onChange,
}: {
  label: string;
  p: string;
  defaultValue: string | undefined;
  onChange: (value: string | undefined) => void;
}) => {
  const [checked, setChecked] = useState(!!defaultValue);
  const [value, setValue] = useState<string>();
  const onInputChange = useCallback(
    (event: SyntheticEvent<HTMLInputElement>) => {
      const value = (event.target as HTMLInputElement).value;
      setValue(value);
      setChecked(true);
      onChange(value);
    },
    [setChecked, setValue, onChange]
  );
  const onCheckboxChange = useCallback(
    (event: SyntheticEvent<HTMLInputElement>) => {
      const checked = (event.target as HTMLInputElement).checked;
      setChecked(checked);
      onChange(checked ? value : undefined);
    },
    [setChecked, value, onChange]
  );
  return (
    <Row>
      <Grid columnGap="space.100" templateColumns="3fr 1fr" alignItems="center">
        <Checkbox label={label} isChecked={checked} onChange={onCheckboxChange} />
        <Inline alignBlock="center" space="space.050">
          <Box xcss={xcss({ color: 'color.text.subtlest' })}>{p}</Box>
          <Textfield
            width={60}
            isCompact
            appearance={checked ? 'standard' : 'subtle'}
            defaultValue={defaultValue}
            onChange={onInputChange}
          />
          <Box xcss={xcss({ ...(checked ? {} : { color: 'color.text.subtlest' }) })}>%</Box>
        </Inline>
      </Grid>
    </Row>
  );
};

const JsSettingView = forwardRef<TAdvanceSettingForwardedRef, any>(({ defaultValues }, ref) => {
  const [formValues, setFormValues] = useState<any>(defaultValues || {});
  const { yangebichongdiebfb, julizhongshushangxianbfb, julizhongshuxiayanbfb, zhongshubodonglv } = formValues;
  useImperativeHandle(
    ref,
    () => ({
      get: () => formValues,
    }),
    [formValues]
  );
  return (
    <Stack space="space.050" xcss={xcss({ paddingBlock: 'space.250', width: '100%' })} spread="space-between">
      <StrictBiPercentRow
        defaultValue={yangebichongdiebfb}
        onChange={yangebichongdiebfb => setFormValues({ yangebichongdiebfb })}
      />
      <CheckedValueRow
        label="当前价距离中枢上沿百分比"
        p="小于"
        defaultValue={julizhongshushangxianbfb}
        onChange={julizhongshushangxianbfb => setFormValues({ julizhongshushangxianbfb })}
      />
      <CheckedValueRow
        label="当前价距离中枢下沿百分比"
        p="小于"
        defaultValue={julizhongshuxiayanbfb}
        onChange={julizhongshuxiayanbfb => setFormValues({ julizhongshuxiayanbfb })}
      />
      <CheckedValueRow
        label="中枢波动率"
        p="大于"
        defaultValue={zhongshubodonglv}
        onChange={zhongshubodonglv => setFormValues({ zhongshubodonglv })}
      />
    </Stack>
  );
});

export default JsSettingView;
