import Button, { ButtonGroup } from '@atlaskit/button';
import CalendarFilledIcon from '@atlaskit/icon/glyph/calendar-filled';
import Tooltip from '@atlaskit/tooltip';
import { MouseEvent, useCallback } from 'react';
import { create } from 'zustand';
import { TContractKLinePeriod } from './ContractPeriodSelector';

export const sort = (periods: TContractKLinePeriod[]) => {
  return (['2D', 'D', '180F', '60F', '30F', '15F', '5F', '3F', '1F'] as TContractKLinePeriod[]).filter(
    p => periods.indexOf(p) !== -1
  );
};

export declare type TContractPeriodsStore = {
  period: TContractKLinePeriod;
  select: (period: TContractKLinePeriod) => void;
};
export const useContractPeriodSwitcherStore = create<TContractPeriodsStore>(set => ({
  period: '30F',
  select: period => set({ period }),
}));

const ContractPeriodSwitcher = () => {
  const { period, select } = useContractPeriodSwitcherStore();
  const onPeriodClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      const period = ((event.target as HTMLSpanElement).parentElement as HTMLButtonElement).getAttribute(
        'data-period'
      ) as TContractKLinePeriod;
      if (!period) {
        return;
      }
      select(period);
    },
    [select, select]
  );
  return (
    <ButtonGroup>
      {(['2D', 'D', '60F', '15F', '3F', '180F', '30F', '5F', '1F'] as TContractKLinePeriod[]).map(p => (
        <Button key={p} isSelected={period === p} data-period={p} onClick={onPeriodClick}>
          {p}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default ContractPeriodSwitcher;

export const ContractPreviewPeriodSwitcher = () => {
  return (
    <ButtonGroup>
      <Tooltip content="多周期" position="top">
        <Button iconAfter={<CalendarFilledIcon label="" />} />
      </Tooltip>
      <ContractPeriodSwitcher />
    </ButtonGroup>
  );
};
